import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import Section from '../../../../components/ui/Section';
import {
  imagesLine1,
  imagesLine2,
  imagesLine3,
} from '../../../../data/gameImagesForScroller';
import { Autoplay, FreeMode } from 'swiper/modules';
import useResponsiveSlides from '../../../../hooks/useResponsiveSlides';
import SectionHeaderHome from '../../../../components/ui/SectionHeaderHome';

const MultiplesGames = () => {
  const slidesPerView = useResponsiveSlides(6, 1200);

  const renderCarousel = (items, direction = 'left') => (
    <Swiper
      spaceBetween={10}
      slidesPerView={slidesPerView}
      loop={true}
      freeMode={true}
      freeModeMomentum={false}
      centeredSlides={false}
      autoplay={{
        delay: 0,
        disableOnInteraction: false,
        reverseDirection: direction === 'right',
      }}
      speed={6000}
      modules={[Autoplay, FreeMode]}
      className={`mySwiper ${direction}`}
    >
      {items.map((item, idx) => (
        <SwiperSlide key={idx}>
          <img
            src={item.src}
            alt={item.alt}
            className="size-24 rounded-3xl object-cover"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );

  return (
    <Section
      variant="wrapper"
      additionalClass="text-white-1 mt-24 md:mt-40 px-5"
    >
      <SectionHeaderHome
        title="Participez à une bibliothèque de jeux unique"
        subtitle="Explorez une collection sans cesse renouvelée de jeux, tous proposés par notre incroyable communauté de joueurs."
      />
      <div className="relative mt-[-20px]">
        <div className="bg-radial-gradient absolute left-[-1%] top-[-1%] z-10 h-[102%] w-[102%] opacity-100"></div>
        <div className="relative overflow-hidden">
          {renderCarousel(imagesLine1)}
          <div className="my-8">{renderCarousel(imagesLine2, 'right')}</div>
          {renderCarousel(imagesLine3)}
        </div>
      </div>
    </Section>
  );
};

export default MultiplesGames;
