import React from 'react';
import { IconContext } from 'react-icons';
import { BsInstagram, BsTwitter } from 'react-icons/bs';

const SocialFollow = ({ title, socialLinks }) => {
  const icons = {
    instagram: <BsInstagram className="xs:size-8 size-5 sm:size-10" />,
    twitter: <BsTwitter className="xs:size-8 size-5 sm:size-10" />,
  };

  return (
    <div className="mb-8 md:w-[254px]">
      <p className="font-inter text-md xs:text-xl mb-4 font-bold">{title}</p>
      <IconContext.Provider
        value={{
          className: 'hover:text-accent-9 cursor-pointer',
        }}
      >
        <div className="xxs:flex-row xxs:items-stretch xxs:space-y-0 xxs:space-x-8 mx-auto mb-7 flex flex-col flex-wrap items-center justify-center space-y-3">
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.url}
              className="mb-2 block"
              target="_blank"
              rel="noopener noreferrer"
            >
              {icons[link.type]}
            </a>
          ))}
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default SocialFollow;
