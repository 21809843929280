import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../components/layout/Navbar/Navbar';
import { API_ENDPOINTS } from '../utils/backURL';
import { useQuery } from '@tanstack/react-query';
import Button from '../components/buttons/Button';
import LabeledInput from '../components/input/LabeledInput';
import AuthContext from '../context/AuthContext';
import { formatDateHour } from '../utils/formattingUtils';
import { BsSend } from 'react-icons/bs';
import AlertMessage from '../components/alertMessage/AlertMessage';

const ContactConversation = () => {
  const { discussionTicket } = useParams();
  const [email, setEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const { userData } = useContext(AuthContext);
  const messagesEndRef = useRef(null);
  const [alertInfo, setAlertInfo] = useState(null);
  const [formData, setFormData] = useState({
    sender: '',
    content: '',
  });

  //!!!! Requete API pour récupérer les messages de la discussion
  const fetchConversation = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_CONTACT_MESSAGE}?discussion.ticket=${discussionTicket}`,
    );
    return response.data['hydra:member'];
  };

  //!!!! Vérification de l'identité de la personne
  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${API_ENDPOINTS.VERIFY_DISCUSSION}/${discussionTicket}/${email}`,
      );
      if (response.data.verified) {
        setIsVerified(true);
        fetchConversation();
      } else {
        setAlertInfo(null);
        setTimeout(() => {
          setAlertInfo({
            title: 'Erreur',
            message: 'Vérification échouée',
            type: 'error',
            autoCloseTime: 5000,
          });
        }, 100);
      }
    } catch (error) {
      setAlertInfo(null);
      setTimeout(() => {
        setAlertInfo({
          title: 'Erreur',
          message: error.response?.data?.error || error.message,
          type: 'error',
          autoCloseTime: 5000,
        });
      }, 100);
    }
  };

  //!!!! Query des messages
  const {
    data: messages,
    refetch: messagesRefetch,
  } = useQuery(['messages'], fetchConversation, {
    refetchOnWindowFocus: true,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //!!!! Gestion de l'envoi de nouveau message
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      content: formData.content,
      senderEmail: userData ? userData.email : email,
      sender: userData ? `api/users/${userData.id}` : null,
      who: 'user',
      discussion: `api/contact_discussions/${discussionTicket}`,
    };
    try {
      await axios.post(API_ENDPOINTS.POST_CONTACT_MESSAGE, payload);
      setFormData({ sender: '', subject: '', content: '' });
    } catch (error) {
    } finally {
      messagesRefetch();
    }
  };

  //!!!! Scroll vers le bas
  useEffect(() => {
    if (isVerified) {
      scrollToBottom();
    }
  }, [messages, isVerified]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Navbar />
      {alertInfo && (
        <AlertMessage
          title={alertInfo.title}
          message={alertInfo.message}
          type={alertInfo.type}
          autoCloseTime={alertInfo.autoCloseTime}
        />
      )}
      {!isVerified ? (
        <div className="mx-auto flex w-full max-w-[450px] flex-col items-center justify-center pt-32">
          <h1 className="mb-4 text-center text-xl font-semibold">
            Demande d'Accès à la Discussion
          </h1>
          <p className="mb-6 px-4 text-center text-xs">
            Par mesure de sécurité veuillez saisir votre adresse email pour
            accéder à votre discussion.
          </p>
          <form onSubmit={handleVerification} className="w-full max-w-md px-4">
            <LabeledInput
              label="Adresse E-mail"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Entrez votre adresse e-mail"
              required
            />
            <Button type="submit" className="mt-4 w-full">
              Vérifier
            </Button>
          </form>
        </div>
      ) : (
        <div className="h-screen-100-64 flex w-full flex-col">
          <div className="border-b px-5 py-4 drop-shadow-2xl">
            <p className="truncate">
              <span className="text-xs">Objet :</span>{' '}
              <span className="font-semibold">
                {messages[0].discussion.subject}
              </span>
            </p>
          </div>
          <div className="grow overflow-y-auto px-5 pb-3 pt-5">
            {messages.map((message) => (
              <div
                key={message['@id']}
                className={`my-4 max-w-[70%] rounded-lg border border-none p-3 ${
                  message.who === 'user'
                    ? 'bg-accent-4 ml-auto'
                    : 'bg-white-3 mr-auto'
                }`}
              >
                <div className="text-black-8 mb-3 flex justify-between space-x-4 text-xs">
                  <p className="w-1/3 truncate md:w-1/2">
                    De: {message.senderEmail}
                  </p>
                  <p className="xs:w-1/2 w-2/3 text-right">
                    {formatDateHour(message.createdAt)}
                  </p>
                </div>
                <p className="whitespace-normal break-words">
                  {message.content}
                </p>
                <div ref={messagesEndRef} />
              </div>
            ))}
          </div>
          <div className="border-t bg-white p-4 drop-shadow-xl">
            <form
              onSubmit={handleSubmit}
              className="flex items-start space-x-3"
            >
              <LabeledInput
                type="text"
                name="content"
                value={formData.content}
                onChange={handleChange}
                asTextarea="true"
                required
                additionalContainerClass="flex-grow"
                additionalInputClass="h-[48px] border-black-1 text-sm"
              />
              <Button
                type="submit"
                className="text-black-5 flex items-center justify-center space-x-3 text-center"
              >
                <span className="">Envoyer</span> <BsSend size="15px" />
              </Button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactConversation;
