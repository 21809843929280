// Backend URLs
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const GAME_IMAGE_URL = `${BACKEND_URL}/assets/images/game/`;
export const USER_IMAGE_URL = `${BACKEND_URL}/assets/images/profile/`;
export const API_BASE_URL = `${BACKEND_URL}/api`;

export const API_ENDPOINTS = {
  // GET
  FETCH_USER: `${API_BASE_URL}/users`,
  FETCH_USERS_FOR_ADMINS: `${API_BASE_URL}/admin/users`,
  FETCH_GAME: `${API_BASE_URL}/game`,
  FETCH_GAMES: `${API_BASE_URL}/games`,
  FETCH_RECENT_GAMES: `${API_BASE_URL}/games?release=recent`,
  FETCH_UPCOMING_GAMES: `${API_BASE_URL}/games?release=upcoming`,
  FETCH_PUBLISHERS: `${API_BASE_URL}/publishers`,
  FETCH_DEVELOPERS: `${API_BASE_URL}/developers`,
  FETCH_PLATFORMS: `${API_BASE_URL}/platforms`,
  FETCH_TAG_TYPES: `${API_BASE_URL}/tag_types`,
  FETCH_TAGS: `${API_BASE_URL}/tags`,
  FETCH_ME: `${API_BASE_URL}/users/me`,
  FETCH_VERSUSES: `${API_BASE_URL}/versuses`,
  FETCH_VERSUS: `${API_BASE_URL}/versus`,
  FETCH_VERSUS_VOTE: `${API_BASE_URL}/versus_votes`,
  FETCH_CONTACT_MESSAGE: `${API_BASE_URL}/contact_messages`,
  FETCH_DISCUSSION: `${API_BASE_URL}/contact_discussions`,
  FETCH_GAME_LIST: `${API_BASE_URL}/user_game_lists`,

  // POST
  POST_NEW_GAME: `${API_BASE_URL}/games/complete`,
  POST_NEW_GAME_PLATFORMS: `${API_BASE_URL}/game_platforms`,
  POST_NEW_GAME_TAGS: `${API_BASE_URL}/game_tags`,
  POST_NEW_DEVELOPER: `${API_BASE_URL}/developers`,
  POST_NEW_PUBLISHER: `${API_BASE_URL}/publishers`,
  POST_NEW_PLATFORM: `${API_BASE_URL}/platforms`,
  POST_VOTE: `${API_BASE_URL}/versus_votes`,
  POST_VERSUS: `${API_BASE_URL}/versus`,
  POST_CONTACT_MESSAGE: `${API_BASE_URL}/contact_messages`,
  POST_GAME_LIST: `${API_BASE_URL}/user_game_lists`,
  POST_GAME_LIST_ENTRIES: `${API_BASE_URL}/user_game_list_entries`,

  // PUT
  EDIT_GAME: `${API_BASE_URL}/games`,
  EDIT_GAME_IMAGE: `${API_BASE_URL}/game/upload_image`,
  EDIT_USER_IMAGE: `${API_BASE_URL}/user/upload_profile_image`,
  DELETE_USER_IMAGE: `${API_BASE_URL}/user/remove_profile_image`,
  EDIT_VERSUS: `${API_BASE_URL}/versuses`,
  EDIT_GAME_LIST: `${API_BASE_URL}/user_game_lists`,
  EDIT_PLATFORM: `${API_BASE_URL}/platforms`,

  // DELETE
  DELETE_GAME_LIST: `${API_BASE_URL}/user_game_lists`,
  DELETE_GAME_LIST_ENTRIES: `${API_BASE_URL}/user_game_list_entries`,
  DELETE_PLATFORMS: `${API_BASE_URL}/platforms`,

  // OTHER
  STATS: `${API_BASE_URL}/admin/totals`,
  REGISTER: `${API_BASE_URL}/users`,
  AUTH: `${API_BASE_URL}/auth`,
  CHANGE_PASSWORD: `${API_BASE_URL}/user/change-password`,
  CHANGE_EMAIL: `${API_BASE_URL}/user/change-email`,
  CHANGE_USERNAME: `${API_BASE_URL}/user/change-username`,
  DELETE_ACCOUNT: `${API_BASE_URL}/user/delete-account`,
  VERIF_EMAIL: `${BACKEND_URL}/verify/email`,
  RESEND_EMAIL_VERIF: `${API_BASE_URL}/resend/verify-email`,
  SEND_EMAIL_FORGOT_PASSWORD: `${API_BASE_URL}/reset-password`,
  POST_RESET_PASSWORD: `${API_BASE_URL}/reset-password/reset`,
  TOKEN_REFRESH: `${API_BASE_URL}/token/refresh`,
  TOKEN_INVALIDATE: `${API_BASE_URL}/token/invalidate`,
  JWT_INVALIDATE: `${API_BASE_URL}/jwt/invalidate`,
  VERIFY_DISCUSSION: `${API_BASE_URL}/verify-discussion`,
};
