import React from "react";
import * as ROUTES from "../utils/routesConfig";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="text-black-7 flex max-w-[1280px] flex-col justify-center py-8 md:ml-32">
      <h1 className="text-black-1 mb-5 ml-2 text-xs font-semibold">
        404 - Page non trouvée
      </h1>
      <p className="mb-5 text-7xl font-semibold uppercase">Désolé</p>
      <p className="mb-3 ml-2 text-4xl">
        la page que vous recherchez n'existe pas (ou plus).
      </p>
      <p className="ml-2 text-2xl">
        Reformulez votre recherche ou allez sur la{" "}
        <Link to={ROUTES.HOME} className="text-blue-500">
          page d’accueil
        </Link>
      </p>
    </div>
  );
};

export default NotFoundPage;
