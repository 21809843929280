import { Link } from 'react-router-dom';

const LinkImage = ({ to, src, alt, title }) => (
  <Link to={to} className="group">
    <div className="aspect-3/4 flex w-full items-center justify-center">
      <img
        src={src}
        alt={alt}
        className="aspect-3/4 h-full rounded-lg object-cover"
      />
    </div>
    <div className="group-hover:text-black-8 mt-2 truncate text-sm font-semibold underline underline-offset-4">
      {title}
    </div>
  </Link>
);

export default LinkImage;
