import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../utils/backURL';
import Spinner from '../components/spinner/Spinner';
import Button from '../components/buttons/Button';
import * as ROUTES from '../utils/routesConfig';
import { Helmet } from 'react-helmet';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Construire l'URL de vérification avec les paramètres de recherche
    const params = Object.fromEntries([...searchParams]);
    const verifyEmailUrl = `${API_ENDPOINTS.VERIF_EMAIL}?${new URLSearchParams(
      params,
    )}`;

    // Vérifier que tous les paramètres sont présents
    if (params.token && params.id && params.expires && params.signature) {
      axios
        .get(verifyEmailUrl)
        .then(() => {
          setVerificationStatus('success');
          setMessage(
            'Félicitations. Votre email a été vérifié avec succès. Vous pouvez maintenant vous connecter.',
          );
        })
        .catch((error) => {
          setVerificationStatus('failure');
          setMessage(
            error.response?.data?.error ||
              'Une erreur est survenue lors de la vérification. Veuillez réessayer ou contacter notre support.',
          );
        });
    } else {
      setVerificationStatus('failure');
      setMessage(
        'Des informations sont manquantes dans le lien de vérification. Veuillez utiliser le lien envoyé à votre adresse email.',
      );
    }
  }, [searchParams]);

  if (verificationStatus === 'pending') {
    return <Spinner />;
  }

  return (
    <div className="flex min-h-[500px] items-center justify-center px-4 ">
      <Helmet>
        <title>Vérification de l'Email | Joystique</title>
        <meta name="description" content={message} />
      </Helmet>
      <div className="bg-white-2 mx-auto my-10 max-w-xl rounded border p-5 shadow-lg">
        <h2 className="mb-4 text-center text-[1rem] font-bold uppercase first-letter:text-[1.3rem]">
          Vérification de l'Email
        </h2>
        <p className="mb-4 text-center">{message}</p>
        {verificationStatus === 'success' ? (
          <Button link={ROUTES.LOGIN} className="w-full">
            Se connecter
          </Button>
        ) : (
          <Button link={ROUTES.CONTACT} variant="secondary" className="w-full">
            Contact
          </Button>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
