import React from 'react';

const LegalInformation = () => {
  return (
    <div className="text-black-1 mx-auto my-8 max-w-[1280px] px-4 font-sans">
      <h1 className="mb-4 text-3xl font-bold">Mentions Légales</h1>
      <h2 className="mb-3 text-2xl font-semibold">1. Informations Légales</h2>
      <p>
        En vertu de l’article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la
        confiance dans l’économie numérique, il est précisé dans cet article
        l’identité des différents intervenants dans le cadre de sa réalisation
        et de son suivi.
      </p>
      <p className="my-2">
        <strong>Le site Joystique est édité par :</strong>
      </p>
      <p>
        M. Mansouri Abdel, dont le siège social est situé à l’adresse suivante :
        98 chemin de Lanusse, 31200 Toulouse. Téléphone : 0682129501 / Adresse
        e-mail : contact@joystique.fr
      </p>
      <p className="my-2">
        <strong>Le directeur de publication du site est :</strong>
      </p>
      <p>M. Mansouri Abdel.</p>
      <p className="my-2">
        <strong>Le site Joystique est hébergé par :</strong>
      </p>
      <p>
        O2SWITCH – 222, Boulevard Gustave Flaubert – 63000 Clermont-Ferrand.
      </p>
      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        2. Propriété Intellectuelle
      </h2>
      <p>
        Joystique respecte les droits d'auteur et s'assure que tous les droits
        nécessaires sont obtenus pour le contenu publié sur le site. Toute
        reproduction, modification, publication, adaptation de tout ou partie
        des éléments du site, quel que soit le moyen ou le procédé utilisé, est
        interdite sans autorisation écrite préalable.
      </p>
      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        3. Limitations Contractuelles sur les Données Techniques
      </h2>
      <p>
        Le site utilise des technologies JavaScript. Le site Internet ne pourra
        être tenu responsable de dommages matériels liés à l'utilisation du
        site. De plus, l'utilisateur du site s'engage à accéder au site en
        utilisant un matériel récent, ne contenant pas de virus et avec un
        navigateur de dernière génération mis-à-jour.
      </p>
      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        4. Gestion des Données Personnelles
      </h2>
      <p>
        Dans le cadre de l'utilisation du site Joystique, peuvent êtres
        recueillies : l'URL des liens par l'intermédiaire desquels l'utilisateur
        a accédé au site Joystique, le fournisseur d'accès de l'utilisateur,
        l'adresse de protocole Internet (IP) de l'utilisateur, etc.
      </p>
      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        5. Liens Hypertextes et Cookies
      </h2>
      <p>
        Le site Joystique contient un certain nombre de liens hypertextes vers
        d'autres sites, mis en place avec l'autorisation de M. Mansouri Abdel.
        Cependant, M. Mansouri Abdel n'a pas la possibilité de vérifier le
        contenu des sites ainsi visités, et n'assumera en conséquence aucune
        responsabilité de ce fait.
      </p>
    </div>
  );
};

export default LegalInformation;
