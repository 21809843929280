import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LuGamepad2 } from 'react-icons/lu';
import { LuLayoutDashboard } from 'react-icons/lu';
import { LuUsers } from 'react-icons/lu';
import { BiNetworkChart } from 'react-icons/bi';
import { MdOutlineMail } from 'react-icons/md';
import BurgerMenu from '../../Navbar/BurgerMenu';
import Overlay from '../../Navbar/Overlay';
import logoxs from '../../../../assets/logo/logo-xs-wy.svg';
import * as ROUTES from '../../../../utils/routesConfig';
import SidebarLink from '../../../link/SidebarLink';
import DropdwonClick from '../../../dropdown/DropdownClick';

const AdminNavbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Basculer l'état de la sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Liens
  const games = [
    { path: ROUTES.ADMIN_GAMES, label: 'Tous les jeux' },
    { path: ROUTES.ADMIN_PLATFORMS, label: 'Plateformes' },
    { path: ROUTES.ADMIN_PUBLISHERS, label: 'Editeur' },
    { path: ROUTES.ADMIN_DEVELOPPERS, label: 'Développeur' },
    { path: ROUTES.ADMIN_TAGS, label: 'Tags' },
  ];
  const community = [{ path: ROUTES.ADMIN_VERSUS, label: 'Versus' }];

  return (
    <header>
      <Overlay
        isSidebarOpen={isSidebarOpen}
        closeSidebar={() => setIsSidebarOpen(false)}
      />
      <div className="bg-black-3 fixed z-20 flex h-[80px] w-full items-center px-4 py-1 md:hidden">
        <BurgerMenu toggleSidebar={toggleSidebar} />
        <Link
          to={ROUTES.DASHBOARD}
          onClick={() => setIsSidebarOpen(false)}
          className="mx-auto"
        >
          <p className="xxs:block text-white-2 hidden w-full text-center uppercase">
            Espace administrateur
          </p>
        </Link>
      </div>

      <nav
        className={`bg-black-3 fixed z-10 flex min-h-screen w-64 max-w-[80%] pb-5 pt-[80px] md:translate-x-0 md:py-0 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-700 ease-in-out`}
      >
        <ul className="text-white-1 w-full font-semibold">
          <Link to={ROUTES.HOME} onClick={() => setIsSidebarOpen(false)}>
            <li className="hover:bg-black-2 mb-7 px-5 py-3 text-[1rem] shadow-lg first-letter:text-[1.2rem]">
              <div className="flex items-center">
                <img
                  src={logoxs}
                  alt="Logo de Joystique"
                  className="xs:block mr-3 hidden h-[30px]"
                />
                <p className="text-[0.8rem] uppercase first-letter:text-[1.1rem]">
                  Joystique{' '}
                  <span className="text-[0.5rem]">(Retour au site)</span>
                </p>
              </div>
            </li>
          </Link>
          <SidebarLink
            icon={<LuLayoutDashboard />}
            title="Dashboard"
            link={ROUTES.DASHBOARD}
            onClick={() => setIsSidebarOpen(false)}
          />
          <DropdwonClick
            icon={<LuGamepad2 />}
            title="Jeux"
            links={games}
            onClick={setIsSidebarOpen}
          />
          <SidebarLink
            icon={<LuUsers />}
            title="Utilisateurs"
            link={ROUTES.ADMIN_USERS}
            onClick={() => setIsSidebarOpen(false)}
          />
          <DropdwonClick
            icon={<BiNetworkChart />}
            title="Communauté"
            links={community}
            onClick={setIsSidebarOpen}
          />
          <SidebarLink
            icon={<MdOutlineMail />}
            title="Messagerie"
            link={ROUTES.ADMIN_MESSAGES}
            onClick={() => setIsSidebarOpen(false)}
          />
        </ul>
      </nav>
    </header>
  );
};

export default AdminNavbar;
