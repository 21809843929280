import { GrowthBook } from '@growthbook/growthbook-react';

// Configure GrowthBook
const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-Goa5yPT4CK4cTLhf', // Remplace par ta clé client
  enableDevMode: true, // Optionnel, utile pour le développement
  trackingCallback: (experiment, result) => {
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

// Ajouter des informations sur l'utilisateur
growthbook.setAttributes({
  id: Math.random().toString(36).substring(7),
  deviceType: 'desktop',
  browser: 'chrome',
  country: 'USA',
  age: Math.floor(Math.random() * 100),
});

export default growthbook;
