import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Button = ({
  link,
  onClick,
  className,
  children,
  variant = "default",
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
    if (link) {
      event.preventDefault();
      navigate(link);
      window.scrollTo(0, 0);
    }
  };

  const baseStyle =
    "block px-4 py-3 rounded-[4px] font-inter text-center transition-all duration-200 ease-in-out";
  const variantStyles = {
    default: " bg-accent-9 hover:bg-accent-10",
    secondary: "bg-black-3 hover:bg-black-4 text-white-2",
    third: "bg-white-4 hover:bg-white-5",
    four: "bg-red-500 hover:bg-red-400 text-white-1",
    five: "bg-black-7 hover:bg-black-6 text-white-1",
  };

  const combinedStyles = `${baseStyle} ${variantStyles[variant]} ${className}`;

  if (link) {
    return (
      <Link
        to={link}
        onClick={handleClick}
        className={combinedStyles}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    <button onClick={handleClick} className={combinedStyles} {...props}>
      {children}
    </button>
  );
};

export default Button;
