// src/components/Logo.js

import React from 'react';
import logoxs from '../../../assets/logo/logo-xs-wy.svg';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../utils/routesConfig';

const LogoMobile = () => {
  return (
    <Link
      to={ROUTES.HOME}
      className="xxs:block mx-3 hidden h-full min-w-[40px] lg:hidden"
    >
      <img src={logoxs} alt="Logo de Joystique" className="h-full" />
    </Link>
  );
};

export default LogoMobile;
