import React from 'react';
import { IconContext } from 'react-icons/lib';
import { RxCross2 } from 'react-icons/rx';

const SearchBar = ({
  isSearchBarOpen,
  toggleSearchBar,
  inputValue,
  onInputChange,
}) => {
  if (!isSearchBarOpen) return null;

  return (
    <div className="bg-black-1 fixed right-0 z-10 flex h-[64px] w-full items-center justify-center md:absolute">
      <div className="xs:w-[70%] s:w-[80%] relative w-[50%] max-w-[1280px] md:mx-5">
        {/* Icône de loupe */}
        <svg
          className="absolute left-3 top-1/2 size-5 -translate-y-1/2 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeWidth="2"
            d="M21 21l-6-6m2-6a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>

        {/* Input */}
        <input
          type="text"
          value={inputValue}
          onChange={onInputChange}
          className="focus:ring-accent-10 w-full rounded border px-10 py-2 focus:shadow-none focus:outline-none focus:ring-2"
          placeholder="Recherche..."
          autoComplete="off"
        />
      </div>

      {/* Icône de croix */}
      <div className="absolute right-3 top-2" onClick={toggleSearchBar}>
        <IconContext.Provider
          value={{ size: '20px', className: 'text-white-1 cursor-pointer' }}
        >
          <RxCross2 />
        </IconContext.Provider>
      </div>
    </div>
  );
};

export default SearchBar;
