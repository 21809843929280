import React, { useContext } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS, USER_IMAGE_URL } from '../../utils/backURL';
import Spinner from '../../components/spinner/Spinner';
import AuthContext from '../../context/AuthContext';
import StatCard from './StatCard';
import Button from '../../components/buttons/Button';
import { LuGamepad } from 'react-icons/lu';
import { FiUsers } from 'react-icons/fi';
import { IoStatsChartOutline } from 'react-icons/io5';
import { TfiStatsUp } from 'react-icons/tfi';
import { MdQueryStats } from 'react-icons/md';
import { ImStatsBars2 } from 'react-icons/im';
import * as ROUTES from '../../utils/routesConfig';

const Dashboard = () => {
  const { userData } = useContext(AuthContext);

  // Récupère les statistiques depuis l'API
  const fetchStats = async () => {
    const response = await axios.get(`${API_ENDPOINTS.STATS}`);
    return response.data;
  };

  // Utilise React Query pour charger les statistiques
  const {
    data: stats,
    isLoading: statsLoading,
    isError: statsError,
  } = useQuery(['stats'], fetchStats);

  // Affiche un spinner pendant le chargement des données
  if (statsLoading) return <Spinner />;
  // Affiche un message d'erreur si le chargement échoue
  if (statsError)
    return <p>Une erreur est survenue lors du chargement des statistiques.</p>;

  return (
    <div className="mx-3 mt-[90px] md:mx-8 md:mt-8">
      <div className="flex flex-col flex-wrap justify-between lg:flex-row">
        {/*//! Bienvenue */}
        <div className="bg-white-2 mb-8 flex w-full max-w-[400px] flex-wrap items-center rounded-3xl p-8 shadow-lg lg:w-1/2">
          <img
            src={`${USER_IMAGE_URL}${userData.profile_picture}`}
            alt={`${userData.alt_text}`}
            className="border-white-3 mr-8 h-full max-h-[110px] rounded-full border-2 object-cover object-center"
          />

          <div className="">
            <p className="xxs:text-md xs:text-xl text-xs font-bold">
              Bienvenue,{' '}
            </p>
            <p className="xxs:text-xl xs:text-4xl text-xs font-bold">
              {userData.username}
            </p>
          </div>
        </div>
        {/*//! Notifications */}
        <div className="mb-8 flex-1 py-4 lg:px-12">
          <p className="font-bungee text-black-4 text-2xl">Notifications</p>
          {/* Notification de jeux en attente */}
          {stats.gamesInWaiting > 0 ? (
            <div className="bg-white-2 my-3 flex items-center justify-between rounded-lg p-5 shadow-lg">
              <div>
                <p className="text-xl font-extrabold uppercase">
                  {stats.gamesInWaiting} jeux
                </p>
                <p className="text-[0.7rem] uppercase">
                  en attente d'approbation
                </p>
              </div>
              <div>
                <Button className="text-xs" link={ROUTES.ADMIN_GAMES}>
                  Verifier
                </Button>
              </div>
            </div>
          ) : (
            <p className="my-3">Aucune notification</p>
          )}
        </div>
      </div>

      <div className="mb-3 mt-5 lg:mx-12 lg:w-1/2">
        <p className="font-bungee text-black-4 text-2xl">Analytics</p>
      </div>
      {/*//! Statistiques */}
      <div className="s:grid-cols-2 grid grid-cols-1 gap-4 xl:grid-cols-4">
        <StatCard
          linkTo="/chemin-vers-quelque-part"
          bgColor="bg-black-2" // Classe Tailwind pour la couleur de fond
          icon={<LuGamepad />} // Remplacer par l'icône de votre choix
          icon1ClassName="text-black-3" // Classe Tailwind pour la couleur de l'icône
          statValue={stats.totalGames} // Valeur de la statistique
          statLabel="Jeux" // Label de la statistique
          followText="suivre" // Texte du lien
          chartIcon={<IoStatsChartOutline />} // Icône du graphique
        />
        <StatCard
          linkTo="/chemin-vers-quelque-part"
          bgColor="bg-accent-10"
          icon={<FiUsers />}
          icon1ClassName="text-black-3"
          icon2ClassName="text-black-3"
          statValue={stats.totalUsers}
          statLabel="Utilisateurs"
          followText="suivre"
          chartIcon={<TfiStatsUp />}
        />
        <StatCard
          linkTo="/chemin-vers-quelque-part"
          bgColor="bg-blue-400"
          icon={<FiUsers />}
          icon1ClassName="text-black-3"
          icon2ClassName="text-black-3"
          statValue={stats.totalUsers}
          statLabel="Total utilisateurs"
          followText="suivre"
          chartIcon={<MdQueryStats />}
        />
        <StatCard
          linkTo="/chemin-vers-quelque-part"
          bgColor="bg-red-400"
          icon={<FiUsers />}
          icon1ClassName="text-black-3"
          icon2ClassName="text-black-3"
          statValue={stats.totalUsers}
          statLabel="Total utilisateurs"
          followText="suivre"
          chartIcon={<ImStatsBars2 />}
        />
      </div>
    </div>
  );
};

export default Dashboard;
