import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../utils/backURL';
import AuthContext from '../context/AuthContext';
import Button from '../components/buttons/Button';
import LabeledInput from '../components/input/LabeledInput';
import AuthHeader from '../components/header/AuthHeader';
import { Helmet } from 'react-helmet';

const SendForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { isUserAuthenticated } = useContext(AuthContext);

  //! Redirection si l'utilisateur est déjà authentifié
  useEffect(() => {
    if (isUserAuthenticated) {
      navigate(location.state?.from || '/');
    }
  }, [isUserAuthenticated, navigate, location.state]);

  //! Connexion via API
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Réinitialiser les messages précédents
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const response = await axios.post(
        API_ENDPOINTS.SEND_EMAIL_FORGOT_PASSWORD,
        {
          email,
        },
      );
      setSuccessMessage(response.data.message);
    } catch (error) {
      setErrorMessage(error.response.data.error);
    }
  };

  return (
    <div className="lg:h-screen-100-64 lg:flex">
      <Helmet>
        <title>Réinitialisation du Mot de Passe | Joystique</title>
        <meta
          name="description"
          content="Réinitialisez votre mot de passe Joystique en saisissant votre adresse e-mail. Un lien de réinitialisation vous sera envoyé."
        />
      </Helmet>
      <div className="bg-login_banner h-screen-40-64 flex-none bg-cover bg-center bg-no-repeat lg:h-full lg:w-2/3"></div>
      <div className="xs:px-10 mx-auto flex min-h-[60vh] max-w-[600px] flex-col px-4 py-8 lg:h-full lg:w-1/3 lg:py-10">
        <AuthHeader activeLink="login" />
        <form onSubmit={handleSubmit}>
          <p className="mb-4 text-sm text-gray-700">
            Merci de saisir votre{' '}
            <span className="font-semibold">adresse e-mail</span> afin que nous
            puissions vous envoyer un lien pour réinitialiser votre mot de
            passe.
          </p>
          {successMessage && (
            <div className="my-4 rounded bg-green-100 p-3 text-center text-xs text-green-700">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="my-4 rounded bg-red-100 p-3 text-center text-xs text-red-700">
              {errorMessage}
            </div>
          )}
          <LabeledInput
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="flex items-center justify-between">
            <Button type="submit" className="xs:text-lg mt-4 w-full text-xs">
              Envoyer
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendForgotPassword;
