import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import Button from '../components/buttons/Button';
import LabeledInput from '../components/input/LabeledInput';
import AuthHeader from '../components/header/AuthHeader';
import { API_ENDPOINTS } from '../utils/backURL';
import { Helmet } from 'react-helmet';
import Navbar from '../components/layout/Navbar/Navbar';

const Register = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const { isUserAuthenticated } = useContext(AuthContext);

  //! Contraintes de validation
  const validateForm = () => {
    if (password.length < 8) {
      setError('Le mot de passe doit contenir au moins 8 caractères.');
      return false;
    }
    if (password !== passwordConfirm) {
      setError('Les mots de passe ne correspondent pas.');
      return false;
    }
    setError(''); // Clear any previous errors
    return true;
  };

  //! Mutation pour l'inscription
  const mutation = useMutation(
    () => axios.post(API_ENDPOINTS.REGISTER, { email, password, username }),
    {
      onSuccess: () => {
        setSuccess(
          'Inscription réussie. Un email de vérification a été envoyé.',
        );
      },
      onError: (error) => {
        const errorMsg =
          error.response?.data['hydra:description'] ||
          'Une erreur inconnue est survenue.';

        const formattedErrors = errorMsg
          .split('\n')
          .filter((msg) => msg.trim() !== '')
          .map((msg) => {
            const formattedMsg = msg.replace(/(email:|username:)\s?/, '');
            return formattedMsg;
          })
          .join(', ');

        setError(formattedErrors);
      },
    },
  );

  //! Mutation à la soumission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    mutation.mutate();
  };

  //! Redirection si déjà authentifié
  useEffect(() => {
    if (isUserAuthenticated) {
      navigate('/');
    }
  }, [isUserAuthenticated, navigate]);

  return (
    <>
      <Navbar />
      <div className="lg:min-h-screen-100-64 flex flex-col lg:mt-0 lg:flex-row lg:items-stretch">
        <Helmet>
          <title>
            Inscription à Joystique | Rejoignez la Communauté de Jeux
          </title>
          <meta
            name="description"
            content="Inscrivez-vous sur Joystique pour faire partie d'une communauté dynamique de jeux vidéo. Partagez, découvrez et contribuez à l'univers du jeu vidéo."
          />
        </Helmet>

        <div className="bg-login_banner hidden grow bg-cover bg-center bg-no-repeat lg:block lg:min-h-full lg:w-2/3"></div>
        <div className="xs:px-10 mx-auto flex max-w-[600px] grow flex-col overflow-auto px-4 pt-6 lg:w-1/3 lg:justify-normal lg:py-10">
          <AuthHeader activeLink="register" />
          {success && (
            <div className="my-4 rounded bg-green-100 p-3 text-center text-xs text-green-700">
              {success}
            </div>
          )}
          {error && (
            <div className="my-4 rounded bg-red-100 p-3 text-center text-xs text-red-700">
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit} className="">
            {/* input email */}
            <div className="mb-4">
              <LabeledInput
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                additionalLabelClass=""
              />
            </div>

            {/* input pseudo */}
            <div className="mb-4">
              <LabeledInput
                label="Pseudo"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                additionalLabelClass=""
              />
            </div>

            {/* input mdp */}
            <div className="mb-4">
              <LabeledInput
                label="Mot de passe"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                showPasswordCriteria={true}
                required
              />
            </div>

            {/* input confirmation mdp */}
            <div className="mb-4">
              <LabeledInput
                label="Confirmez le mot de passe"
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                required
                additionalLabelClass=""
              />
            </div>

            <Button
              type="submit"
              disabled={mutation.isLoading}
              className="xs:text-lg w-full truncate text-xs font-bold"
            >
              S'inscrire
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
