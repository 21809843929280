import React, { useEffect, useState } from 'react';

const Select = ({
  options,
  labelField,
  valueField,
  label,
  onChange,
  disabledOptions,
  value = '',
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSelected(value);
    onChange(value);
  };

  return (
    <div className="flex items-center ">
      {label && (
        <label className="bg-white-4 rounded-l px-3 py-2">{label}</label>
      )}
      <select
        value={selected}
        onChange={handleChange}
        className="w-full truncate p-2"
      >
        <option value="" disabled>
          Sélectionnez une option
        </option>
        {options.map((option, index) => (
          <option
            key={index}
            value={option[valueField]}
            disabled={disabledOptions.includes(option[valueField])}
            className=""
          >
            {option[labelField]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
