import React from 'react';
import Section from '../../../components/ui/Section';
import SectionHeader from '../../../components/ui/SectionHeader';
import Slider from '../../../components/slider/Slider';
import LinkImage from '../../../components/ui/LinkImage';
import { GAME_IMAGE_URL } from '../../../utils/backURL';
import * as ROUTES from '../../../utils/routesConfig'

const GamesSection = ({ title, games, route }) => {
  if (!games || games.length < 2) return null;

  return (
    <Section additionalClass="mx-4">
      <SectionHeader title={title} to={route} linkName="Voir tout" />
      <Slider
        data={games}
        renderSlide={(game) => (
          <LinkImage
            to={`${ROUTES.GAME_DETAILS.replace(':gameSlug', game.slug)}`}
            src={`${GAME_IMAGE_URL}${game.imageName}`}
            alt={`Image de ${game.title}`}
            title={game.title}
          />
        )}
      />
    </Section>
  );
};

export default GamesSection;
