import React, { useState, useCallback } from 'react';
import axios from 'axios';
import Spinner from '../../../components/spinner/Spinner';
import Pagination from '../../../components/pagination/Pagination';
import { API_ENDPOINTS } from '../../../utils/backURL';
import { useQuery } from '@tanstack/react-query';
import Button from '../../../components/buttons/Button';
import * as ROUTES from '../../../utils/routesConfig';

const AdminMessages = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const ITEMS_PER_PAGE = 30;
  const [selectedStatus, setSelectedStatus] = useState('');

  const buildURL = (page, status) => {
    let url = `${API_ENDPOINTS.FETCH_DISCUSSION}?page=${page}`;
    if (status) {
      url += `&status=${encodeURIComponent(status)}`;
    }
    return url;
  };

  const fetchDiscussions = useCallback(async () => {
    const url = buildURL(currentPage, selectedStatus);
    try {
      const response = await axios.get(url);
      setTotalPages(
        Math.ceil(response.data['hydra:totalItems'] / ITEMS_PER_PAGE),
      );
      return response.data['hydra:member'];
    } catch (error) {
      console.error('Erreur lors de la récupération des messages:', error);
    } finally {
    }
  }, [currentPage, selectedStatus]);

  const {
    data: discussions = [],
    isLoading: discussionsLoading,
  } = useQuery(['discussions', currentPage, selectedStatus], fetchDiscussions);

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  return (
    <div className="mx-3 mt-[90px] md:mx-8 md:mt-8">
      <div className="bg-white-2 mb-8 rounded-lg px-4 py-2 shadow-xl">
        <h1 className="font-bungee text-center text-xl">Messages</h1>
        <div className="my-4 flex flex-wrap items-center">
          <label
            htmlFor="status-select"
            className="mr-3 block text-sm font-semibold uppercase"
          >
            Statut :
          </label>
          <select
            id="status-select"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="rounded border px-3 py-2"
          >
            <option value="">Tous les statuts</option>
            <option value="en cours">Non-traité</option>
            <option value="cloturé">Traité</option>
          </select>
        </div>
      </div>
      <div className="overflow-x-auto rounded-lg shadow-xl">
        <table className="min-w-full table-auto text-xs">
          <thead>
            <tr className="bg-black-4 text-white-1 ">
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Actions
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Sujet
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Email
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Date
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Status
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                N° ticket
              </th>
            </tr>
          </thead>
          <tbody>
            {discussionsLoading ? (
              <tr>
                <td colSpan="5" className="text-center">
                  <Spinner />
                </td>
              </tr>
            ) : discussions ? (
              discussions.map((discussion) => (
                <tr
                  key={discussion.ticket}
                  className="hover:bg-white-4 text-center"
                >
                  <td className="whitespace-nowrap border px-2">
                    <Button
                      className="mx-auto py-0 text-xs"
                      link={`${ROUTES.ADMIN_MESSAGES_CONVERSATION.replace(
                        ':discussionTicket',
                        discussion.ticket,
                      )}`}
                    >
                      Vérifier
                    </Button>
                  </td>
                  <td className="whitespace-nowrap border px-4 font-semibold">
                    {discussion.subject}
                  </td>
                  <td className="whitespace-nowrap border px-4 font-semibold">
                    {discussion.authorEmail}
                  </td>
                  <td className="whitespace-nowrap border px-4">
                    {new Date(discussion.createdAt).toLocaleDateString()}
                  </td>
                  <td className="whitespace-nowrap border px-4 capitalize">
                    {discussion.status}
                  </td>
                  <td className="whitespace-nowrap border px-4">
                    {discussion.ticket}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  Aucun message disponible
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default AdminMessages;
