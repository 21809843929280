import React, { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../../../context/AuthContext';
import BurgerMenu from './BurgerMenu';
import LogoMobile from './LogoMobile';
import LogoDesktopAndLinks from './LogoDesktopAndLinks';
import SidebarMobile from './SidebarMobile';
import Overlay from './Overlay';
import SearchBar from './SearchBar';
import UserProfileDesktop from './UserProfileDesktop';
import IconSearchMobile from './IconSearchMobile';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../utils/backURL';
import { useQuery } from '@tanstack/react-query';
import SearchResults from './SearchResults';

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isUserAuthenticated, handleLogout, userData, isAdmin } =
    useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const closeTimeoutRef = useRef(null);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [displayedGames, setDisplayedGames] = useState([]);

  //! Construit l'URL pour la requête API en fonction de la recherche
  const buildURL = (page, search, status) => {
    let url = `${API_ENDPOINTS.FETCH_GAMES}?page=${page}`;
    if (search) {
      url += `&slug=${encodeURIComponent(search)}`;
    }
    if (status) {
      url += `&status=${encodeURIComponent(status)}`;
    }
    return url;
  };

  //! Fonction pour appeler l'API et rechercher des jeux
  const fetchSearchResults = async () => {
    // Remplace les espaces par des tirets dans la chaîne de recherche
    const formattedSearchQuery = searchQuery.replace(/\s+/g, '-');
    const url = buildURL(1, formattedSearchQuery, 'actif');
    try {
      const response = await axios.get(url);
      return response.data['hydra:member'];
    } catch (error) {
      throw new Error('Erreur lors de la recherche : ' + error.message);
    }
  };

  //! Query des jeux
  const { data: games, isLoading: isSearching } = useQuery(
    ['games', searchQuery],
    fetchSearchResults,
    {
      keepPreviousData: false,
      enabled: !!searchQuery,
    },
  );

  //! Useffect
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setSearchQuery(inputValue);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [inputValue]);

  useEffect(() => {
    setDisplayedGames(games || []);
  }, [games]);

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  //! Fonctions utiles
  const openDropdown = () => {
    setIsDropdownOpen(true);
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
  };

  const closeDropdown = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
      closeTimeoutRef.current = null;
    }, 200);
  };

  // Gestion de la sidebar ouverture/fermeture
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Pour fermer la sidebar (utilisé lors du clic sur overlay)
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  // Pour deconnecter + ferme la sidebar
  const handleLogoutAndClose = () => {
    handleLogout();
    closeSidebar();
  };

  const toggleSearchBar = () => {
    setIsSearchBarOpen(!isSearchBarOpen);

    // Si la barre de recherche est en train d'être fermée, réinitialiser les états
    if (isSearchBarOpen) {
      setInputValue(''); // Réinitialiser l'inputValue
      setDisplayedGames([]); // Vider les jeux affichés
      setSearchQuery(''); // Réinitialiser la requête de recherche
    }
  };

  //!**********************************************! Rendu
  return (
    <header>
      <nav className="bg-black-1 fixed top-0 z-30 w-full">
        <div className="flex h-[64px] grow items-center justify-between">
          {
            //*******************************! BurgerMenu mobile *******************************//
          }
          <BurgerMenu toggleSidebar={toggleSidebar} />

          {
            //*******************************! Logo mobile *******************************//
          }
          <LogoMobile />

          {
            //*******************************! Links version MD et logo *******************************//
          }

          <LogoDesktopAndLinks isUserAuthenticated={isUserAuthenticated} />
          {
            //*******************************! Sidebar *******************************//
          }
          <SidebarMobile
            isSidebarOpen={isSidebarOpen}
            closeSidebar={closeSidebar}
            userData={userData}
            isAdmin={isAdmin}
            handleLogoutAndClose={handleLogoutAndClose}
            isUserAuthenticated={isUserAuthenticated}
          />

          {
            //*******************************! Overlay assombrissement *******************************//
          }
          <Overlay isSidebarOpen={isSidebarOpen} closeSidebar={closeSidebar} />

          {
            //*******************************! Searchbar *******************************//
          }
          <IconSearchMobile toggleSearchBar={toggleSearchBar} />

          {
            //*******************************! Searchbar *******************************//
          }
          <SearchBar
            isSearchBarOpen={isSearchBarOpen}
            toggleSearchBar={toggleSearchBar}
            inputValue={inputValue}
            onInputChange={(e) => setInputValue(e.target.value)}
            games={games}
          />

          {
            //*******************************! SearchResult *******************************//
          }
          <SearchResults
            games={displayedGames}
            isSearchBarOpen={isSearchBarOpen}
            inputValue={inputValue}
            isLoading={isSearching}
            toggleSearchBar={toggleSearchBar}
          />

          {
            //*******************************! Profil et déconnexion MD et + *******************************//
          }
          <UserProfileDesktop
            isUserAuthenticated={isUserAuthenticated}
            handleLogout={handleLogout}
            userData={userData}
            isAdmin={isAdmin}
            isDropdownOpen={isDropdownOpen}
            openDropdown={openDropdown}
            closeDropdown={closeDropdown}
            toggleSearchBar={toggleSearchBar}
          />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
