import React from 'react';

const GameImageWithFilter = ({
  gameDetails,
  imageUrlBase,
  addContainerStyle,
}) => {
  // Conteneur avec flex pour centrer l'image et le filtre, et relative pour le positionnement absolu du filtre
  const baseContainerStyle =
    'w-1/2 h-full aspect-3/4 relative flex items-center justify-center lg:w-full';
  const combinedContainerStyle = `${baseContainerStyle} ${addContainerStyle}`;

  return (
    <div className={combinedContainerStyle}>
      {/* Image */}
      <img
        src={`${imageUrlBase}${gameDetails.gameImage}`}
        alt={gameDetails.gameTitle}
        className="lg:aspect-3/4 h-full w-full object-cover lg:h-auto lg:max-h-full lg:rounded-lg"
      />
      {/* Filtre */}
      <div className="bg-black-1 lg:aspect-3/4 absolute left-0 top-0 h-full w-full opacity-30 lg:rounded-lg"></div>
    </div>
  );
};

export default GameImageWithFilter;
