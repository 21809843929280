import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { Helmet } from 'react-helmet';
import { API_ENDPOINTS } from '../../utils/backURL';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import 'swiper/css';
import 'swiper/css/pagination';
import * as ROUTES from '../../utils/routesConfig';
import GamesSection from './components/GameSection';
import VersusSection from './components/VersusSection';
import HeroBanner from './components/HeroBanner';
import Section from '../../components/ui/Section';
import VotingGames from './components/VotingGames/VotingGames';
import MultiplesGames from './components/MultiplesGames/MultiplesGames';
import GamesList from './components/GamesList/GamesList';

const Home = () => {
  const [animate, setAnimate] = useState(false);
  const { isUserAuthenticated, userData } = useContext(AuthContext);
  const [isVoteChecking, setIsVoteChecking] = useState(true);
  const [hasVoting, setHasVoting] = useState(false);

  //*********************************************************************! Appel Api
  // Récupère des jeux : 12 jeux actif par odre de création
  const fetchGames = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_GAMES}?page=1&itemsPerPage=12&status=actif&order%5Bcreated_at%5D=desc`,
    );
    return response.data['hydra:member'];
  };

  // Récupèration des derniers jeux sortie
  const fetchRecentGames = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_RECENT_GAMES}&page=1&itemsPerPage=12&status=actif`,
    );
    return response.data['hydra:member'];
  };

  // Récupèration des prochaines sortie de jeux
  const fetchUpcomingGames = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_UPCOMING_GAMES}&page=1&itemsPerPage=12&status=actif`,
    );
    return response.data['hydra:member'];
  };

  // Récupèration du Versus Actif
  const fetchVersus = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_VERSUSES}?status=actif`,
    );
    return response.data['hydra:member'][0];
  };

  //*********************************************************************! Mise en cache
  const {
    data: lastGames,
    // isLoading: lastGamesLoading,
    // isError: lastGamesError,
  } = useQuery(['lastGames'], () => fetchGames());

  const {
    data: recentGames,
    // isLoading: lastGamesLoading,
    // isError: lastGamesError,
  } = useQuery(['latestGames'], () => fetchRecentGames());

  const {
    data: upcomingGames,
    // isLoading: lastGamesLoading,
    // isError: lastGamesError,
  } = useQuery(['upcomingGames'], () => fetchUpcomingGames());

  const {
    data: versus,
    // isLoading: versusLoading,
    // isError: versusError,
  } = useQuery(['versuses'], fetchVersus);

  //*********************************************************************! useEffect
  useEffect(() => {
    const checkUserVote = async () => {
      try {
        if (!versus || !versus.slug || !userData || !userData.id) {
          return;
        }
        setIsVoteChecking(true);
        const response = await axios.get(
          `${API_ENDPOINTS.FETCH_VERSUS_VOTE}?versus.slug=${versus.slug}&user.id=${userData.id}`,
        );
        if (response.data['hydra:member'].length > 0) {
          setHasVoting(true);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification du vote', error);
      } finally {
        setIsVoteChecking(false);
      }
    };

    if (versus && versus.slug && userData && userData.id) {
      checkUserVote();
    }
  }, [versus, userData]);

  // Relance l'animation du hero banner
  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Joystique | Découvrez et Partagez votre Passion pour les Jeux Vidéo
        </title>
        <meta
          name="description"
          content="Rejoignez Joystique, la plateforme interactive dédiée aux jeux vidéo. Explorez, évaluez, partagez et devenez acteur de votre univers ludique."
        />
      </Helmet>

      {!isUserAuthenticated ? (
        <div className="bg-black-1">
          <HeroBanner animate={animate} />
          <VotingGames />
          <MultiplesGames />
        </div>
      ) : (
        <Section additionalClass="mt-8" variant="wrapper">
          <GamesSection
            title="Dernières sorties"
            games={recentGames}
            route={ROUTES.GAMES}
          />
          <GamesSection
            title="Prochaines sorties"
            games={upcomingGames}
            route={ROUTES.GAMES}
          />
          <GamesSection
            title="Nouvelles publications"
            games={lastGames}
            route={ROUTES.GAMES}
          />
          <VersusSection
            isVoteChecking={isVoteChecking}
            hasVoting={hasVoting}
            versus={versus}
          />
        </Section>
      )}
    </div>
  );
};

export default Home;
