import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import LabeledInput from '../components/input/LabeledInput';
import AuthContext from '../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/buttons/Button';
import { API_ENDPOINTS } from '../utils/backURL';
import axios from 'axios';
import AlertMessage from '../components/alertMessage/AlertMessage';
import { IoAlertCircle } from 'react-icons/io5';
import { IconContext } from 'react-icons/lib';
import * as ROUTES from '../utils/routesConfig';
import Modal from '../components/modal/Modal';

const UserAccount = () => {
  const { isUserAuthenticated, userData, setUserData, handleLogout } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [alertPassword, setAlertPassword] = useState(false);
  const [showEmailFields, setShowEmailFields] = useState(false);
  const [emailData, setEmailData] = useState({
    newEmail: '',
    password: '',
  });
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [emailForDeletion, setEmailForDeletion] = useState('');
  const [passwordForDeletion, setPasswordForDeletion] = useState('');
  const [alertDeleteAccount, setAlertDeleteAccount] = useState(false);
  const [showUsernameFields, setShowUsernameFields] = useState(false);
  const [usernameData, setUsernameData] = useState({
    newUsername: userData.username,
    password: '',
  });
  const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
  const [alertUsernameSuccess, setAlertUsernameSuccess] = useState(false);

  //! Redirection si l'utilisateur n'est pas authentifié
  useEffect(() => {
    if (!isUserAuthenticated) {
      navigate(location.state?.from || '/');
    }
  }, [isUserAuthenticated, navigate, location.state]);

  //! Fonctions
  const togglePasswordFields = () => {
    setPasswordErrorMessage('');
    setShowPasswordFields((prev) => !prev);
  };

  const toggleEmailFields = () => {
    setEmailErrorMessage('');
    setShowEmailFields((prev) => !prev);
  };

  const handleEmailInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({ ...prev, [name]: value }));
  };

  const toggleUsernameFields = () => {
    setUsernameErrorMessage('');
    setAlertUsernameSuccess(false);
    setShowUsernameFields((prev) => !prev);
  };

  const handleUsernameInputChange = (e) => {
    const { name, value } = e.target;
    setUsernameData((prev) => ({ ...prev, [name]: value }));
  };

  //! Submit mail
  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setEmailErrorMessage('');

    try {
      // Mise à jour de l'email
      const updateResponse = await axios.post(
        API_ENDPOINTS.CHANGE_EMAIL,
        {
          currentPassword: emailData.password,
          newEmail: emailData.newEmail,
        },
        {
          withCredentials: true,
        },
      );

      // Si la mise à jour de l'email est réussie, réenvoyer l'email de vérification
      if (updateResponse.status === 200) {
        await axios.post(API_ENDPOINTS.RESEND_EMAIL_VERIF, {
          email: emailData.newEmail,
        });

        // Afficher l'alerte, déconnecter l'utilisateur et rediriger
        setShowEmailFields(false);
        handleLogout();
        navigate(ROUTES.LOGIN, { state: { emailUpdated: true } });
      }
    } catch (error) {
      // Gérer les erreurs
      const errorMessage =
        error.response?.data?.message ||
        "Une erreur s'est produite lors de la mise à jour de l'email.";
      setEmailErrorMessage(errorMessage);
    }
  };

  //! Submit mdp
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    setPasswordErrorMessage('');

    if (passwords.newPassword.length < 8) {
      setPasswordErrorMessage(
        'Le nouveau mot de passe doit comporter au moins 8 caractères.',
      );
      return;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(passwords.newPassword)) {
      setPasswordErrorMessage(
        'Le mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre.',
      );
      return;
    }

    if (passwords.newPassword !== passwords.confirmPassword) {
      setPasswordErrorMessage('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      await axios.post(
        API_ENDPOINTS.CHANGE_PASSWORD,
        {
          currentPassword: passwords.currentPassword,
          newPassword: passwords.newPassword,
        },
        {
          withCredentials: true,
        },
      );
      setShowPasswordFields(false);
      setAlertPassword(true);
      setPasswords({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Une erreur s'est produite lors de la mise à jour du mot de passe.";
      setPasswordErrorMessage(errorMessage);
    }
  };

  //! Submit delete compte
  const handleDeleteAccount = async (e) => {
    e.preventDefault();

    if (!emailForDeletion || !passwordForDeletion) {
      setAlertDeleteAccount(
        "L'email et le mot de passe sont obligatoires pour la suppression du compte.",
      );
      setTimeout(() => {
        setAlertDeleteAccount('');
      }, 3000);
      return;
    }

    try {
      const response = await axios.post(
        API_ENDPOINTS.DELETE_ACCOUNT,
        {
          email: emailForDeletion,
          password: passwordForDeletion,
        },
        {
          withCredentials: true,
        },
      );

      if (response.status === 200) {
        handleLogout();
        navigate(ROUTES.HOME);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Une erreur s'est produite lors de la suppression du compte.";
      console.error('Erreur lors de la suppression du compte', error);
      setAlertDeleteAccount(errorMessage);

      setTimeout(() => {
        setAlertDeleteAccount('');
      }, 3000);
    }
  };

  //! Submit pseudo
  const handleSubmitUsername = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        API_ENDPOINTS.CHANGE_USERNAME,
        { newUsername: usernameData.newUsername },
        { withCredentials: true },
      );
      if (response.status === 200) {
        setUserData((prevData) => ({
          ...prevData,
          username: usernameData.newUsername,
        }));
        setShowUsernameFields(false);
        setAlertUsernameSuccess(true);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Une erreur s'est produite lors de la mise à jour du pseudo.";
      setUsernameErrorMessage(errorMessage);
    }
  };

  //!!!*********************************************!!! Rendu
  return (
    <div className="flex flex-col items-center justify-center p-5 sm:px-8">
      <Helmet>
        <title>Paramètres | Joystique</title>
        <meta
          name="description"
          content="Contribuez à la communauté Joystique en proposant de nouveaux jeux. Partagez vos découvertes et enrichissez notre bibliothèque de jeux vidéo."
        />
      </Helmet>
      {/* Les alertes */}
      {alertPassword && (
        <AlertMessage
          title="Succès"
          message={'Votre mot de passe a été correctement mis à jour'}
          type="success"
          autoCloseTime={5000}
        />
      )}
      {alertDeleteAccount && (
        <AlertMessage
          title="Erreur"
          message={alertDeleteAccount}
          type="error"
        />
      )}
      {alertUsernameSuccess && (
        <AlertMessage
          title="Succès"
          message={'Votre pseudo a été correctement mis à jour'}
          type="success"
          autoCloseTime={5000}
        />
      )}

      <div className="bg-white-1 my-8 w-full max-w-[1280px] rounded-xl border pb-5 pt-2 shadow-sm">
        {/* 
          //! ***************************************************  Connexion 
          */}
        <div className="px-7">
          <h2 className="my-3 text-[1.05rem] font-semibold uppercase">
            Connexion
          </h2>
          {/* 
          //! ***************************  Email 
          */}
          {!showEmailFields ? (
            <LabeledInput
              label="Email"
              name="email"
              type="email"
              value={userData.email}
              additionalLabelClass="text-black-7"
              additionalInputClass="text-black-7"
              additionalContainerClass="pt-2 pb-3"
              disabled={true}
              showButton={true}
              buttonText="Modifier"
              onButtonClick={toggleEmailFields}
            />
          ) : (
            <>
              <div className="my-5 border"></div>
              {emailErrorMessage && (
                <div className="my-4 rounded bg-red-100 p-3 text-center text-xs text-red-700">
                  {emailErrorMessage}
                </div>
              )}
              <form onSubmit={handleSubmitEmail} className="">
                <LabeledInput
                  label="Nouvel Email"
                  name="newEmail"
                  type="email"
                  placeholder="Nouvel Email"
                  additionalContainerClass="pt-2 pb-3"
                  value={emailData.newEmail}
                  onChange={handleEmailInputChange}
                />
                <LabeledInput
                  label="Mot de passe actuel"
                  name="password"
                  type="password"
                  placeholder="Mot de passe actuel pour vérification"
                  additionalContainerClass="pt-2 pb-3"
                  value={emailData.password}
                  onChange={handleEmailInputChange}
                />
                <div className=" bg-white-6 relative mb-5 mt-2 rounded p-3 text-xs">
                  <IconContext.Provider
                    value={{
                      size: '20px',
                      className: 'absolute top-[-8px] left-[-8px]',
                    }}
                  >
                    <IoAlertCircle />
                  </IconContext.Provider>
                  Suite à la modification de votre email, vous serez déconnecté.
                  Un email de confirmation sera envoyé à votre nouvelle adresse.
                  Vous devrez le confirmer pour pouvoir vous reconnecter.
                </div>
                <div className="flex md:w-1/3">
                  <Button type="submit" className="mb-3 mr-4 w-1/2">
                    Valider
                  </Button>
                  <Button
                    onClick={toggleEmailFields}
                    className="mb-3 w-1/2"
                    variant="third"
                  >
                    Annuler
                  </Button>
                </div>
              </form>
              <div className="my-5 border"></div>
            </>
          )}

          {/* 
          //! ***************************  MDP 
          */}
          {!showPasswordFields ? (
            <LabeledInput
              label="Mot de passe"
              name="password"
              type="password"
              value="********"
              additionalLabelClass="text-black-7"
              additionalContainerClass="pt-2 pb-3"
              disabled={true}
              showButton={true}
              buttonText="Modifier"
              onButtonClick={togglePasswordFields}
            />
          ) : (
            <>
              <div className="my-5 border"></div>
              {passwordErrorMessage && (
                <div className="my-4 rounded bg-red-100 p-3 text-center text-xs text-red-700">
                  {passwordErrorMessage}
                </div>
              )}
              <form onSubmit={handleSubmitPassword} className="">
                <LabeledInput
                  label="Mot de passe actuel"
                  name="currentPassword"
                  type="password"
                  placeholder="Mot de passe actuel"
                  additionalContainerClass="pt-2 pb-3"
                  value={passwords.currentPassword}
                  onChange={handleInputChange}
                />
                <LabeledInput
                  label="Nouveau mot de passe"
                  name="newPassword"
                  type="password"
                  placeholder="Nouveau mot de passe"
                  additionalContainerClass="pt-2 pb-3"
                  value={passwords.newPassword}
                  onChange={handleInputChange}
                />
                <LabeledInput
                  label="Confirmez le mot de passe"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirmez le mot de passe"
                  additionalContainerClass="pt-2 pb-3"
                  value={passwords.confirmPassword}
                  onChange={handleInputChange}
                />
                <div className="flex md:w-1/3">
                  <Button type="submit" className="mb-3 mr-4 w-1/2">
                    Valider
                  </Button>
                  <Button
                    onClick={togglePasswordFields}
                    className="mb-3 w-1/2"
                    variant="third"
                  >
                    Annuler
                  </Button>
                </div>
              </form>
              <div className="my-5 border"></div>
            </>
          )}
        </div>

        {/* 
          //! ***************************************************  Informations 
          */}
        <div className="px-7">
          <h2 className="my-3 text-[1.05rem] font-semibold uppercase">
            Mes informations
          </h2>
          {/* 
        //! ***************************  Pseudo 
      */}
          {!showUsernameFields ? (
            <LabeledInput
              label="Pseudo"
              name="newUsername"
              type="text"
              value={usernameData.newUsername}
              additionalLabelClass="text-black-7"
              additionalInputClass="text-black-7"
              additionalContainerClass="pt-2 pb-3"
              disabled={true}
              showButton={true}
              buttonText="Modifier"
              onButtonClick={toggleUsernameFields}
            />
          ) : (
            <>
              {usernameErrorMessage && (
                <div className="my-4 rounded bg-red-100 p-3 text-center text-xs text-red-700">
                  {usernameErrorMessage}
                </div>
              )}
              <form onSubmit={handleSubmitUsername} className="">
                <LabeledInput
                  label="Nouveau Pseudo"
                  name="newUsername"
                  type="text"
                  placeholder="Nouveau Pseudo"
                  additionalContainerClass="pt-2 pb-3"
                  value={usernameData.newUsername}
                  onChange={handleUsernameInputChange}
                />
                <div className="flex md:w-1/3">
                  <Button type="submit" className="mb-3 mr-4 w-1/2">
                    Valider
                  </Button>
                  <Button
                    onClick={toggleUsernameFields}
                    className="mb-3 w-1/2"
                    variant="third"
                  >
                    Annuler
                  </Button>
                </div>
              </form>
              <div className="my-5 border"></div>
            </>
          )}
        </div>
      </div>
      <div className="bg-white-1 mb-8 w-full max-w-[1280px] rounded-xl shadow-sm">
        {/* 
          //! ***************************************************  Demande 
          */}
        <div className="rounded-lg border px-7 pb-5 pt-2">
          <h2 className="my-3 text-[1.05rem] font-semibold uppercase">
            Demande
          </h2>
          {/* 
          //! ***************************  Suppression de compte 
          */}
          <div>
            <button
              onClick={() => setModalOpen(true)}
              className="text-sm uppercase text-red-500"
            >
              Supprimer le compte
            </button>
            <Modal
              isOpen={modalOpen}
              title="Suppression de compte"
              text="La suppression de compte signifie que votre adresse email ainsi que toutes les données personnelles associées seront effacées de notre base de données. Afin de valider cette suppression merci de saisir votre adresse e-mail et votre mot de passe."
              onClose={() => setModalOpen(false)}
              onConfirm={handleDeleteAccount}
              showCancelButton={true}
              additionalTitleClass="font-bold"
            >
              <form
                onSubmit={handleDeleteAccount}
                className="flex flex-col"
                autoComplete="off"
              >
                <LabeledInput
                  label="Email"
                  type="email"
                  name="deleteEmail"
                  value={emailForDeletion}
                  onChange={(e) => setEmailForDeletion(e.target.value)}
                  additionalContainerClass="mb-2 mt-5"
                  additionalLabelClass="text-sm"
                  additionalInputClass="text-sm"
                  autoComplete="off"
                />
                <LabeledInput
                  label="Mot de passe"
                  type="password"
                  name="deletePassword"
                  value={passwordForDeletion}
                  onChange={(e) => setPasswordForDeletion(e.target.value)}
                  additionalContainerClass="mb-5"
                  additionalLabelClass="text-sm"
                  additionalInputClass="text-sm"
                  autoComplete="new-password"
                />
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccount;
