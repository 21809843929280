import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Helmet } from 'react-helmet';
import { API_ENDPOINTS } from '../utils/backURL';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { GAME_IMAGE_URL } from '../utils/backURL';
import 'swiper/css';
import 'swiper/css/pagination';
import * as ROUTES from '../utils/routesConfig';
import VersusResults from './Community/Versus/VersusResults';
import CurrentVersus from './Community/Versus/CurrentVersus';

const Community = () => {
  const { userData } = useContext(AuthContext);
  const [isVoteChecking, setIsVoteChecking] = useState(true);
  const [hasVoting, setHasVoting] = useState(false);

  //*********************************************************************! Appel Api
  // Récupèration du Versus Actif
  const fetchVersus = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_VERSUSES}?status=actif`,
    );
    return response.data['hydra:member'][0];
  };

  // Récupèration des Versus terminés
  const fetchVersuses = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_VERSUSES}?page=1&itemsPerPage=6&status=terminé`,
    );
    return response.data['hydra:member'];
  };

  //*********************************************************************! Mise en cache
  const {
    data: versus,
    // isLoading: versusLoading,
    // isError: versusError,
  } = useQuery(['versuses'], fetchVersus);

  const {
    data: versuses,
    // isLoading: versusesLoading,
    // isError: versusesError,
    // refetch: versusesRefetch,
  } = useQuery(['versusesResult'], fetchVersuses);

  //*********************************************************************! Vérifie si l'utilisateur a déjà voté
  useEffect(() => {
    const checkUserVote = async () => {
      try {
        if (!versus || !versus.slug || !userData || !userData.id) {
          return;
        }
        setIsVoteChecking(true);
        const response = await axios.get(
          `${API_ENDPOINTS.FETCH_VERSUS_VOTE}?versus.slug=${versus.slug}&user.id=${userData.id}`,
        );
        if (response.data['hydra:member'].length > 0) {
          setHasVoting(true);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification du vote', error);
      } finally {
        setIsVoteChecking(false);
      }
    };

    if (versus && versus.slug && userData && userData.id) {
      checkUserVote();
    }
  }, [versus, userData]);

  //! Return
  return (
    <>
      <Helmet>
        <title>
          Joystique | Découvrez et Partagez votre Passion pour les Jeux Vidéo
        </title>
        <meta
          name="description"
          content="Rejoignez Joystique, la plateforme interactive dédiée aux jeux vidéo. Explorez, évaluez, partagez et devenez acteur de votre univers ludique."
        />
      </Helmet>
      <div className="mx-auto my-8 max-w-[1280px]">
        {/* Duel de la semaine */}
        <CurrentVersus
          isVoteChecking={isVoteChecking}
          versus={versus}
          hasVoting={hasVoting}
          GAME_IMAGE_URL={GAME_IMAGE_URL}
          ROUTES={ROUTES}
        />
        {/* Résultat précédent versus */}
        <VersusResults versuses={versuses} GAME_IMAGE_URL={GAME_IMAGE_URL} />
      </div>
    </>
  );
};

export default Community;
