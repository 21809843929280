import React from 'react';

const ModalOverlay = ({ children, onClose }) => {
  const handleOverlayClick = (event) => {
    // Vérifier si le clic est sur l'élément overlay lui-même, et non sur ses enfants
    if (event.target === event.currentTarget) {
      onClose?.();
    }
  };

  return (
    <div
      className="fixed inset-0 z-20 flex h-full w-full items-center justify-center overflow-y-auto bg-gray-600/30"
      onClick={handleOverlayClick} // Utiliser onClick ici pour gérer le clic
    >
      {children}
    </div>
  );
};

export default ModalOverlay;
