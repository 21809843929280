import React, { useEffect, useState } from 'react';
import { animated } from 'react-spring';
import { RiVipCrownFill } from 'react-icons/ri';
import { formatPercentage } from '../../utils/formattingUtils';

const VotePercentageDisplay = ({
  animatedValue,
  staticValue,
  isWinner,
  addPercentStyle,
  showWinner,
  isVotingInProgress,
}) => {
  const [showCrown, setShowCrown] = useState(false);
  const basePercentStyle = 'text-4xl xs:text-5xl font-inter font-black';
  const combinedPercentStyle = `${basePercentStyle} ${addPercentStyle}`;

  useEffect(() => {
    if (isVotingInProgress) {
      setTimeout(() => {
        setShowCrown(true);
      }, 2500);
    } else {
      setShowCrown(true);
    }
  }, [showWinner, isVotingInProgress]);

  console.log("Valeur animée reçue:", animatedValue);
  console.log("Valeur statique reçue:", staticValue);

  return (
    <div className="relative m-auto">
      {isWinner && showWinner && showCrown && (
        <div className="crown-animation xs:left-[24.5px] text-accent-10 absolute -top-5 left-[17px]">
          <RiVipCrownFill size="20" />
        </div>
      )}
      <p className={combinedPercentStyle}>
        {staticValue || staticValue === 0 ? (
          <>
            <span>{formatPercentage(staticValue)}</span>
          </>
        ) : animatedValue ? (
          <animated.span>
            {animatedValue.to((v) => formatPercentage(v))}
          </animated.span>
        ) : null}
        <span className="text-xl">%</span>
      </p>
    </div>
  );
};

export default VotePercentageDisplay;
