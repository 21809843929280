import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../utils/routesConfig';
import logo from '../../../assets/logo/logo-xl-wy.svg';

const FooterLogo = () => {
  return (
    <div className="mx-auto flex max-w-[254px] justify-center">
      <Link to={ROUTES.HOME}>
        <img src={logo} alt="Logo de Joystique" className="h-[120px]" />
      </Link>
    </div>
  );
};

export default FooterLogo;
