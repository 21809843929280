import React, { useEffect, useState } from 'react';

const AlertMessage = ({
  title,
  message,
  type,
  position,
  autoCloseTime,
  externalCloseTrigger,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  // Fermeture automatique après un certain temps
  useEffect(() => {
    if (autoCloseTime) {
      const timer = setTimeout(() => setIsVisible(false), autoCloseTime);
      return () => clearTimeout(timer);
    }
  }, [autoCloseTime]);

  // Gérer la fermeture externe
  useEffect(() => {
    if (externalCloseTrigger) setIsVisible(false);
  }, [externalCloseTrigger]);

  // Position
  const defaultPosition =
    'top-[90px] xs:top-[70px] left-8 right-8 xs:left-5 xs:right-5';
  const alertPosition = position || defaultPosition;

  // Style
  const alertStyles = `fixed flex flex-col xs:flex-row ${alertPosition} p-2 xs:py-4 xs:px-5 rounded shadow-lg z-50 max-w-[350px] ${
    type === 'error' ? 'bg-red-200' : 'bg-accent-10'
  }`;

  if (!isVisible) return null;

  return (
    <div className={alertStyles}>
      <div className="xs:w-3/5 flex grow flex-col px-3">
        <span className="mb-1 font-semibold">{title}</span>
        <span className="text-sm">{message}</span>
      </div>
      <button
        onClick={() => setIsVisible(false)}
        className="absolute right-2 top-1 text-lg font-bold"
      >
        &times;
      </button>
    </div>
  );
};

export default AlertMessage;
