import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import AuthProvider from './context/AuthProvider';
import * as ROUTES from './utils/routesConfig';
import { ScrollToTop } from './utils/ScrollToTop';
import AdminRoute from './utils/AdminRoute';
import UserRoute from './utils/UserRoute';
import Layout from './components/layout/layout';
import AdminLayout from './components/layout/adminLayout';
import Home from './pages/Home/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Games from './pages/Games';
import ProposeGame from './pages/ProposeGame';
import ProposeGameSuccess from './pages/ProposeGameSuccess';
import GameDetails from './pages/GameDetails';
import Community from './pages/Community';
import UserProfile from './pages/UserProfile';
import UserAccount from './pages/UserAccount';
import Versus from './pages/Versus';
import Contact from './pages/Contact';
import ContactConversation from './pages/ContactConversation';
import Dashboard from './pages/admin/Dashboard';
import AdminGames from './pages/admin/Game/AdminGames';
import AdminGameEdit from './pages/admin/Game/AdminGameEdit';
import AdminPlatforms from './pages/admin/Game/AdminPlatforms';
import AdminPublishers from './pages/admin/Game/AdminPublishers';
import AdminDevelopers from './pages/admin/Game/AdminDevelopers';
import AdminTags from './pages/admin/Game/AdminTags';
import AdminUsers from './pages/admin/User/AdminUsers';
import AdminVersus from './pages/admin/Community/Versus/AdminVersus';
import AdminVersusAdd from './pages/admin/Community/Versus/AdminAddVersus';
import AdminVersusEdit from './pages/admin/Community/Versus/AdminVersusEdit';
import AdminMessages from './pages/admin/Message/AdminMessages';
import AdminConversation from './pages/admin/Message/AdminConversation';
import LegalInformation from './pages/Legal/LegalInformation';
import VerifyEmail from './pages/VerifyEmail';
import ResendEmailVerif from './pages/ResendEmailVerif';
import SendForgotPassword from './pages/SendForgotPassword';
import ResetPassword from './pages/ResetPassword';
import TermsOfUse from './pages/Legal/TermsOfUse';
import PrivacyAndCookies from './pages/Legal/PrivacyAndCookies';
import FAQ from './pages/FAQ';
import About from './pages/About';
import NotFoundPage from './pages/NotFoundPage';

//prettier-ignore
function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          {/* //! Routes classique */}
          <Route path={ROUTES.HOME} element={<Layout />}>
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.GAMES} element={<Games />} />
            <Route path={ROUTES.GAME_DETAILS} element={<GameDetails />} />
            <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route path={ROUTES.RESEND_EMAIL_VERIF} element={<ResendEmailVerif />}/>
            <Route path={ROUTES.SEND_EMAIL_FORGOT_PASSWORD} element={<SendForgotPassword />}/>
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={ROUTES.USER_PROFILE} element={<UserProfile />} />
            <Route path={ROUTES.CONTACT} element={<Contact />} />
            <Route path={ROUTES.LEGALINFORMATION} element={<LegalInformation />} />
            <Route path={ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />
            <Route path={ROUTES.PRIVACY_AND_COOKIES} element={<PrivacyAndCookies />} />
            <Route path={ROUTES.FAQ} element={<FAQ />} />
            <Route path={ROUTES.ABOUT} element={<About />} />
            <Route path="*" element={<NotFoundPage />} />
            {/* //! Routes Users  */}
            <Route path={ROUTES.PROPOSE_GAME} element={<UserRoute><ProposeGame /></UserRoute>}/>
            <Route path={ROUTES.PROPOSE_GAME_SUCCESS} element={<UserRoute><ProposeGameSuccess /></UserRoute>}/>
            <Route path={ROUTES.USER_ACCOUNT} element={<UserRoute><UserAccount /></UserRoute>}/>
            <Route path={ROUTES.COMMUNITY} element={<UserRoute><Community /></UserRoute>}/>
          </Route>


          {/* //! Routes sans layout  */}
          <Route path={ROUTES.HOME} element={<Layout noFooter />}>
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.REGISTER} element={<Register />} />
            <Route path={ROUTES.VERSUS} element={<UserRoute><Versus /></UserRoute>}/>
            <Route path={ROUTES.CONTACT_CONVERSATION} element={<ContactConversation />}/>
          </Route>

          {/* //! Routes administrateur  */}
          <Route path={ROUTES.DASHBOARD} element={<AdminLayout />}>
            <Route path={ROUTES.DASHBOARD} element={<AdminRoute><Dashboard /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_GAMES} element={<AdminRoute><AdminGames /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_GAME} element={<AdminRoute><AdminGameEdit /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_PLATFORMS} element={<AdminRoute><AdminPlatforms /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_PUBLISHERS} element={<AdminRoute><AdminPublishers /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_DEVELOPPERS} element={<AdminRoute><AdminDevelopers /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_TAGS} element={<AdminRoute><AdminTags /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_USERS} element={<AdminRoute><AdminUsers /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_VERSUS} element={<AdminRoute><AdminVersus /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_VERSUS_ADD} element={<AdminRoute><AdminVersusAdd /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_VERSUS_EDIT} element={<AdminRoute><AdminVersusEdit /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_MESSAGES} element={<AdminRoute><AdminMessages /></AdminRoute>}/>
            <Route path={ROUTES.ADMIN_MESSAGES_CONVERSATION} element={<AdminRoute><AdminConversation /></AdminRoute>}/>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
