import React from 'react';
import Section from '../../../components/ui/Section';
import SectionHeader from '../../../components/ui/SectionHeader';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../utils/routesConfig';
import Slider from '../../../components/slider/Slider';

const VersusResults = ({ versuses, GAME_IMAGE_URL }) => {
  return (
    <>
      {versuses && (
        <Section additionalClass="mx-4">
          <SectionHeader
            title="Résultats derniers duels"
            additionalClass="text-center"
          />
          <Slider
            data={versuses}
            maxSlides="3.33"
            renderSlide={(versusDetail) => (
              <>
                <div className="bg-white-1 rounded-xl border p-4 text-center shadow-sm">
                  {/* Titre et Date */}
                  <h3 className="truncate text-lg font-semibold">
                    {versusDetail.title}
                  </h3>
                  <p className="mb-5 text-xs text-gray-600">
                    Du {new Date(versusDetail.startTime).toLocaleDateString()}{' '}
                    au {new Date(versusDetail.endTime).toLocaleDateString()}
                  </p>
                  {/* Images et pourcentages */}
                  <div className="flex items-center justify-around">
                    {/* Jeu 1 du versus */}
                    <div className="w-1/3">
                      <div className="">
                        <img
                          src={`${GAME_IMAGE_URL}${versusDetail.gameVotesDetails[0].gameImage}`}
                          alt={versusDetail.gameVotesDetails[0].gameTitle}
                          className="aspect-3/4 rounded-lg"
                        />
                      </div>
                      <p className="mt-1 truncate text-xs">
                        {versusDetail.gameVotesDetails[0].gameTitle}
                      </p>
                      <p className="font-semibold">
                        {Math.round(
                          versusDetail.gameVotesDetails[0].votePercentage,
                        )}
                        %
                      </p>
                    </div>
                    <span className="xs:block hidden text-lg font-bold">
                      Vs
                    </span>
                    {/* Jeu 2 du versus */}
                    <div className="w-1/3">
                      <div className="">
                        <img
                          src={`${GAME_IMAGE_URL}${versusDetail.gameVotesDetails[1].gameImage}`}
                          alt={versusDetail.gameVotesDetails[1].gameTitle}
                          className="aspect-3/4 rounded-lg"
                        />
                      </div>
                      <p className="mt-1 truncate text-xs">
                        {versusDetail.gameVotesDetails[1].gameTitle}
                      </p>
                      <p className="font-semibold">
                        {Math.round(
                          versusDetail.gameVotesDetails[1].votePercentage,
                        )}
                        %
                      </p>
                    </div>
                  </div>
                  <div className="ml-auto mt-5 w-full text-right">
                    <Link
                      to={`${ROUTES.VERSUS.replace(':versusSlug', versusDetail.slug)}`}
                      className="text-black-8 text-xs font-thin underline underline-offset-4"
                    >
                      En savoir +
                    </Link>
                  </div>
                </div>
              </>
            )}
          />
        </Section>
      )}
    </>
  );
};

export default VersusResults;
