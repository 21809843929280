import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="text-black-1 mx-auto my-8 max-w-[1280px] px-4 font-sans">
      <h1 className="mb-4 text-3xl font-bold">
        Conditions Générales d'Utilisation
      </h1>
      <p>
        Le site Joystique, accessible à{' '}
        <a href="https://www.joystique.fr">l'adresse </a>, est soumis aux
        conditions générales d'utilisation suivantes. L'utilisation du site
        implique l'acceptation pleine et entière des conditions d'utilisation
        décrites ci-dessous. Ces conditions d'utilisation sont susceptibles
        d'être modifiées ou complétées à tout moment.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        1. Ajout de Jeux à la Base de Données
      </h2>
      <p>
        Les utilisateurs inscrits ont la possibilité d'ajouter des jeux à la
        base de données du site. Ces ajouts sont soumis à une validation par
        l'administrateur avant d'être rendus publics. Chaque jeu soumis doit
        inclure des informations précises et vérifiées.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        2. Utilisation du Chat de Discussion
      </h2>
      <p>
        Joystique offre un chat de discussion permettant aux utilisateurs de
        communiquer directement avec l'administrateur. Ce service est destiné à
        fournir une assistance, répondre à des questions et recueillir des
        commentaires. Les utilisateurs sont priés de rester courtois et
        respectueux dans leurs communications.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        3. Participation aux jeu communautaire"
      </h2>
      <p>
        Joystique propose des jeu, la composition de ces jeux est strictement
        choisi par l'administrateur. Les choix des utilisateurs contribuent à
        établir un classement des jeux populaires sur le site.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        4. Responsabilités de l'Utilisateur
      </h2>
      <p>
        Les utilisateurs s'engagent à ne pas publier de contenu inapproprié,
        offensant, illégal ou en violation des droits d'auteur. Le site se
        réserve le droit de supprimer tout contenu ne respectant pas ces
        critères et de prendre les mesures appropriées en cas de violation
        répétée.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        5. Modifications des Conditions Générales d'Utilisation
      </h2>
      <p>
        Joystique se réserve le droit de modifier à tout moment les présentes
        CGU. Les utilisateurs seront informés de toute modification majeure et
        devront accepter les nouvelles conditions pour continuer à utiliser le
        site.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        6. Droits d'Auteur et Utilisation d'Images
      </h2>
      <p>
        Les utilisateurs qui soumettent des jeux à la base de données de
        Joystique, y compris des images telles que des jaquettes de jeux,
        doivent s'assurer qu'ils ont le droit d'utiliser ces images ou qu'elles
        sont sous licence permettant leur utilisation. Joystique respecte les
        droits d'auteur et s'engage à ne pas enfreindre les droits de propriété
        intellectuelle des tiers.
      </p>
      <p>
        En soumettant des images, les utilisateurs garantissent qu'ils possèdent
        les droits nécessaires ou qu'ils ont obtenu l'autorisation appropriée
        pour leur utilisation. Les images protégées par des droits d'auteur ne
        doivent être soumises qu'avec l'autorisation explicite du détenteur des
        droits.
      </p>
      <p>
        Joystique se dégage de toute responsabilité en cas de violation des
        droits d'auteur par les utilisateurs. En cas de réclamation relative à
        une violation des droits d'auteur, le contenu litigieux sera retiré du
        site dans les plus brefs délais.
      </p>
    </div>
  );
};

export default TermsOfUse;
