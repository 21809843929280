import React, { useEffect } from 'react';
import ModalOverlay from './ModalOverlay';
import Button from '../buttons/Button';
import { RxCross2 } from 'react-icons/rx';

/**
 * Composant Modal - affiche une boîte modale avec un contenu personnalisable.
 *
 * Props:
 * - isOpen: Booléen indiquant si le modal est visible.
 * - title: Titre du modal.
 * - text: Contenu texte du modal.
 * - onClose: Fonction appelée à la fermeture du modal.
 * - onConfirm: Fonction appelée lors de la confirmation.
 * - showCloseIcon: Indique si l'icône de fermeture doit être affichée.
 * - showCancelButton: Indique si le bouton d'annulation doit être affiché.
 * - additionalContainerClass: Classes supplémentaires pour le style du conteneur.
 * - additionalTitleClass: Classes supplémentaires pour le style du titre.
 * - additionalTextClass: Classes supplémentaires pour le style du text.
 * - buttonVariant: Choix de la variante du boutton de confirmation.
 */

const Modal = ({
  children,
  isOpen,
  title,
  text,
  onClose,
  onConfirm,
  showCloseIcon,
  showCancelButton,
  additionalTitleClass,
  additionalContainerClass,
  additionalTextClass,
  buttonVariant = 'four',
}) => {
  const defaultContainerClass =
    'bg-white-1 pt-5 pb-3 px-5 rounded-xl shadow-lg max-w-[400px] mx-5 sm:mx-auto fixed left-0 right-0';
  const combinedContainerClass = `${defaultContainerClass} ${additionalContainerClass}`;
  const defaultTitleClass = 'text-center text-black-4 mb-2';
  const combinedTitleClass = `${defaultTitleClass} ${additionalTitleClass}`;
  const defaultTextClass = 'mb-4 text-center text-sm';
  const combinedTextClass = `${defaultTextClass} ${additionalTextClass}`;

  // Empêcher le scroll quand le modal est ouvert
  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
    return () => {
      body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  // Empêcher la propagation de fermeture du modal qui doit s'appliquer uniquement lors du clic sur l'overlay
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  //!!****************************************!! Rendu !!***************************************************//
  return (
    <ModalOverlay onClose={onClose}>
      <div
        className={combinedContainerClass}
        onMouseDown={stopPropagation}
        onMouseUp={stopPropagation}
      >
        {showCloseIcon && onClose && (
          <div className="absolute right-4 top-2">
            <RxCross2
              onClick={onClose}
              size={'22px'}
              className="cursor-pointer"
            />
          </div>
        )}
        <h2 className={combinedTitleClass}>{title}</h2>
        <p className={combinedTextClass}>{text}</p>
        {children}
        <div className="flex flex-wrap justify-between">
          {onConfirm && (
            <Button
              onClick={() => {
                if (onConfirm) {
                  onConfirm();
                }
                onClose();
              }}
              variant={`${buttonVariant}`}
              className="xs:w-[48%] xs:mb-0 mb-2 w-full text-sm uppercase"
            >
              Confirmer
            </Button>
          )}
          {showCancelButton && onClose && (
            <Button
              onClick={onClose}
              variant="third"
              className="xs:w-[48%] w-full text-sm uppercase"
            >
              Annuler
            </Button>
          )}
        </div>
      </div>
    </ModalOverlay>
  );
};

export default Modal;
