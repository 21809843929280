import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../utils/routesConfig';

const GameDetailsGrid = ({ versus }) => {
  return (
    <div className="xs:grid-cols-3 grid grid-cols-2 gap-3 gap-y-5 p-5 text-center text-xs">
      {/* Titres des jeux */}
      <div className="truncate font-semibold uppercase">
        {versus.versusGames[0].game.title}
      </div>
      <div className="xs:block hidden"></div>
      <div className="truncate font-semibold uppercase">
        {versus.versusGames[1].game.title}
      </div>

      {/* Genres des jeux */}
      <div className="truncate">
        {versus.versusGames[0].game.gameTags
          .map((tag) => tag.tag.value)
          .join(', ')}
      </div>
      <div className="border-black-1 xs:block hidden border-x font-bold">
        Genre(s)
      </div>
      <div className="truncate">
        {versus.versusGames[1].game.gameTags
          .map((tag) => tag.tag.value)
          .join(', ')}
      </div>

      {/* Plateformes des jeux */}
      <div className="truncate">
        {versus.versusGames[0].game.gamePlatforms
          .map((platform) => platform.platform.name)
          .join(', ')}
      </div>
      <div className="border-black-1 xs:block hidden border-x font-bold">
        Plateforme(s)
      </div>
      <div className="truncate">
        {versus.versusGames[1].game.gamePlatforms
          .map((platform) => platform.platform.name)
          .join(', ')}
      </div>

      <div className="">
        {new Date(versus.versusGames[0].game.releaseDate).toLocaleDateString()}
      </div>
      <div className="border-black-1 xs:block hidden border-x font-bold">
        Date de sortie
      </div>
      <div className="">
        {new Date(versus.versusGames[1].game.releaseDate).toLocaleDateString()}
      </div>

      <div className="text-black-8 mt-5 font-thin underline underline-offset-4">
        <Link
          to={`${ROUTES.GAME_DETAILS.replace(
            ':gameSlug',
            versus.versusGames[0].game.slug,
          )}`}
        >
          En savoir +
        </Link>
      </div>
      <div className="xs:block hidden"></div>
      <div className="text-black-8 mt-5 font-thin underline underline-offset-4">
        <Link
          to={`${ROUTES.GAME_DETAILS.replace(
            ':gameSlug',
            versus.versusGames[1].game.slug,
          )}`}
        >
          En savoir +
        </Link>
      </div>
    </div>
  );
};

export default GameDetailsGrid;
