import React, { useContext, useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../utils/backURL';
import LabeledInput from '../components/input/LabeledInput';
import Button from '../components/buttons/Button';
import AlertMessage from '../components/alertMessage/AlertMessage';
import { Helmet } from 'react-helmet';
import logoxl from '../assets/logo/logo-xl-by.svg';
import AuthContext from '../context/AuthContext';

const Contact = () => {
  const [formData, setFormData] = useState({
    sender: '',
    subject: '',
    content: '',
  });
  const { userData } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertType, setAlertType] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payload = {
      content: formData.content,
      senderEmail: formData.sender,
      who: 'user',
      discussion: {
        authorEmail: formData.sender,
        subject: formData.subject,
        status: 'en cours',
      },
    };

    if (userData && userData.id) {
      // Si l'utilisateur est connecté
      payload.sender = `api/users/${userData.id}`;
      payload.discussion.author = `api/users/${userData.id}`;
    }

    try {
      await axios.post(API_ENDPOINTS.POST_CONTACT_MESSAGE, payload);
      setMessage('Message envoyé avec succès !');
      setAlertType('success');
      setAlertTitle('Envoyé');
      setFormData({ sender: '', subject: '', content: '' });
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    } catch (error) {
      if (
        error.response &&
        error.response.data['@type'] === 'ConstraintViolationList'
      ) {
        const violations = error.response.data.violations;
        let errorMessage = violations
          .map((violation) => violation.message)
          .join(' ');
        setMessage(errorMessage);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
      } else {
        setMessage("Erreur lors de l'envoi du message.");
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
      }
      setAlertTitle('Erreur');
      setAlertType('error');
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="min-h-screen-100-64 mx-4 flex flex-col items-center justify-center lg:mx-0">
        <Helmet>
          <title>Contactez Joystique | Votre Espace de Jeux Vidéo</title>
          <meta
            name="description"
            content="Contactez Joystique pour toute question ou information. Notre équipe est à votre disposition pour vous aider."
          />
        </Helmet>
        <div className="flex items-center justify-center">
          <div className="w-full max-w-[1280px]">
            <div className="space-x-0 lg:mb-12 lg:flex lg:space-x-6">
              <figure className="mx-auto mb-8 flex w-[full] max-w-[400px] items-center justify-center lg:mb-0 lg:w-1/3">
                <img src={logoxl} alt="Logo de Joystique" className="w-[90%]" />
              </figure>
              <div className="text-black-7 w-full lg:w-2/3">
                <p className="lg:text-xl">Vous souhaitez</p>
                <h1 className="text-2xl font-bold lg:text-4xl">
                  Nous contacter ?
                </h1>
                <p className="mb-6 lg:text-xl">
                  N'hésitez pas à consulter notre FAQ où vous pourriez trouver
                  des réponses immédiates à vos questions.
                </p>
              </div>
            </div>
            <div className="lg:flex">
              <div className="mb-8 w-full py-3 lg:w-1/3 lg:px-4 lg:text-lg">
                Vous pouvez également nous contacter ici :
                <ul className="custom-bullet">
                  <li>
                    Sur{' '}
                    <a
                      href="mailto:contact@joystique.fr"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="xs:text-base text-accent-11 hover:text-accent-12 text-xs hover:underline"
                    >
                      contact@joystique.fr
                    </a>
                  </li>
                  <li>
                    Via le{' '}
                    <a
                      href="https://twitter.com/Joystique_fr"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="xs:text-base text-accent-11 hover:text-accent-12 text-xs hover:underline"
                    >
                      compte twitter
                    </a>
                  </li>
                </ul>
              </div>
              <form
                onSubmit={handleSubmit}
                className="w-full space-y-4 lg:w-2/3"
              >
                <LabeledInput
                  label="Votre adresse email"
                  type="email"
                  name="sender"
                  value={formData.sender}
                  onChange={handleChange}
                  required
                />
                <LabeledInput
                  label="Sujet"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
                <LabeledInput
                  label="Votre message"
                  placeholder="Tapez ici votre demande."
                  asTextarea="true"
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  required
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full text-xs font-bold"
                >
                  Envoyer
                </Button>
              </form>
            </div>
            {showAlert && message && (
              <AlertMessage
                title={alertTitle}
                message={message}
                type={alertType}
                autoCloseTime={3000}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
