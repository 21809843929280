import React, { useEffect, useRef } from 'react';
import ModalOverlay from './ModalOverlay';
import { RxCross2 } from 'react-icons/rx';
import { MdArrowBack } from 'react-icons/md';
import { HiDotsVertical } from 'react-icons/hi';
import { FaHeart, FaPen, FaTrashAlt } from 'react-icons/fa';
import Button from '../buttons/Button';

const ListManagementModal = ({
  children,
  title,
  isOpen,
  Arrow,
  onClose,
  onBack,
  options,
  onRename,
  onDelete,
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [menuRef]);

  // Empêcher le scroll quand le modal est ouvert
  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
    return () => {
      body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ModalOverlay onClose={onClose}>
      <div className="bg-white-1 relative inset-x-0 h-screen w-screen overflow-y-auto p-5 py-8 shadow-lg sm:mx-auto md:h-auto md:max-h-[500px] md:min-h-[200px] md:w-[90%] md:max-w-[500px] md:rounded-xl">
        <div
          className={`flex ${
            options ? 'justify-between' : 'justify-center'
          } relative`}
        >
          {Arrow ? (
            <MdArrowBack
              onClick={onBack}
              size={'30px'}
              className={`cursor-pointer ${
                options ? '' : 'absolute left-2 top-0'
              }`}
            />
          ) : (
            <RxCross2
              onClick={onClose}
              size={'30px'}
              className={`cursor-pointer ${
                options ? '' : 'absolute left-2 top-0'
              }`}
            />
          )}
          <h2 className="text-black-4 mb-6 flex items-center text-center text-lg font-bold">
            {title === 'Favoris' && <FaHeart className="mr-2 text-red-400" />}
            {title}
          </h2>
          {options && (
            <div className=" cursor-pointer">
              <span onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <HiDotsVertical
                  size="25px"
                  className="text-black-4 hover:text-black-7 relative"
                />
              </span>
              {isMenuOpen && (
                <div
                  ref={menuRef}
                  className="bg-white-2 absolute right-0 z-10 rounded-lg border"
                >
                  <Button
                    variant=""
                    className="flex items-center p-3 text-sm"
                    onClick={onRename}
                  >
                    <FaPen className="mr-2" /> Modifier
                  </Button>
                  <Button
                    variant=""
                    className="flex items-center p-3 text-sm"
                    onClick={onDelete}
                  >
                    <FaTrashAlt className="mr-2" /> Supprimer
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {children}
      </div>
    </ModalOverlay>
  );
};

export default ListManagementModal;
