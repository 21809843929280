import React from 'react';

const SectionHeaderHome = ({
  title,
  subtitle,
  titleClassName = '',
  subtitleClassName = '',
}) => {
  return (
    <div className="relative z-20 text-center">
      <h2
        className={`mx-auto mb-6 max-w-[900px] text-3xl font-black sm:text-5xl sm:leading-[3rem] md:text-6xl md:leading-[5rem] ${titleClassName}`}
      >
        {title}
      </h2>
      <p
        className={`relative mx-auto max-w-[650px] text-sm font-[500] sm:text-2xl ${subtitleClassName}`}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default SectionHeaderHome;
