import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from '../utils/backURL';
import AuthContext from '../context/AuthContext';
import { Link } from 'react-router-dom';
import * as ROUTES from '../utils/routesConfig';
import { GAME_IMAGE_URL } from '../utils/backURL';
import Spinner from '../components/spinner/Spinner';
import Button from '../components/buttons/Button';
import { Helmet } from 'react-helmet';
import Pagination from '../components/pagination/Pagination';

const Games = () => {
  const { isUserAuthenticated } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const ITEMS_PER_PAGE = 30;

  const fetchGames = async (page = 1) => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_GAMES}?status=actif&page=${page}`,
    );
    setTotalPages(
      Math.ceil(response.data['hydra:totalItems'] / ITEMS_PER_PAGE),
    );
    return response.data['hydra:member'];
  };

  const {
    data: games,
    isLoading: gamesLoading,
    isError: gamesError,
  } = useQuery(['games', currentPage], () => fetchGames(currentPage));

  // Gère le changement de page
  const handlePageChange = (newPage) => setCurrentPage(newPage);

  if (gamesError) return <p>Une erreur est survenue.</p>;

  return (
    <>
      <Helmet>
        <title>Tous les Jeux | Joystique</title>
        <meta
          name="description"
          content="Explorez notre vaste collection de jeux vidéo sur Joystique. Trouvez vos favoris, découvrez de nouveaux titres et rejoignez notre communauté de passionnés."
        />
      </Helmet>
      {isUserAuthenticated ? (
        <div className="bg-white-4 mx-auto my-10 flex max-w-[1280px] flex-col border px-4 py-5 md:flex-row">
          <div className="sm:px-8 md:w-2/3">
            <h1 className="font-bungee text-black-2 px-2 text-center text-2xl md:px-4">
              Tous les jeux
            </h1>
            <div className="my-6 hidden text-center text-xs text-gray-700 md:block">
              <p>Découvrez et explorez notre collection complète de jeux.</p>
              <p className="mt-2">
                Indécis ? Profitez de nos recommandations personnalisées pour
                trouver votre prochain jeu favori.
              </p>
              <p className="mt-2">
                Un jeu manque à l'appel ? Proposez-le et contribuez à la
                richesse de notre bibliothèque tout en gagnant des points.
              </p>
            </div>
          </div>
          <div className="s:w-3/4 mx-auto my-3 flex w-full flex-col md:w-1/3 ">
            <Button
              link={ROUTES.HOME}
              className="bg-accent-10 group mb-4 flex items-center justify-between rounded-xl px-5 py-3"
            >
              <p className="xs:text-sm font-inter text-left text-xs font-bold">
                Découvrez votre prochain jeu
              </p>
              <span className="font-bungee text-black-3 text-[2rem] duration-1000 group-hover:animate-spin">
                ?
              </span>
            </Button>

            <Button
              link={ROUTES.PROPOSE_GAME}
              className="group flex items-center justify-between rounded-xl px-5 py-3"
              variant="secondary"
            >
              <p className="xs:text-sm font-inter text-left text-xs font-bold">
                Proposez un jeu à ajouter
              </p>
              <span className="font-bungee text-[2rem] group-hover:animate-spin">
                +
              </span>
            </Button>
          </div>
        </div>
      ) : (
        <h1 className="font-bungee text-black-2 my-12 px-2 text-center text-2xl md:px-4">
          Tout les jeux
        </h1>
      )}

      {gamesLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="xs:grid-cols-2 s:grid-cols-3 mx-auto grid max-w-[1280px] grid-cols-1 sm:grid-cols-4 lg:grid-cols-5">
            {games.map((game) => (
              <Link
                key={game.id}
                to={`${ROUTES.GAME_DETAILS.replace(':gameSlug', game.slug)}`}
              >
                <div className="m-2 p-2 transition duration-300 hover:-translate-y-1 hover:scale-105">
                  <img
                    src={`${GAME_IMAGE_URL}${game.imageName}`}
                    alt=""
                    className="mb-1 aspect-[3/4] w-full rounded object-cover"
                  />
                  <h3 className="truncate text-xs font-semibold md:text-sm">
                    {game.title}
                  </h3>
                </div>
              </Link>
            ))}
          </div>
          {totalPages > 1 && (
            <div className="mb-8">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Games;
