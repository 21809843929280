import React from 'react';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';

const StatCard = ({
  linkTo,
  bgColor,
  icon,
  icon1ClassName,
  icon2ClassName,
  statValue,
  statLabel,
  followText,
  chartIcon,
}) => {
  return (
    <Link to={linkTo} className="w-full overflow-hidden">
      <div
        className={`p-8 ${bgColor} text-white-2 group flex justify-between rounded-3xl shadow-md hover:cursor-pointer `}
      >
        <div className="flex flex-col items-start">
          <div className="bg-white-2 mb-7 rounded-lg p-1">
            <IconContext.Provider
              value={{ size: '40px', className: icon1ClassName }}
            >
              {icon}
            </IconContext.Provider>
          </div>
          <div className="">
            <p className="mb-2 text-5xl font-bold">{statValue}</p>
            <p className="text-[0.8rem] uppercase">{statLabel}</p>
          </div>
        </div>
        <div className="relative flex flex-col">
          <span className="mb-8 text-right text-xs group-hover:underline">
            {followText}
          </span>
          <div className="">
            <IconContext.Provider
              value={{
                size: '150px',
                className: `opacity-10 absolute bottom-[-25%] right-0 group-hover:scale-[120%] transition-transform duration-300 ${icon2ClassName}`,
              }}
            >
              {chartIcon}
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StatCard;
