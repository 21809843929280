import React from 'react';

const Mouse = () => {
  return (
    <>
      <div className="mouse bg-accent-8 absolute h-12 w-10 opacity-0" />
    </>
  );
};

export default Mouse;
