import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '../components/buttons/Button';
import * as ROUTES from '../utils/routesConfig';

const ProposeGameSuccess = () => {
  return (
    <div className="flex min-h-[500px] items-center justify-center px-4 ">
      <Helmet>
        <title>Succès de la soumission du jeu | Joystique</title>
      </Helmet>
      <div className="bg-white-2 mx-auto my-10 max-w-xl rounded border p-5 shadow-lg">
        <h2 className="mb-4 text-center text-[1rem] font-bold uppercase first-letter:text-[1.3rem]">
          Merci pour votre contribution 😍
        </h2>
        <p className="mb-4 text-center">
          Votre proposition sera maintenant analysée par un administrateur, vous
          retrouverez dans votre profil votre jeu une fois qu'il aura été
          accepté.
        </p>
        <Button link={ROUTES.GAMES} className="w-full">
          Continuer votre navigation
        </Button>
      </div>
    </div>
  );
};

export default ProposeGameSuccess;
