import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  API_ENDPOINTS,
  GAME_IMAGE_URL,
  BACKEND_URL,
} from '../../../utils/backURL';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IconContext } from 'react-icons';
import LabeledInput from '../../../components/input/LabeledInput';
import SearchableInput from '../../../components/input/SearchableInput';
import { generateSlug } from '../../../utils/slugger';
import { RxCross2 } from 'react-icons/rx';
import Button from '../../../components/buttons/Button';
import Select from '../../../components/select/Select';
import * as ROUTES from '../../../utils/routesConfig';
import Spinner from '../../../components/spinner/Spinner';

const AdminGameEdit = () => {
  const { gameSlug } = useParams();
  const [gameData, setGameData] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImageName, setUploadedImageName] = useState('');
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [searchTermPublisher, setSearchTermPublisher] = useState('');
  const [searchTermDeveloper, setSearchTermDeveloper] = useState('');
  const [publisherIRI, setPublisherIRI] = useState('');
  const [developerIRI, setDeveloperIRI] = useState('');
  const [newDeveloper, setNewDeveloper] = useState('');
  const [newPublisher, setNewPublisher] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectCount, setSelectCount] = useState(1);
  const [resetFirstKey, setResetFirstKey] = useState(0);
  const allSelected =
    selectedGenres.length === selectCount && !selectedGenres.includes('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [gameStatus, setGameStatus] = useState('en attente');

  //*********************************************! Fetch depuis API *****************************
  const fetchGame = async () => {
    const response = await axios.get(`${API_ENDPOINTS.FETCH_GAME}/${gameSlug}`);
    return response.data;
  };

  const fetchPublishers = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_PUBLISHERS}?name=${searchTermPublisher}`,
    );
    return response.data['hydra:member'];
  };

  const fetchDevelopers = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_DEVELOPERS}?name=${searchTermDeveloper}`,
    );
    return response.data['hydra:member'];
  };

  const fetchPlatforms = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_PLATFORMS}?status=actif`,
    );
    return response.data['hydra:member'];
  };

  const fetchGenres = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_TAGS}?tag_type.slug=genre&is_active=1`,
    );
    return response.data['hydra:member'];
  };

  //*********************************************! Configuration Query *****************************
  const {
    data: game,
    isLoading: gameLoading,
    // isError: gameError,
    // refetch: refetchGame,
  } = useQuery(['game'], fetchGame);

  const {
    data: publishers = [],
    // isLoading: publishersLoading,
    // isError: publishersError,
    // refetch: refetchPublishers,
  } = useQuery(
    ['publishers', searchTermPublisher],
    () => fetchPublishers(searchTermPublisher),
    {
      enabled: searchTermPublisher.length >= 2,
    },
  );

  const {
    data: developers = [],
    // isLoading: developersLoading,
    // isError: developersError,
    // refetch: refetchDevelopers,
  } = useQuery(
    ['developers', searchTermDeveloper],
    () => fetchDevelopers(searchTermDeveloper),
    {
      enabled: searchTermDeveloper.length >= 2,
    },
  );

  const {
    data: platforms,
    isLoading: platformsLoading,
    // isError: platformsError,
    // refetch: refetchPlatforms,
  } = useQuery(['platforms'], fetchPlatforms);

  const {
    data: genres,
    isLoading: genresLoading,
    // isError: genresError,
    // refetch: refetchGenres,
  } = useQuery(['genres'], fetchGenres);

  //*********************************************! Fonctions utiles *****************************

  useEffect(() => {
    if (game) {
      setGameData({
        id: game.id || '',
        title: game.title || '',
        slug: game.slug || '',
        description: game.description || '',
        beforeGamePlatforms: game.gamePlatforms ? game.gamePlatforms : [],
        beforeGameTags: game.gameTags ? game.gameTags : [],
        beforePlatforms: game.gamePlatforms
          ? game.gamePlatforms.map((gp) => gp.platform['@id'])
          : [],
        beforeTags: game.gameTags
          ? game.gameTags.map((gt) => gt.tag['@id'])
          : [],
        platforms: game.gamePlatforms
          ? game.gamePlatforms.map((gp) => gp.platform['@id'])
          : [],
        tags: game.gameTags ? game.gameTags.map((gt) => gt.tag['@id']) : [],
        releaseDate: game.releaseDate ? game.releaseDate.split('T')[0] : '',
        publisher: game.publisher ? game.publisher.name : '',
        developer: game.developer ? game.developer.name : '',
      });
      const initialSelectedGenres = game.gameTags
        ? game.gameTags.map((gt) => gt.tag['@id'])
        : [];
      setSelectCount(initialSelectedGenres.length);
      setSelectedGenres(initialSelectedGenres);
      setGameStatus(game.status);
    }
  }, [game]);

  // Gestion du changement dans les champs du formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setGameData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };

    if (file) {
      setUploadedImageName(file.name);
      setUploadedImageFile(file);
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    // Réinitialisez les états
    setUploadedImage(null);
    setUploadedImageName('');
    setUploadedImageFile(null);

    // Réinitialisez la valeur du champ de fichier
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  // Gestion du clic sur les plateformes
  const handlePlatformClick = (platform) => {
    setGameData((prevData) => {
      const existingPlatforms = [...prevData.platforms];
      const indexToRemove = existingPlatforms.findIndex(
        (id) => id === platform['@id'],
      );
      if (indexToRemove !== -1) {
        existingPlatforms.splice(indexToRemove, 1);
      } else {
        existingPlatforms.push(platform['@id']);
      }
      return {
        ...prevData,
        platforms: existingPlatforms,
      };
    });
  };

  const handleGenreChange = (value, index) => {
    let updatedSelectedGenres = [...selectedGenres];
    updatedSelectedGenres[index] = value;
    setSelectedGenres(updatedSelectedGenres);
    setGameData((prevData) => {
      return { ...prevData, tags: updatedSelectedGenres };
    });
  };

  const addSelect = () => {
    setSelectCount((prevCount) => prevCount + 1);
  };

  const handleDelete = (index) => {
    const newSelectedGenres = [...selectedGenres];
    const deletedGenre = newSelectedGenres.splice(index, 1)[0];

    setGameData((prevData) => {
      const newGameTags = prevData.tags.filter((tag) => tag !== deletedGenre);
      return { ...prevData, tags: newGameTags };
    });

    setSelectedGenres(newSelectedGenres);

    if (index === 0) {
      setResetFirstKey((prevKey) => prevKey + 1); // incrémente le compteur
    } else {
      setSelectCount((prevCount) => prevCount - 1);
    }
  };

  //*********************************************! Mutation *****************************
  const put = useMutation((editGame) => {
    const url = `${API_ENDPOINTS.EDIT_GAME}/${game.slug}`;
    return axios.put(url, editGame, {
      withCredentials: true,
    });
  });

  const postGameTags = useMutation((postGameData) => {
    const url = `${API_ENDPOINTS.POST_NEW_GAME_TAGS}`;
    return axios.post(url, postGameData, {
      withCredentials: true,
    });
  });

  const postGamePlatforms = useMutation((postGameData) => {
    const url = `${API_ENDPOINTS.POST_NEW_GAME_PLATFORMS}`;
    return axios.post(url, postGameData, {
      withCredentials: true,
    });
  });

  const deleteGamePlatforms = useMutation((deleteGameData) => {
    const url = `${BACKEND_URL}${deleteGameData}`;
    return axios.delete(url, {
      withCredentials: true,
    });
  });

  const deleteGameTags = useMutation((deleteGameData) => {
    const url = `${BACKEND_URL}${deleteGameData}`;
    return axios.delete(url, {
      withCredentials: true,
    });
  });

  const updateImage = useMutation((formData) => {
    const url = `${API_ENDPOINTS.EDIT_GAME_IMAGE}/${gameData.id}`;
    return axios.post(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      { withCredentials: true },
    );
  });

  //*********************************************! A la soumission du formulaire *****************************
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const gameDataToUpdate = {
        title: gameData.title,
        slug: generateSlug(gameData.title),
        description: gameData.description,
        releaseDate: gameData.releaseDate
          ? new Date(`${gameData.releaseDate}`).toISOString()
          : null,
        status: gameStatus,
      };

      if (newDeveloper) {
        const developerSlug = generateSlug(newDeveloper);
        const newDeveloperData = {
          name: newDeveloper,
          slug: developerSlug,
        };
        const developerResponse = await axios.post(
          API_ENDPOINTS.POST_NEW_DEVELOPER,
          newDeveloperData,
        );
        gameDataToUpdate.developer = developerResponse.data['@id'];
      }

      if (newPublisher) {
        const publisherSlug = generateSlug(newPublisher);
        const newPublisherData = {
          name: newPublisher,
          slug: publisherSlug,
        };
        const publisherResponse = await axios.post(
          API_ENDPOINTS.POST_NEW_PUBLISHER,
          newPublisherData,
        );
        gameDataToUpdate.publisher = publisherResponse.data['@id'];
      }

      if (developerIRI) {
        gameDataToUpdate.developer = developerIRI;
      }

      if (publisherIRI) {
        gameDataToUpdate.publisher = publisherIRI;
      }

      await put.mutateAsync(gameDataToUpdate);

      // Plateformes et tags à ajouter
      const platformsToAdd = gameData.platforms.filter(
        (platformId) => !gameData.beforePlatforms.includes(platformId),
      );
      const tagsToAdd = gameData.tags.filter(
        (tagId) => !gameData.beforeTags.includes(tagId),
      );

      // Plateformes et tags à supprimer
      const platformsToRemove = gameData.beforeGamePlatforms
        .filter((gp) => !gameData.platforms.includes(gp.platform['@id']))
        .map((gp) => gp['@id']);

      const tagsToRemove = gameData.beforeGameTags
        .filter((gt) => !gameData.tags.includes(gt.tag['@id']))
        .map((gt) => gt['@id']);

      // Ajout de nouvelles plateformes et de nouveaux tags
      for (const platformId of platformsToAdd) {
        const gamePlatformData = {
          platform: platformId,
          game: `api/game/${generateSlug(gameData.slug)}`,
        };
        await postGamePlatforms.mutateAsync(gamePlatformData);
      }

      for (const tagId of tagsToAdd) {
        const gameTagData = {
          tag: tagId,
          game: `api/game/${generateSlug(gameData.slug)}`,
        };
        await postGameTags.mutateAsync(gameTagData);
      }

      // Suppression des plateformes et des tags inutiles
      for (const gamePlatformId of platformsToRemove) {
        await deleteGamePlatforms.mutateAsync(gamePlatformId);
      }

      for (const gameTagId of tagsToRemove) {
        await deleteGameTags.mutateAsync(gameTagId);
      }

      if (uploadedImageFile) {
        const formData = new FormData();
        formData.append('imageFile', uploadedImageFile);

        await updateImage.mutateAsync(formData);
      }

      navigate(ROUTES.ADMIN_GAMES);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du jeu : ', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (
    gameLoading ||
    genresLoading ||
    platformsLoading ||
    !gameData ||
    isLoading
  ) {
    return <Spinner />;
  }

  const displayPlatforms = showAll ? platforms : platforms.slice(0, 5);

  //*********************************************! Rendu *****************************
  return (
    <div className="mx-auto mt-[90px] max-w-[1280px] md:mt-8">
      <div className="xs:px-4 w-full px-2 py-8">
        <div className="bg-white-2 mb-10 mt-3 rounded-xl py-6 shadow-lg lg:flex">
          {/************* Image jeu + upload {/*************/}
          <div className="xs:px-4 flex min-h-[500px] flex-col items-center px-2 lg:w-1/3 lg:min-w-[340px]">
            <p className="text-black-5 mb-2 font-medium">Cover du jeu</p>
            {/* Image */}
            <div className="bg-black-7 relative h-[400px] max-w-[300px] overflow-hidden rounded">
              {uploadedImage && (
                <img
                  src={uploadedImage}
                  alt=""
                  className="h-full w-full object-cover"
                />
              )}
              {!uploadedImage && (
                <img
                  src={`${GAME_IMAGE_URL}${game.imageName}`}
                  alt=""
                  className="h-full w-full object-cover"
                />
              )}
            </div>
            {/* Input caché pour donné du style */}
            <input
              type="file"
              accept="image/*"
              className="hidden"
              id="fileInput"
              onChange={handleImageUpload}
            />
            <div className="mt-3 flex">
              <label
                htmlFor="fileInput"
                className=" bg-white-5 border-white-6 cursor-pointer truncate rounded-l border px-2 py-1"
              >
                Télécharger une image
              </label>
              {uploadedImageName && (
                <div className="flex max-w-[90px] items-center rounded-r border px-2 py-1">
                  <span className="text-black-7 truncate text-sm">
                    {uploadedImageName}
                  </span>
                  <button onClick={handleImageRemove} className="ml-2">
                    <IconContext.Provider
                      value={{
                        size: '17px',
                        className: 'text-black-7',
                      }}
                    >
                      <RxCross2 />
                    </IconContext.Provider>
                  </button>
                </div>
              )}
            </div>
          </div>
          {/************* Début formulaire *************/}
          <form onSubmit={handleSubmit} className="xs:px-4 px-2 lg:w-2/3">
            {/* Status button */}
            <div className="mb-5 flex flex-col">
              <label for="">Statut</label>
              <div className="">
                <button
                  type="button"
                  className={`rounded px-4 py-2 ${
                    gameStatus === 'inactif'
                      ? 'bg-red-500 font-semibold'
                      : 'bg-gray-200'
                  }`}
                  onClick={() => setGameStatus('inactif')}
                >
                  Inactif
                </button>
                <button
                  type="button"
                  className={`mx-2 rounded px-4 py-2 ${
                    gameStatus === 'en attente'
                      ? 'bg-yellow-500 font-semibold'
                      : 'bg-gray-200'
                  }`}
                  onClick={() => setGameStatus('en attente')}
                >
                  Attente
                </button>
                <button
                  type="button"
                  className={`w-[100px] rounded px-3 py-2 ${
                    gameStatus === 'actif'
                      ? 'bg-green-500 font-semibold'
                      : 'bg-gray-200'
                  }`}
                  onClick={() => setGameStatus('actif')}
                >
                  Actif
                </button>
              </div>
            </div>

            {/* Titre */}
            <LabeledInput
              label="Titre"
              name="title"
              value={gameData.title}
              onChange={handleChange}
              required
              additionalInputClass="mb-5"
              placeholder="ex : Grand Theft Auto V"
            />

            {/* Description */}
            <LabeledInput
              label="Description"
              name="description"
              value={gameData.description}
              onChange={handleChange}
              asTextarea={true}
              additionalInputClass="mb-5"
            />

            {/* Platform */}
            <div className="border-white-6 mb-3 w-1/2 border-b-2">
              <p className="text-black-5 font-medium">Plateforme(s)</p>
            </div>
            <div className="-mr-4 mb-5 flex flex-wrap items-center">
              {displayPlatforms.map((platform, index) => {
                const isSelected = gameData.platforms.includes(platform['@id']);
                return (
                  <p
                    key={index}
                    className={`mb-3 mr-4 cursor-pointer select-none rounded border px-3 py-1 shadow-sm ${
                      isSelected
                        ? 'bg-accent-9 border-white-6 shadow-lg'
                        : 'bg-white-1 border-white-6 hover:bg-white-3 shadow-md'
                    }`}
                    onClick={() => handlePlatformClick(platform)}
                  >
                    {platform.name}
                  </p>
                );
              })}
              {platforms.length > 5 && (
                <p
                  onClick={() => setShowAll(!showAll)}
                  className="bg-white-4 hover:bg-white-5 mb-3 mr-4 cursor-pointer rounded p-2 text-xs font-bold uppercase"
                >
                  {showAll ? 'Voir moins' : 'Voir plus'}
                </p>
              )}
            </div>

            {/* Genre */}
            {genres && (
              <div className="mb-5">
                <div className="border-white-6 mb-3 w-1/2 border-b-2">
                  <p className="text-black-5 font-medium">Genre(s)</p>
                </div>
                {[...Array(selectCount)].map((_, index) => (
                  <div
                    key={`select-${index === 0 ? resetFirstKey : index}`}
                    className="mb-3 flex items-center"
                  >
                    {' '}
                    {/* utiliser resetFirstKey pour le premier élément */}
                    <Select
                      options={genres}
                      labelField="value"
                      valueField="@id"
                      label="Genre"
                      value={selectedGenres[index]}
                      onChange={(value) => handleGenreChange(value, index)}
                      disabledOptions={selectedGenres.filter(
                        (sg, sgIndex) => sgIndex !== index,
                      )}
                    />
                    <div
                      onClick={() => handleDelete(index)}
                      className="bg-white-4 cursor-pointer rounded-r p-3"
                    >
                      <IconContext.Provider
                        value={{
                          size: '17px',
                          className: 'text-black-7',
                        }}
                      >
                        <RxCross2 />
                      </IconContext.Provider>
                    </div>
                  </div>
                ))}
                {allSelected && selectCount < 5 && (
                  <button
                    onClick={addSelect}
                    className="bg-accent-9 rounded px-3 py-2 text-sm font-semibold"
                  >
                    + Ajouter un autre genre
                  </button>
                )}
              </div>
            )}

            {/* Date de sortie */}
            <LabeledInput
              label="Date de sortie"
              name="releaseDate"
              type="date"
              value={gameData.releaseDate}
              onChange={handleChange}
              additionalInputClass="mb-5 max-w-[48%]"
            />

            <div className="xs:flex xs:space-x-8">
              {/* Editeur */}
              <SearchableInput
                label="Editeur"
                name="publisher"
                options={publishers}
                value={gameData.publisher}
                onChange={handleChange}
                setSearchTerm={setSearchTermPublisher}
                optionKey="name"
                setIRI={setPublisherIRI}
                additionalInputClass="mb-5"
                isPreselected={!!gameData.publisher}
                setNewData={setNewPublisher}
              />
              {/* Développeur */}
              <SearchableInput
                label="Dévelopeur"
                name="developer"
                options={developers}
                value={gameData.developer}
                onChange={handleChange}
                setSearchTerm={setSearchTermDeveloper}
                optionKey="name"
                setIRI={setDeveloperIRI}
                additionalInputClass="mb-5"
                isPreselected={!!gameData.developer}
                setNewData={setNewDeveloper}
              />
            </div>

            <div className="xs:flex xs:space-x-8">
              <Button
                link={ROUTES.ADMIN_GAMES}
                variant="secondary"
                className="xs:mb-0 xs:w-1/2 mb-3 w-full"
              >
                Retour
              </Button>
              <Button type="submit" className="xs:w-1/2 w-full">
                Soumettre
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminGameEdit;
