import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

const Layout = ({ noFooter }) => {
  return (
    <div>
      <Navbar />
      <main className="pt-16">
        <Outlet />
      </main>
      {!noFooter && <Footer />}
    </div>
  );
};

export default Layout;
