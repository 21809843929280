// src/components/header/AuthHeader.js

import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../utils/routesConfig';
import logoxl from '../../assets/logo/logo-xl-by.svg';

const AuthHeader = ({ activeLink, headerText }) => {
  return (
    <header className="mx-auto mb-8 max-w-[300px]">
      <img src={logoxl} alt="Logo de Joystique" className="" />
      <p className="mb-8 px-2 text-center text-xs font-semibold">
        {headerText ||
          'Inscrivez-vous ou connectez-vous et commencez à profiter pleinement de la plateforme'}
      </p>
      <div className="xs:space-x-10 flex flex-wrap justify-center space-x-2">
        <Link
          to={ROUTES.LOGIN}
          className={`xs:text-lg text-xs font-bold ${activeLink === 'login' ? 'border-accent-10 border-b-2' : ''}`}
        >
          Connexion
        </Link>
        <Link
          to={ROUTES.REGISTER}
          className={`xs:text-lg text-xs font-bold ${activeLink === 'register' ? 'border-accent-10 border-b-2' : ''}`}
        >
          Inscription
        </Link>
      </div>
    </header>
  );
};

export default AuthHeader;
