import React from 'react';
import { GAME_IMAGE_URL } from '../../utils/backURL';

const Contribution = ({ contributions, username }) => {
  return (
    <div className="mb-12 grid grid-cols-1 gap-x-6 md:grid-cols-2 lg:grid-cols-3">
      {contributions.map((contribution) => (
        <div className="" key={contribution.id}>
          <div className="bg-white-1 mb-4 rounded-xl border p-5 shadow-sm">
            <div className="flex space-x-3">
              <img
                src={`${GAME_IMAGE_URL}${contribution.imageName}`}
                alt={`Cover de ${contribution.title}`}
                className="aspect-3/4 w-16 rounded-lg"
              />
              <div className="flex flex-col truncate whitespace-nowrap">
                <p className="truncate text-sm">Contribution de {username} :</p>
                <p className="truncate font-semibold">{contribution.title}</p>
                <p className="text-black-7 mt-auto truncate text-xs">
                  Validée le{' '}
                  {new Date(contribution.created_at).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Contribution;
