import React, { useState, useEffect, useRef } from 'react';
import { IconContext } from 'react-icons';
import { RxCross2 } from 'react-icons/rx';

/** A savoir sur le composant :
 * @param {string} label - Le texte du label (Obligatoire)
 * @param {string} labelDetail - Une précision au label (facultatif)
 * @param {string} name - Le nom du champ input (Obligatoire)
 * @param {Array} options - Les options pour la recherche (Obligatoire)
 * @param {any} value - La valeur du champ (Obligatoire)
 * @param {Function} onChange - Fonction pour gérer le changement de valeur (Obligatoire)
 * @param {Function} setSearchTerm - Fonction pour mettre à jour le terme de recherche (Obligatoire)
 * @param {string} [placeholder] - Le texte d'indication dans le champ (Facultatif)
 * @param {string} [additionalLabelClass] - Classes CSS supplémentaires pour le label (Facultatif)
 * @param {string} [additionalInputClass] - Classes CSS supplémentaires pour le input (Facultatif)
 * @param {boolean} [required] - Si le champ est obligatoire ou non (Facultatif)
 * @param {string} [optionKey='name'] - Clé des options à afficher, "name" par défaut (Facultatif)
 * @param {Function} [setIRI] - Fonction pour mettre à jour l'IRI de l'option sélectionnée (Facultatif)
 */

const SearchableInput = ({
  label,
  labelDetail = '',
  name,
  options,
  value,
  onChange,
  setSearchTerm,
  placeholder,
  additionalLabelClass = '',
  additionalInputClass = '',
  required = false,
  optionKey = 'name',
  setIRI,
  isPreselected = false,
  setNewData,
}) => {
  // État pour les options filtrées
  const [filteredOptions, setFilteredOptions] = useState([]);
  // État pour montrer/cacher le menu déroulant
  const [showDropdown, setShowDropdown] = useState(false);
  // État pour suivre si un élément a été sélectionné
  const [isItemSelected, setIsItemSelected] = useState(isPreselected);
  // Référence à l'élément input
  const containerRef = useRef(null);

  const defaultLabelClass = 'block text-black-5 font-medium';
  const defaultInputClass = 'flex justify-between w-full p-2 border rounded';

  const combinedLabelClass = `${defaultLabelClass} ${additionalLabelClass}`;
  const combinedInputClass = `${defaultInputClass} ${additionalInputClass}`;

  // Filtrer les options chaque fois que la valeur change
  useEffect(() => {
    if (value.length < 2 || isItemSelected) {
      setFilteredOptions([]);
      return;
    }

    const isSelected = options.some((option) => option[optionKey] === value);
    setIsItemSelected(isSelected);

    setFilteredOptions(
      options.filter((option) =>
        option?.[optionKey]?.toLowerCase().includes(value.toLowerCase()),
      ),
    );
    setShowDropdown(true);
  }, [value, options, isItemSelected, optionKey]);

  // Fonction appelée lorsque l'utilisateur clique sur une option
  const handleOptionClick = (option) => {
    setIsItemSelected(true);
    if (!isItemSelected) {
      onChange({ target: { name, value: option[optionKey] } });
    }
    setShowDropdown(false);
    if (setIRI) {
      setIRI(option['@id']);
    }
  };

  const handleAddDataClick = (value) => {
    setIsItemSelected(true);
    setShowDropdown(false);
    setNewData(value);
  };

  // Fonction pour réinitialiser la sélection
  const handleReset = () => {
    onChange({ target: { name, value: '' } });
    setSearchTerm('');
    setNewData('');
    setIsItemSelected(false);
  };

  // Fonction pour gérer le clic en dehors de l'input
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    // Ajouter l'écouteur d'événement lors du montage du composant
    document.addEventListener('click', handleClickOutside);

    // Supprimer l'écouteur d'événement lors du démontage du composant
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    // Div englobante qui a une position relative pour positionner correctement les enfants
    <div
      style={{ position: 'relative' }}
      ref={containerRef}
      className="xs:w-1/2"
    >
      {/* Label du champ input */}
      <label className={combinedLabelClass}>
        {label} {/* Détail supplémentaire pour le label, si nécessaire */}
        <span className="text-black-7 text-xs italic">{labelDetail}</span>
      </label>
      {/* Bloc conditionnel : Si un élément a été sélectionné */}
      {isItemSelected ? (
        <div className={combinedInputClass}>
          {value}
          <button type="button" onClick={handleReset}>
            <IconContext.Provider
              value={{
                size: '20px',
                className: 'text-black-7',
              }}
            >
              <RxCross2 />
            </IconContext.Provider>
          </button>
        </div>
      ) : (
        // Input pour la recherche si aucun élément n'est sélectionné
        <input
          type="text"
          name={name}
          value={value}
          placeholder={`${placeholder ? placeholder : 'Recherche'}...`}
          required={required}
          className={combinedInputClass}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            onChange(e);
          }}
          onFocus={() => {
            if (value.length < 2) {
              setShowDropdown(true);
            }
          }}
          autoComplete="off"
        />
      )}
      {/* Menu déroulant pour les options filtrées */}
      {showDropdown && (
        <ul className="bg-white-1 border-white-6 absolute z-50 mt-[-20px] w-full rounded border shadow-xl ">
          {value.length < 2 ? (
            <li className="px-2 py-3 text-xs italic">
              Merci d'entrer au moins 2 caractères
            </li>
          ) : filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className="hover:bg-white-4 cursor-pointer px-2 py-3"
              >
                {option[optionKey]}
              </li>
            ))
          ) : (
            // Si aucun résultat n'est trouvé
            <ul class="">
              <li className="px-2 py-3 italic">Aucun résultat</li>
              <li
                className="hover:bg-accent-9 cursor-pointer border-t px-2 py-3"
                onClick={() => handleAddDataClick(value)}
              >
                + Ajouter «<span className="font-bold"> {value} </span>»
              </li>
            </ul>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchableInput;
