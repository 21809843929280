import React from "react";
import { IconContext } from "react-icons";
import { AiOutlineMenu } from "react-icons/ai";

const BurgerMenu = ({ toggleSidebar }) => {
  return (

      <IconContext.Provider
        value={{
          size: "30px",
          className: "text-white-3 min-w-[30px] xxs:ml-5",
        }}
      >
        <AiOutlineMenu
          className="cursor-pointer lg:hidden"
          onClick={toggleSidebar}
        />
      </IconContext.Provider>

  );
};

export default BurgerMenu;
