import React from 'react';

const IconSearchMobile = ({ toggleSearchBar }) => {
  return (
    <div className="xxs:pr-5 lg:hidden" onClick={toggleSearchBar}>
      <svg
        className="text-white-1 size-7"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeWidth="2"
          d="M21 21l-6-6m2-6a7 7 0 11-14 0 7 7 0 0114 0z"
        ></path>
      </svg>
    </div>
  );
};

export default IconSearchMobile;
