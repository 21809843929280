const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPageNumbers = (currentPage, totalPages, range = 2) => {
    // Si totalPages est 0, retourner un tableau vide
    if (totalPages === 0) {
      return [];
    }

    const pages = [];
    for (
      let i = Math.max(1, currentPage - range);
      i <= Math.min(totalPages, currentPage + range);
      i++
    ) {
      pages.push(i);
    }

    if (!pages.includes(1)) {
      pages.unshift(1);
    }
    if (!pages.includes(totalPages)) {
      pages.push(totalPages);
    }

    return pages;
  };
  const pageNumbers = getPageNumbers(currentPage, totalPages);

  return (
    <div className="mt-8 flex items-center justify-center space-x-2">
      {/* Bouton précédent */}
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage <= 1}
        className="rounded bg-gray-200 px-3 py-1 hover:bg-gray-300 disabled:opacity-50 disabled:hover:bg-gray-200"
      >
        Précédent
      </button>

      {/* Numéros de page */}
      {pageNumbers.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          disabled={page === currentPage}
          className={`rounded px-3 py-1  ${
            page === currentPage
              ? 'bg-accent-9'
              : 'bg-gray-200 hover:bg-gray-300'
          }`}
        >
          {page}
        </button>
      ))}

      {/* Bouton suivant */}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage >= totalPages}
        className="rounded bg-gray-200 px-3 py-1 hover:bg-gray-300 disabled:opacity-50 disabled:hover:bg-gray-200"
      >
        Suivant
      </button>
    </div>
  );
};

export default Pagination;
