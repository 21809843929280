import React from 'react';
import { IconContext } from 'react-icons';
import { MdOutlineGroupAdd } from 'react-icons/md';
import Button from '../../../components/buttons/Button';

const HeroBanner = ({ animate }) => (
  <div className="bg-black-1 text-white-1 flex h-[calc(100dvh-64px)] max-h-[780px] items-center justify-center">
    <div
      className={`duration-1700 flex max-w-[900px] flex-col items-center text-center transition-all ease-in-out ${
        animate ? 'translate-y-0 opacity-100' : 'translate-y-[-5rem] opacity-0'
      }`}
    >
      <h1 className="px-5 text-4xl font-black sm:text-5xl sm:leading-[3rem] md:px-0 md:text-[80px] md:leading-[6rem]">
        Vivez l'aventure et racontez l'histoire sur{' '}
        <span className="text-accent-10">Joystique</span>.
      </h1>
      <p className="text-white-6 my-6 max-w-[400px] px-5 text-sm font-semibold sm:text-xl md:px-0">
        Devenez acteur de la référence en matière de jeux vidéo et participez à
        une communauté hyperactive !
      </p>
      <Button
        link="/login"
        className="text-black-1 flex items-center px-4 py-2 text-sm font-bold sm:px-8"
      >
        <IconContext.Provider
          value={{ size: '35px', className: 'mr-4 hidden sm:block' }}
        >
          <MdOutlineGroupAdd />
        </IconContext.Provider>
        Rejoindre la Communauté
      </Button>
    </div>
  </div>
);

export default HeroBanner;
