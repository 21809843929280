import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useSpring } from 'react-spring';
import { useParams } from 'react-router-dom';

import Navbar from '../components/layout/Navbar/Navbar';
import Spinner from '../components/spinner/Spinner';
import GameDetailsGrid from './Versus/GameDetailsGrid';
import GameImageWithFilter from './Versus/GameImageWithFilter';
import VotePercentageDisplay from './Versus/VotePercentageDisplay';
import VotingSection from './Versus/VotingSection';

import { API_ENDPOINTS, GAME_IMAGE_URL } from '../utils/backURL';
import AuthContext from '../context/AuthContext';

const Versus = () => {
  const { userData } = useContext(AuthContext);
  const { versusSlug } = useParams();
  const [isVoteChecking, setIsVoteChecking] = useState(true);
  const [hasVoting, setHasVoting] = useState(false);
  const [isVotingInProgress, setIsVotingInProgress] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [lowerPercentage, setLowerPercentage] = useState(0);
  const [higherPercentage, setHigherPercentage] = useState(0);
  const [showWinner, setShowWinner] = useState(false);

  //*********************************************************************! Appel Api
  const fetchVersus = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_VERSUS}/${versusSlug}`,
    );
    return response.data;
  };

  //*********************************************************************! Query
  const { data: versus, refetch: versusRefetch } = useQuery(
    ['versus', versusSlug],
    fetchVersus,
  );

  //*********************************************************************! useEffect
  // Vérifie si l'utilisateur a déjà voté
  useEffect(() => {
    const checkUserVote = async () => {
      if (!userData || !versusSlug) {
        return;
      }
      setIsVoteChecking(true);
      try {
        const response = await axios.get(
          `${API_ENDPOINTS.FETCH_VERSUS_VOTE}?versus.slug=${versusSlug}&user.id=${userData.id}`,
        );
        if (response.data['hydra:member'].length > 0) {
          setHasVoting(true);
          setShowWinner(true);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification du vote', error);
      } finally {
        setIsVoteChecking(false);
      }
    };

    checkUserVote();
  }, [userData, versusSlug]);

  // Empêcher le vote si status du versus n'est pas "actif"
  useEffect(() => {
    if (!versus) {
      return;
    }

    if (versus.status !== 'actif') {
      setHasVoting(true);
    }
  }, [versus]);

  //*********************************************************************! Animation
  // Recherche du pourcentage le plus bas
  useEffect(() => {
    if (
      versus &&
      versus.gameVotesDetails &&
      versus.gameVotesDetails.length > 1
    ) {
      const percentage1 = versus.gameVotesDetails[0].votePercentage;
      const percentage2 = versus.gameVotesDetails[1].votePercentage;

      setLowerPercentage(Math.min(percentage1, percentage2));
      setHigherPercentage(Math.max(percentage1, percentage2));
    }
  }, [versus]);

  // Durée pour atteindre le pourcentage le plus bas
  const baseDuration = 1000;

  // Calculer la durée proportionnelle pour le jeu avec le pourcentage le plus élevé
  const isOneHundredPercent =
    higherPercentage === 100 || lowerPercentage === 100;
  const durationForHigherPercentage = isOneHundredPercent
    ? 2000
    : (higherPercentage / lowerPercentage) * baseDuration;

  //************** UseSping pour animé le pourcentage
  const votePercentage1 = useSpring({
    from: { value: 0 },
    to: {
      value: startAnimation ? versus?.gameVotesDetails[0].votePercentage : 0,
    },
    config: {
      duration:
        versus?.gameVotesDetails[0].votePercentage === higherPercentage
          ? durationForHigherPercentage
          : baseDuration,
    },
    reset: isVotingInProgress,
  });

  const votePercentage2 = useSpring({
    from: { value: 0 },
    to: {
      value: startAnimation ? versus?.gameVotesDetails[1].votePercentage : 0,
    },
    config: {
      duration:
        versus?.gameVotesDetails[1].votePercentage === higherPercentage
          ? durationForHigherPercentage
          : baseDuration,
    },
    reset: isVotingInProgress,
  });

  //*********************************************************************! Envoi du formulaire lors du vote
  const handleSubmit = async (gameIndex) => {
    const selectedGameIri = versus.gameVotesDetails[gameIndex].gameSlug;
    const voteData = {
      game: '/api/game/' + selectedGameIri,
      versus: versus['@id'],
      user: '/api/users/' + userData.id,
    };

    try {
      await axios.post(API_ENDPOINTS.POST_VOTE, voteData);
      await versusRefetch();
      setIsVotingInProgress(true);
      setShowWinner(true);
      setTimeout(() => {
        setStartAnimation(true);
      }, 1000);
    } catch (error) {
      console.error("Erreur lors de l'envoi du vote", error);
    }
  };

  if (!versus || isVoteChecking) {
    return <Spinner />;
  }

  //*********************************************************************! Rendu
  return (
    <>
      <Navbar />
      <div className="md:h-screen-100-64 mx-auto max-w-[1280px]">
        {/* Versus section */}
        <div className="flex h-full flex-col lg:flex-row lg:items-center lg:justify-center">
          {/* Images avec filtres */}
          <div className="flex h-1/2 lg:h-auto lg:w-1/3 ">
            <GameImageWithFilter
              gameDetails={versus.gameVotesDetails[0]}
              imageUrlBase={GAME_IMAGE_URL}
              addContainerStyle=""
            />
            <GameImageWithFilter
              gameDetails={versus.gameVotesDetails[1]}
              imageUrlBase={GAME_IMAGE_URL}
              addContainerStyle="lg:hidden"
            />
          </div>

          {/* Container des boutons */}
          <div className="lg:flex lg:w-1/3 lg:flex-col lg:items-center lg:justify-center">
            {/* Affichage des pourcentages ou section de vote */}
            {hasVoting || isVotingInProgress ? (
              <div className="xs:grid-cols-3 grid h-[130px] max-w-full grid-cols-2 gap-3 gap-y-5 p-5">
                <VotePercentageDisplay
                  animatedValue={
                    isVotingInProgress ? votePercentage1.value : ''
                  }
                  staticValue={
                    hasVoting ? versus.gameVotesDetails[0].votePercentage : ''
                  }
                  isWinner={versus.gameVotesDetails[0].isWinner}
                  addPercentStyle=""
                  showWinner={showWinner}
                  isVotingInProgress={isVotingInProgress}
                />
                <div className="xs:block hidden"></div>
                <VotePercentageDisplay
                  animatedValue={
                    isVotingInProgress ? votePercentage2.value : ''
                  }
                  staticValue={
                    hasVoting ? versus.gameVotesDetails[1].votePercentage : ''
                  }
                  isWinner={versus.gameVotesDetails[1].isWinner}
                  addPercentStyle=""
                  showWinner={showWinner}
                  isVotingInProgress={isVotingInProgress}
                />
              </div>
            ) : (
              <VotingSection versus={versus} handleSubmit={handleSubmit} />
            )}
            {/* Détails des jeux */}
            <div className="mx-7 border-t"></div>
            <GameDetailsGrid versus={versus} />
          </div>

          {/* Deuxième image avec filtre pour le Desktop */}
          <div className="lg:w-1/3">
            <GameImageWithFilter
              gameDetails={versus.gameVotesDetails[1]}
              imageUrlBase={GAME_IMAGE_URL}
              addContainerStyle="hidden lg:flex"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Versus;
