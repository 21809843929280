import React from 'react';
import { Link } from 'react-router-dom';
import logoxs from '../../../assets/logo/logo-xs-wy.svg';
import logoxl from '../../../assets/logo/logo-xl-wy.svg';
import * as ROUTES from '../../../utils/routesConfig';

const LogoDesktopAndLinks = ({ isUserAuthenticated }) => {
  return (
    <div className="hidden h-full min-w-[376px] lg:flex lg:items-center">
      <Link to={ROUTES.HOME} className="h-full px-5 lg:hidden">
        <img src={logoxs} alt="Logo de Joystique" className="h-full" />
      </Link>
      <Link to={ROUTES.HOME} className="hidden h-full pl-5 pr-10 lg:block">
        <img
          src={logoxl}
          alt="Logo de Joystique"
          className="h-full max-w-[150px]"
        />
      </Link>
      <ul className="flex min-w-[275px] space-x-5 font-semibold uppercase">
        <li>
          <Link to={ROUTES.GAMES} className="text-white-1 hover:text-accent-9">
            Jeux
          </Link>
        </li>
        {isUserAuthenticated && (
          <li>
            <Link
              to={ROUTES.COMMUNITY}
              className="text-white-1 hover:text-accent-9"
            >
              <span className="">Communauté</span>
            </Link>
          </li>
        )}
        {/* <li>
          <Link to={ROUTES.HOME} className="text-white-1 hover:text-accent-9">
            À propos
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default LogoDesktopAndLinks;
