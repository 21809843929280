import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../utils/routesConfig';
import Button from '../../buttons/Button';
import { USER_IMAGE_URL } from '../../../utils/backURL';

const UserProfileDesktop = ({
  isUserAuthenticated,
  handleLogout,
  userData,
  isAdmin,
  isDropdownOpen,
  openDropdown,
  closeDropdown,
  toggleSearchBar,
}) => {
  return (
    <>
      <div className="relative ml-4 hidden h-full cursor-pointer items-center justify-between pl-1 pr-5 lg:flex">
        {/* Icône de loupe */}
        <div className="" onClick={toggleSearchBar}>
          <svg
            className="text-white-1 size-7"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeWidth="2"
              d="M21 21l-6-6m2-6a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        {isUserAuthenticated ? (
          <div
            className="relative ml-4 hidden h-full min-w-[215px] cursor-pointer items-center justify-between pl-1 pr-5 lg:flex"
            onMouseEnter={openDropdown}
            onMouseLeave={closeDropdown}
          >
            <img
              src={`${USER_IMAGE_URL}${userData.profile_picture}`}
              alt={`${userData.alt_text}`}
              className="border-white-3 size-[45px] border-2 object-cover object-center"
            />
            <Link to={ROUTES.HOME} className="text-white-1">
              {userData.username}
            </Link>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`text-white-1 size-5 transition-transform ${
                isDropdownOpen ? 'rotate-180' : ''
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>

            {isDropdownOpen && (
              <div className="bg-black-1 text-white-1 absolute right-0 top-full z-10 w-full text-sm uppercase shadow-lg">
                <Link
                  to={`${ROUTES.USER_PROFILE.replace(':userId', userData.id)}`}
                  onClick={() => {
                    closeDropdown();
                  }}
                  className="text-white-1 hover:bg-black-3 block px-4 py-2"
                >
                  Profil
                </Link>
                <Link
                  to={ROUTES.USER_ACCOUNT}
                  onClick={() => {
                    closeDropdown();
                  }}
                  className="text-white-1 hover:bg-black-3 block px-4 py-2"
                >
                  Paramètres
                </Link>
                {isAdmin && (
                  <Link
                    to={ROUTES.DASHBOARD}
                    onClick={() => {
                      closeDropdown();
                    }}
                    className="text-white-1 hover:bg-black-3 block truncate px-4 py-2"
                  >
                    Espace Administrateur
                  </Link>
                )}
                <Link
                  to={ROUTES.HOME}
                  onClick={() => {
                    handleLogout();
                    closeDropdown();
                  }}
                  className="text-white-1 hover:bg-black-3 block px-4 py-2"
                >
                  Déconnexion
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div className="px-5">
            <Button
              className={'hidden py-[10px] text-base font-medium md:block'}
              link="/login"
              children="Connexion"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UserProfileDesktop;
