import React from 'react';

const Copyright = ({ author, copyrightText }) => {
  return (
    <p className="mt-12 w-full text-center text-xs">
      &copy; {new Date().getFullYear()} {author}. {copyrightText}.
    </p>
  );
};

export default Copyright;
