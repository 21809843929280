import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_ENDPOINTS } from '../utils/backURL';
import LabeledInput from '../components/input/LabeledInput';
import Button from '../components/buttons/Button';
import SearchableInput from '../components/input/SearchableInput';
import { generateSlug } from '../utils/slugger';
import logoxs from '../assets/logo/logo-xs-wy.svg';
import { IconContext } from 'react-icons';
import { RxCross2 } from 'react-icons/rx';
import Select from '../components/select/Select';
import AuthContext from '../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../components/spinner/Spinner';
import { Helmet } from 'react-helmet';
import AlertMessage from '../components/alertMessage/AlertMessage';
import Compressor from 'compressorjs';
import * as ROUTES from '../utils/routesConfig';

const ProposeGame = () => {
  const [searchTermDeveloper, setSearchTermDeveloper] = useState('');
  const [searchTermPublisher, setSearchTermPublisher] = useState('');
  const [developerIRI, setDeveloperIRI] = useState('');
  const [publisherIRI, setPublisherIRI] = useState('');
  const [newDeveloper, setNewDeveloper] = useState('');
  const [newPublisher, setNewPublisher] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImageName, setUploadedImageName] = useState('');
  const [uploadedImageFile, setUploadedImageFile] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isUserAuthenticated, userData } = useContext(AuthContext);
  const [resetFirstKey, setResetFirstKey] = useState(0);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectCount, setSelectCount] = useState(1);
  const allSelected =
    selectedGenres.length === selectCount && !selectedGenres.includes('');
  const [alertInfo, setAlertInfo] = useState({
    isVisible: false,
    title: '',
    message: '',
    type: 'error',
  });

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    developer: '',
    publisher: '',
    gamePlatforms: [],
    gameTags: [],
    releaseDate: '',
  });

  const MAX_FILE_SIZE = 2097152;

  //! Redirection si l'utilisateur n'est pas authentifié
  useEffect(() => {
    if (!isUserAuthenticated) {
      navigate(location.state?.from || '/');
    }
  }, [isUserAuthenticated, navigate, location.state]);

  //! Gestion de l'alerte
  const resetAlertInfo = () => {
    setAlertInfo({
      isVisible: false,
      title: '',
      message: '',
      type: 'error',
    });
  };

  useEffect(() => {
    if (alertInfo.isVisible) {
      const timer = setTimeout(() => {
        resetAlertInfo();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [alertInfo]);

  //*********************************************! Fetch depuis API *****************************
  const fetchDevelopers = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_DEVELOPERS}?name=${searchTermDeveloper}`,
    );
    return response.data['hydra:member'];
  };

  const fetchPublishers = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_PUBLISHERS}?name=${searchTermPublisher}`,
    );
    return response.data['hydra:member'];
  };

  const fetchPlatforms = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_PLATFORMS}?status=actif`,
    );
    return response.data['hydra:member'];
  };

  const fetchTagsTypes = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_TAG_TYPES}?slug=genre`,
    );
    const firstMember = response.data['hydra:member'][0];
    const genreId = firstMember['@id'];
    return genreId;
  };

  const fetchGenres = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_TAGS}?tag_type.slug=genre&is_active=1`,
    );
    return response.data['hydra:member'];
  };

  //*********************************************! Configuration Query *****************************
  const {
    data: developers = [],
    // isLoading: developersLoading,
    // isError: developersError,
    // refetch: refetchDevelopers,
  } = useQuery(
    ['developers', searchTermDeveloper],
    () => fetchDevelopers(searchTermDeveloper),
    {
      enabled: searchTermDeveloper.length >= 2,
    },
  );

  const {
    data: publishers = [],
    // isLoading: publishersLoading,
    // isError: publishersError,
    // refetch: refetchPublishers,
  } = useQuery(
    ['publishers', searchTermPublisher],
    () => fetchPublishers(searchTermPublisher),
    {
      enabled: searchTermPublisher.length >= 2,
    },
  );

  const {
    data: platforms,
    isLoading: platformsLoading,
    // isError: platformsError,
    // refetch: refetchPlatforms,
  } = useQuery(['platforms'], fetchPlatforms);

  const {
    data: genres,
    isLoading: genresLoading,
    // isError: genresError,
    // refetch: refetchGenres,
  } = useQuery(['genres'], fetchGenres);

  //*********************************************! Fonctions utiles *****************************
  // Mutation pour créer un nouveau jeu
  const mutation = useMutation((newGame) =>
    axios.post(API_ENDPOINTS.POST_NEW_GAME, newGame, {
      withCredentials: true,
    }),
  );

  // Gestion du changement dans les champs du formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Gestion du clic sur les plateformes
  const handlePlatformClick = (platform) => {
    // Utilisation du "setter" setFormData pour mettre à jour l'état formData
    setFormData((prevData) => {
      // Création d'une copie de l'array gamePlatforms de l'état précédent
      const existingPlatforms = [...prevData.gamePlatforms];

      // Recherche de l'indice de la plateforme cliquée dans l'array copié
      const indexToRemove = existingPlatforms.findIndex(
        (id) => id === platform['@id'],
      );

      // Si la plateforme est déjà dans l'array (indexToRemove !== -1),
      // elle est retirée
      if (indexToRemove !== -1) {
        existingPlatforms.splice(indexToRemove, 1);
      } else {
        // Sinon, la plateforme est ajoutée à l'array
        existingPlatforms.push(platform['@id']);
      }

      // Retourne le nouvel état avec l'array gamePlatforms mis à jour
      return {
        ...prevData,
        gamePlatforms: existingPlatforms,
      };
    });
  };

  const handleGenreChange = (value, index) => {
    // Mettre à jour le tableau selectedGenres avec la nouvelle valeur à l'index spécifique
    let updatedSelectedGenres = [...selectedGenres];
    updatedSelectedGenres[index] = value;
    setSelectedGenres(updatedSelectedGenres);

    // Mettre à jour formData.gameTags avec les nouvelles valeurs
    setFormData((prevData) => {
      return { ...prevData, gameTags: updatedSelectedGenres };
    });
  };

  const addSelect = () => {
    setSelectCount((prevCount) => prevCount + 1);
  };

  const handleDelete = (index) => {
    const newSelectedGenres = [...selectedGenres];
    const deletedGenre = newSelectedGenres.splice(index, 1)[0];

    setFormData((prevData) => {
      const newGameTags = prevData.gameTags.filter(
        (tag) => tag !== deletedGenre,
      );
      return { ...prevData, gameTags: newGameTags };
    });

    setSelectedGenres(newSelectedGenres);

    if (index === 0) {
      setResetFirstKey((prevKey) => prevKey + 1); // incrémente le compteur
    } else {
      setSelectCount((prevCount) => prevCount - 1);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setAlertInfo({
        isVisible: true,
        title: 'Erreur de Fichier',
        message: 'Aucun fichier sélectionné.',
        type: 'error',
      });
      return;
    }
    new Compressor(file, {
      quality: 0.8,
      success: (compressedImage) => {
        if (compressedImage.size > MAX_FILE_SIZE) {
          setAlertInfo({
            isVisible: true,
            title: 'Erreur de Taille de Fichier',
            message:
              'La taille du fichier après compression est encore trop grande.',
            type: 'error',
          });
          return;
        }

        setUploadedImageFile(compressedImage);
        setUploadedImage(URL.createObjectURL(compressedImage));
        setUploadedImageName(compressedImage.name);
      },
      error: () => {
        setAlertInfo({
          isVisible: true,
          title: 'Erreur de Compression',
          message: 'Erreur lors de la compression du fichier.',
          type: 'error',
        });
      },
    });
  };

  const handleImageRemove = () => {
    // Réinitialisez les états
    setUploadedImage(null);
    setUploadedImageName('');
    setUploadedImageFile(null);

    // Réinitialisez la valeur du champ de fichier
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();

    // Création de l'objet FormData
    const formDataToSend = new FormData();

    // Ajout du titre
    formDataToSend.append('title', formData.title);

    // Ajout du slug
    formDataToSend.append('slug', generateSlug(formData.title));

    // Ajout de la description
    formDataToSend.append('description', formData.description);

    // Ajout de la date mais avant : Convertir la date au format ISO 8601
    if (formData.releaseDate) {
      const dateObject = new Date(`${formData.releaseDate}`);
      const isoDate = dateObject.toISOString();
      formDataToSend.append('releaseDate', isoDate);
    }

    // Ajout des développeurs
    if (developerIRI) {
      formDataToSend.append('developer', developerIRI);
    } else if (newDeveloper) {
      formDataToSend.append('developer[name]', newDeveloper);
      formDataToSend.append('developer[slug]', generateSlug(newDeveloper));
    }

    // Ajout des éditeurs
    if (publisherIRI) {
      formDataToSend.append('publisher', publisherIRI);
    } else if (newPublisher) {
      formDataToSend.append('publisher[name]', newPublisher);
      formDataToSend.append('publisher[slug]', generateSlug(newPublisher));
    }

    // Ajout du status par défaut qui sera "en attente
    formDataToSend.append('status', 'en attente');

    // Ajout des plateformes
    formData.gamePlatforms.forEach((platformId, index) => {
      formDataToSend.append(`gamePlatforms[${index}][platform]`, platformId);
    });

    // Ajout des genres
    formData.gameTags.forEach((tagId, index) => {
      formDataToSend.append(`gameTags[${index}][tag]`, tagId);
    });

    // Ajout de l'image
    if (uploadedImageFile) {
      formDataToSend.append(
        'imageFile',
        uploadedImageFile,
        uploadedImageFile.name,
      );
    }

    // Ajout de l'utilisateur
    if (userData) {
      formDataToSend.append('user', 'api/users/' + userData.id);
    }

    // Mutation, gestion du succes et echec
    mutation.mutate(formDataToSend, {
      onSuccess: () => {
        navigate(`${ROUTES.PROPOSE_GAME_SUCCESS}`);
      },
      onError: (error) => {
        setAlertInfo({
          isVisible: true,
          title: 'Erreur lors de la soumission',
          message: 'Un problème est survenu lors de la soumission du jeu.',
          type: 'error',
        });
      },
    });
  };

  // Chargement du rendu
  if (platformsLoading || genresLoading) {
    return <Spinner />;
  }

  const displayPlatforms = showAll ? platforms : platforms.slice(0, 5);

  //*********************************************! Rendu *****************************
  return (
    <div className="mx-auto max-w-[1280px]">
      <Helmet>
        <title>Proposer un Jeu | Joystique</title>
        <meta
          name="description"
          content="Contribuez à la communauté Joystique en proposant de nouveaux jeux. Partagez vos découvertes et enrichissez notre bibliothèque de jeux vidéo."
        />
      </Helmet>
      {alertInfo.isVisible && (
        <AlertMessage
          title={alertInfo.title}
          message={alertInfo.message}
          type={alertInfo.type}
          autoCloseTime={5000}
        />
      )}
      <div className="xs:px-4 w-full px-2 py-8">
        {/************* Titre + explication *************/}
        <h1 className="mb-4 text-2xl font-bold">Ajoutez un Nouveau Jeu.</h1>
        <p className="text-black-6 text-sm">
          <span className="font-semibold">
            Vous ne trouvez pas votre jeu préféré dans notre base de données ?
          </span>{' '}
          Pas de souci ! Utilisez ce formulaire pour nous aider à enrichir notre
          collection. Remplissez simplement les champs ci-dessous pour proposer
          un nouveau jeu. Après vérification, il sera ajouté à notre liste.
          Merci de contribuer à notre communauté de joueurs !
        </p>
        <div className="bg-white-2 mb-10 mt-3 rounded-xl border py-6 shadow-lg md:flex">
          {/************* Image jeu + upload {/*************/}
          <div className="xs:px-4 flex min-h-[500px] flex-col items-center px-2 md:w-1/3 md:min-w-[340px]">
            <p className="text-black-5 mb-2 font-medium">Cover du jeu</p>
            {/* Image */}
            <div className="bg-black-7 relative h-[400px] max-w-[300px] overflow-hidden rounded">
              {uploadedImage && (
                <img
                  src={uploadedImage}
                  alt=""
                  className="h-full w-full object-cover"
                />
              )}
              {!uploadedImage && (
                <img
                  src={logoxs}
                  alt=""
                  className="h-full w-full object-contain"
                />
              )}
            </div>
            {/* Input caché pour donné du style */}
            <input
              type="file"
              accept="image/*"
              className="hidden"
              id="fileInput"
              onChange={handleImageUpload}
            />
            <div className="mt-3 flex">
              <label
                htmlFor="fileInput"
                className=" bg-white-5 border-white-6 cursor-pointer truncate rounded-l border px-2 py-1"
              >
                Télécharger une image
              </label>
              {uploadedImageName && (
                <div className="flex max-w-[90px] items-center rounded-r border px-2 py-1">
                  <span className="text-black-7 truncate text-sm">
                    {uploadedImageName}
                  </span>
                  <button onClick={handleImageRemove} className="ml-2">
                    <IconContext.Provider
                      value={{
                        size: '17px',
                        className: 'text-black-7',
                      }}
                    >
                      <RxCross2 />
                    </IconContext.Provider>
                  </button>
                </div>
              )}
            </div>
          </div>
          {/************* Début formulaire *************/}
          <form onSubmit={handleSubmit} className="xs:px-4 px-2 md:w-2/3">
            {/* Titre */}
            <LabeledInput
              label="Titre"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
              additionalInputClass="mb-5"
              placeholder="ex : Grand Theft Auto V"
            />

            {/* Description */}
            <LabeledInput
              label="Description"
              name="description"
              asTextarea={true}
              value={formData.description}
              onChange={handleChange}
              additionalInputClass="mb-5"
            />

            {/* Platform */}
            <div className="border-white-6 mb-3 w-1/2 border-b-2">
              <p className="text-black-5 font-medium">Plateforme(s)</p>
            </div>
            <div className="-mr-4 mb-5 flex flex-wrap items-center">
              {displayPlatforms.map((platform, index) => {
                const isSelected = formData.gamePlatforms.includes(
                  platform['@id'],
                );
                return (
                  <p
                    key={index}
                    className={`mb-3 mr-4 cursor-pointer select-none rounded border px-3 py-1 shadow-sm ${
                      isSelected
                        ? 'bg-accent-9 border-white-6 shadow-lg'
                        : 'bg-white-1 border-white-6 hover:bg-white-3 shadow-md'
                    }`}
                    onClick={() => handlePlatformClick(platform)}
                  >
                    {platform.name}
                  </p>
                );
              })}
              {platforms.length > 5 && (
                <p
                  onClick={() => setShowAll(!showAll)}
                  className="bg-white-4 hover:bg-white-5 mb-3 mr-4 cursor-pointer rounded p-2 text-xs font-bold uppercase"
                >
                  {showAll ? 'Voir moins' : 'Voir plus'}
                </p>
              )}
            </div>

            {/* Genre */}
            {genres && (
              <div className="mb-5">
                <div className="border-white-6 mb-3 w-1/2 border-b-2">
                  <p className="text-black-5 font-medium">Genre(s)</p>
                </div>
                {[...Array(selectCount)].map((_, index) => (
                  <div
                    key={`select-${index === 0 ? resetFirstKey : index}`}
                    className="mb-3 flex items-center"
                  >
                    {' '}
                    {/* utiliser resetFirstKey pour le premier élément */}
                    <Select
                      options={genres}
                      labelField="value"
                      valueField="@id"
                      label="Genre"
                      onChange={(value) => handleGenreChange(value, index)}
                      disabledOptions={selectedGenres}
                    />
                    <div
                      onClick={() => handleDelete(index)}
                      className="bg-white-4 cursor-pointer rounded-r p-3"
                    >
                      <IconContext.Provider
                        value={{
                          size: '17px',
                          className: 'text-black-7',
                        }}
                      >
                        <RxCross2 />
                      </IconContext.Provider>
                    </div>
                  </div>
                ))}
                {allSelected && selectCount < 5 && (
                  <button
                    onClick={addSelect}
                    className="bg-accent-9 rounded px-3 py-2 text-sm font-semibold"
                  >
                    + Ajouter un autre genre
                  </button>
                )}
              </div>
            )}

            {/* Date de sortie */}
            <LabeledInput
              label="Date de sortie"
              name="releaseDate"
              type="date"
              value={formData.releaseDate}
              onChange={handleChange}
              additionalInputClass="mb-5 max-w-[48%]"
            />

            {/* Editeur */}
            <div className="xs:flex xs:space-x-8">
              <SearchableInput
                label="Editeur"
                name="publisher"
                options={publishers}
                value={formData.publisher}
                onChange={handleChange}
                setSearchTerm={setSearchTermPublisher}
                optionKey="name"
                setIRI={setPublisherIRI}
                additionalInputClass="mb-5"
                setNewData={setNewPublisher}
              />
              {/* Developpeur */}
              <SearchableInput
                label="Développeur"
                name="developer"
                options={developers}
                value={formData.developer}
                onChange={handleChange}
                setSearchTerm={setSearchTermDeveloper}
                optionKey="name"
                setIRI={setDeveloperIRI}
                additionalInputClass="mb-5"
                setNewData={setNewDeveloper}
              />
            </div>
            <Button type="submit" className="mt-5">
              Soumettre
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProposeGame;
