import React from 'react';

const ProgressBar = ({ progress }) => (
  <div className={`progress-bar mx-auto w-full max-w-[490px] opacity-0`}>
    <div className="mb-1 flex justify-between font-semibold">
      <p>Résultat</p>
      <p>{progress}%</p>
    </div>
    <div className="h-4 w-full rounded-lg bg-gray-300">
      <div
        className="bg-accent-7 h-4 rounded-lg"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  </div>
);

export default ProgressBar;
