import React from 'react';

const Tab = ({ name, activeTab, onTabChange }) => {
  return (
    <div
      className={`cursor-pointer rounded-full border p-2 capitalize ${
        activeTab === name
          ? 'bg-accent-10 border-accent-9 border font-semibold'
          : 'bg-white-5'
      }`}
      onClick={() => onTabChange(name)}
    >
      {name}
    </div>
  );
};

export default Tab;
