import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Spinner from '../components/spinner/Spinner';

const UserRoute = ({ children }) => {
  const { isUserAuthenticated, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <Spinner />;
  }

  if (!isUserAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default UserRoute;
