import React from 'react';

const Game = ({ src, alt, className }) => (
  <div className={className}>
    <img
      src={src}
      alt={alt}
      className="aspect-[3/4] w-full rounded object-cover"
    />
  </div>
);

export default Game;
