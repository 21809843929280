import React, { useEffect, useRef, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import ReactDOM from 'react-dom';

const ContextMenu = ({ options }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({});
  const buttonRef = useRef();
  const menuRef = useRef();
  const menuWidth = 160;

  // Ouverture / Fermeture menu + calcul de sa position
  const toggleMenu = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();

      setMenuPosition({
        top: rect.bottom + window.scrollY,
        left: rect.right + window.scrollX - menuWidth,
      });
    }
    setShowMenu(!showMenu);
  };

  // Ferme le menu lors du clic à l'extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showMenu &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <>
      <button ref={buttonRef} onClick={toggleMenu}>
        <HiDotsVertical
          className="text-black-4 hover:text-black-7"
          size="20px"
        />
      </button>
      {showMenu &&
        ReactDOM.createPortal(
          <div
            ref={menuRef}
            className="bg-white-1 absolute z-50 rounded-lg border shadow"
            style={{
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              width: `${menuWidth}px`,
              position: 'absolute',
            }}
          >
            {options.map((option, index) => (
              <button
                key={index}
                className="flex w-full items-center truncate rounded-lg px-4 py-2 text-left hover:bg-gray-100"
                onClick={() => {
                  option.action();
                  setShowMenu(false);
                }}
              >
                {option.icon && <span className="mr-3">{option.icon}</span>}
                {option.text}
              </button>
            ))}
          </div>,
          document.getElementById('portal-root'),
        )}
    </>
  );
};

export default ContextMenu;
