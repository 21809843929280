import React from 'react';

const FAQ = () => {
  return (
    <div className="text-black-1 mx-auto my-8 max-w-[1280px] px-4 font-sans">
      <h1 className="mb-4 text-3xl font-bold">Foire Aux Questions (FAQ)</h1>

      <div className="mt-6">
        <h2 className="mb-2 text-2xl font-semibold">
          Comment puis-je ajouter un jeu à la base de données ?
        </h2>
        <p>
          Pour ajouter un jeu, connectez-vous à votre compte, accédez à la
          section "Jeux" puis "Ajouter un jeu" et remplissez le formulaire. Ou
          accéder directement via ce{' '}
          <a href="https://joystique.fr/soumettre-jeu">lien</a> Votre soumission
          sera examinée par un administrateur avant d'être publiée.
        </p>
      </div>

      <div className="mt-6">
        <h2 className="mb-2 text-2xl font-semibold">
          Quels types de jeux puis-je ajouter ?
        </h2>
        <p>
          Vous pouvez soumettre tout type de jeu vidéo, à condition qu'il
          respecte nos critères de publication et ne viole pas les droits
          d'auteur.
        </p>
      </div>

      <div className="mt-6">
        <h2 className="mb-2 text-2xl font-semibold">
          Comment fonctionne le système de vote dans le mini-jeu "Versus" ?
        </h2>
        <p>
          Dans le mini-jeu "Versus", deux jeux sont mis en compétition. Vous
          pouvez voter pour votre préféré. Les résultats des votes sont pris en
          compte pour le classement des jeux sur le site.
        </p>
      </div>

      <div className="mt-6">
        <h2 className="mb-2 text-2xl font-semibold">
          Comment puis-je contacter l'administrateur ?
        </h2>
        <p>
          Vous pouvez utiliser le formulaire de contact disponible sur le site
          pour envoyer un message directement à l'administrateur.
        </p>
      </div>

      <div className="mt-6">
        <h2 className="mb-2 text-2xl font-semibold">
          Quelle est la politique concernant les droits d'auteur ?
        </h2>
        <p>
          Nous respectons les droits d'auteur et demandons à nos utilisateurs de
          faire de même. Si vous n'êtes pas le propriétaire des droits d'un jeu
          ou d'une image, assurez-vous d'avoir l'autorisation nécessaire pour
          les publier sur notre site.
        </p>
      </div>
    </div>
  );
};

export default FAQ;
