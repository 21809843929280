// Slider.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import useResponsiveSlides from '../../hooks/useResponsiveSlides';

const Slider = ({ data, renderSlide, maxSlides = 7.2, maxWidth = 1200 }) => {
  const slidesPerView = useResponsiveSlides(maxSlides, maxWidth);

  return (
    <>
      <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={10}
        freeMode={true}
        freeModeMomentum={false}
        pagination={{
          el: '.swiper-custom-pagination',
          clickable: true,
        }}
        modules={[Pagination]}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>{renderSlide(item)}</SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-custom-pagination mt-2 text-center" />
    </>
  );
};

export default Slider;
