import { useState, useEffect, useCallback } from 'react';

const useResponsiveSlides = (maxSlides, maxWidth) => {
  const [slidesPerView, setSlidesPerView] = useState(maxSlides);

  const updateSlidesPerView = useCallback(() => {
    const width = window.innerWidth;
    const newSlidesPerView = Math.min((width / maxWidth) * maxSlides, maxSlides);
    setSlidesPerView(newSlidesPerView);
  }, [maxSlides, maxWidth]);

  useEffect(() => {
    updateSlidesPerView();
    window.addEventListener('resize', updateSlidesPerView);
    return () => window.removeEventListener('resize', updateSlidesPerView);
  }, [updateSlidesPerView]);

  return slidesPerView;
};

export default useResponsiveSlides;
