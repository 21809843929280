import React, { useEffect, useState } from 'react';
import { RxCrossCircled, RxCheckCircled } from 'react-icons/rx';

/**
 * Composant LabeledInput - affiche un champ de saisie avec un label et des options de personnalisation.
 *
 * Props:
 * - label: Texte du label.
 * - labelDetail: Détail supplémentaire à afficher à côté du label (précision).
 * - type: Type de l'input (par défaut 'text').
 * - value: Valeur de l'input.
 * - onChange: Fonction appelée lors du changement de la valeur.
 * - required: Indique si l'input est requis (par défaut 'false').
 * - placeholder: Texte du placeholder.
 * - asTextarea: Si 'true', l'input sera un textarea (par défaut 'false').
 * - disabled: Si 'true', l'input sera désactivé (par défaut 'false').
 * - showButton: Si 'true', affiche un bouton à côté de l'input (par défaut 'false').
 * - buttonText: Texte du bouton (par défaut 'Modifier').
 * - onButtonClick: Fonction appelée lors du clic sur le bouton.
 * - additionalContainerClass: Classes supplémentaires pour le style du conteneur.
 * - additionalLabelClass: Classes supplémentaires pour le style du label.
 * - additionalInputClass: Classes supplémentaires pour le style de l'input.
 * - additionalButtonClass: Classes supplémentaires pour le style du bouton.
 * - showPasswordCriteria: Si 'true', affiche les critères de mot de passe pour les champs de type password (par défaut 'false').
 */

const LabeledInput = ({
  label,
  labelDetail = '',
  type = 'text',
  value,
  onChange,
  required = false,
  placeholder = '',
  asTextarea = false,
  disabled = false,
  showButton = false,
  buttonText = 'Modifier',
  onButtonClick,
  additionalLabelClass = '',
  additionalInputClass = '',
  additionalContainerClass = '',
  additionalButtonClass = '',
  showPasswordCriteria = false,
  ...props
}) => {
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasSymbol: false,
    minLength: false,
  });
  const [isInputActive, setIsInputActive] = useState(false);
  const defaultLabelClass = 'block text-black-5 font-medium';
  const defaultInputClass = 'w-full p-2 border rounded placeholder:text-xs';
  const defaultContainerClass = '';
  const defaultButtonClass =
    'absolute inset-y-0 right-0 px-4 text-sm font-medium text-black-5 focus:outline-none bg-white-4 border rounded-r';

  const combinedLabelClass = `${defaultLabelClass} ${additionalLabelClass}`;
  const combinedInputClass = `${defaultInputClass} ${additionalInputClass}`;
  const combinedContainerClass = `${defaultContainerClass} ${additionalContainerClass}`;
  const combinedButtonClass = `${defaultButtonClass} ${additionalButtonClass}`;

  // Fonction pour valider le mot de passe
  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-={};'":\\|,.<>?]+/.test(password);
    const minLength = password.length >= 8;

    setPasswordCriteria({ hasUpperCase, hasLowerCase, hasSymbol, minLength });
  };

  // Utilisez useEffect pour mettre à jour la validation à chaque changement de `value`
  useEffect(() => {
    if (type === 'password' && showPasswordCriteria) {
      validatePassword(value);
    }
  }, [value, type, showPasswordCriteria]);

  // Choix de l'icône en fonction de l'état du critère
  const getIcon = (isValid, isActive) => {
    if (!isActive) return <RxCrossCircled color="grey" />;
    return isValid ? (
      <RxCheckCircled color="green" />
    ) : (
      <RxCrossCircled color="red" />
    );
  };

  // Fonction pour gérer le focus
  const handleFocus = () => {
    setIsInputActive(true);
  };

  // Fonction pour gérer le blur
  const handleBlur = () => {
    setIsInputActive(false);
  };

  return (
    <div className={combinedContainerClass}>
      <label className={combinedLabelClass}>
        {label}{' '}
        <span className="text-black-7 text-xs italic">{labelDetail}</span>
      </label>
      <div className="relative">
        {/* Conteneur relatif pour l'input et le bouton */}
        {asTextarea ? (
          <textarea
            value={value}
            onChange={onChange}
            required={required}
            placeholder={placeholder}
            className={combinedInputClass}
            disabled={disabled}
            {...props}
          />
        ) : (
          <>
            <input
              type={type}
              value={value}
              onChange={onChange}
              required={required}
              placeholder={placeholder}
              className={`${combinedInputClass} ${showButton ? 'pr-20' : ''}`}
              disabled={disabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...props}
            />
            {showButton && (
              <button onClick={onButtonClick} className={combinedButtonClass}>
                {buttonText}
              </button>
            )}
            {showPasswordCriteria && type === 'password' && isInputActive && (
              <div className="xs:grid-cols-2 ml-2 mt-2 grid grid-cols-1 gap-2 text-xs transition-opacity duration-500 ease-in-out">
                <div className="flex items-center">
                  {getIcon(passwordCriteria.hasUpperCase, value)}
                  <span className="ml-2">1+ Majuscule</span>
                </div>
                <div className="flex items-center">
                  {getIcon(passwordCriteria.hasLowerCase, value)}
                  <span className="ml-2">1+ Minuscule</span>
                </div>
                <div className="flex items-center ">
                  {getIcon(passwordCriteria.hasSymbol, value)}
                  <span className="ml-2">1+ Symbole</span>
                </div>
                <div className="flex items-center">
                  {getIcon(passwordCriteria.minLength, value)}
                  <span className="ml-2">8+ Caractères</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LabeledInput;
