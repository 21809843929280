import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { API_ENDPOINTS } from '../../../utils/backURL';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Button from '../../../components/buttons/Button';
import LabeledInput from '../../../components/input/LabeledInput';
import { BsSend } from 'react-icons/bs';
import { formatDateHour } from '../../../utils/formattingUtils.js';
import AuthContext from '../../../context/AuthContext.js';
import Spinner from '../../../components/spinner/Spinner.js';

const AdminConversation = () => {
  const { discussionTicket } = useParams();
  const { userData } = useContext(AuthContext);
  const messagesEndRef = useRef(null);
  const [formData, setFormData] = useState({
    sender: '',
    content: '',
  });

  //! Récupère tout les messages de la discussion depuis l'API
  const fetchConversation = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_CONTACT_MESSAGE}?discussion.ticket=${discussionTicket}`,
    );
    return response.data['hydra:member'];
  };

  //! Query
  const {
    data: messages,
    isLoading: messagesLoading,
    refetch: messagesRefetch,
  } = useQuery(['messages'], fetchConversation);

  console.log(messages);

  //! Fonctions utile
  // Message de l'utilisateur pour remplir le formData
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Envoi du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      content: formData.content,
      senderEmail: userData.email,
      sender: `api/users/${userData.id}`,
      who: 'admin',
      discussion: `api/contact_discussions/${discussionTicket}`,
    };
    try {
      await axios.post(API_ENDPOINTS.POST_CONTACT_MESSAGE, payload);
      setFormData({ sender: '', subject: '', content: '' });
    } catch (error) {
    } finally {
      messagesRefetch();
    }
  };

  // Permet de scroller automatiquement vers le bas, pour visualisation derniers messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  //! useEffect
  // Utilisation du scroll quand messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  //! Spinner
  if (messagesLoading || !messages) {
    return <Spinner />;
  }

  //! Rendu
  return (
    <div className="h-screen-100-80 mt-[80px] flex w-full flex-col md:mt-0 md:h-screen">
      <div className="border-b px-5 py-4 drop-shadow-2xl">
        <p className="truncate">
          <span className="text-xs">Objet :</span>{' '}
          <span className="font-semibold">
            {messages[0].discussion.subject}
          </span>
        </p>
      </div>
      <div className="grow overflow-y-auto px-5 pb-3 pt-5">
        {messages.map((message) => (
          <div
            key={message['@id']}
            className={`my-4 max-w-[70%] rounded-lg border border-none p-3 ${
              message.who === 'admin'
                ? 'bg-accent-4 ml-auto'
                : 'bg-white-3 mr-auto'
            }`}
          >
            <div className="text-black-8 mb-3 flex justify-between space-x-4 text-xs">
              <p className="w-1/3 truncate md:w-1/2">
                De: {message.senderEmail}
              </p>
              <p className="xs:w-1/2 w-2/3 text-right">
                {formatDateHour(message.createdAt)}
              </p>
            </div>
            <p className="whitespace-normal break-words">{message.content}</p>
            <div ref={messagesEndRef} />
          </div>
        ))}
      </div>
      <div className="border-t bg-white p-4 drop-shadow-xl">
        <form onSubmit={handleSubmit} className="flex items-start space-x-3">
          <LabeledInput
            type="text"
            name="content"
            value={formData.content}
            onChange={handleChange}
            asTextarea="true"
            required
            additionalContainerClass="flex-grow"
            additionalInputClass="h-[48px] border-black-1 text-sm"
          />
          <Button
            type="submit"
            className="text-black-5 flex items-center justify-center space-x-3 text-center"
          >
            <span className="">Envoyer</span> <BsSend size="15px" />
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AdminConversation;
