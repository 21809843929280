import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_ENDPOINTS } from '../../../../utils/backURL';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Button from '../../../../components/buttons/Button';
import LabeledInput from '../../../../components/input/LabeledInput';
import GameSelection from './GameSelection';
import AlertMessage from '../../../../components/alertMessage/AlertMessage';
import * as ROUTES from '../../../../utils/routesConfig';
import { generateSlug } from '../../../../utils/slugger';

const AdminVersusEdit = () => {
  const { versusSlug } = useParams();
  const navigate = useNavigate();
  const [beforeData, setBeforeData] = useState(null);
  const [searchQuery1, setSearchQuery1] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');
  const [selectedGame1, setSelectedGame1] = useState(null);
  const [selectedGame2, setSelectedGame2] = useState(null);
  const [title, setTitle] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [alert, setAlert] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  //! Construit l'URL pour la requête API en fonction de la recherche
  const buildURL = (page, search, status) => {
    let url = `${API_ENDPOINTS.FETCH_GAMES}?page=${page}`;
    if (search) {
      url += `&slug=${encodeURIComponent(search)}`;
    }
    if (status) {
      url += `&status=${encodeURIComponent(status)}`;
    }
    return url;
  };

  //! Fonction pour appeler l'API et rechercher des jeux
  const fetchSearchResults = async (searchQuery) => {
    const formattedSearchQuery = searchQuery.replace(/\s+/g, '-');
    const url = buildURL(1, formattedSearchQuery, 'actif');
    try {
      const response = await axios.get(url, {
        withCredentials: true,
      });
      return response.data['hydra:member'];
    } catch (error) {
      throw new Error('Erreur lors de la recherche : ' + error.message);
    }
  };

  const fetchVersus = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_VERSUS}/${versusSlug}`,
    );
    return response.data;
  };

  //! Query
  const { data: versus } = useQuery(['versusEdit'], fetchVersus);

  const { data: games1 } = useQuery(
    ['games', searchQuery1],
    () => fetchSearchResults(searchQuery1),
    { enabled: !!searchQuery1 },
  );

  const { data: games2 } = useQuery(
    ['games', searchQuery2],
    () => fetchSearchResults(searchQuery2),
    { enabled: !!searchQuery2 },
  );

  //********************************! UseEffect pour pré-remplir les champs + beforeData
  useEffect(() => {
    if (versus) {
      const initialData = {
        title: versus.title || '',
        startTime: versus.startTime || '',
        endTime: versus.endTime || '',
        game1Slug: versus.versusGames?.[0]?.game?.slug || '',
        game2Slug: versus.versusGames?.[1]?.game?.slug || '',
      };
      setBeforeData(initialData);
      setTitle(versus.title || '');
      setStartTime(versus.startTime || '');
      setEndTime(versus.endTime || '');
      setSelectedGame1(versus.versusGames?.[0]?.game || null);
      setSelectedGame2(versus.versusGames?.[1]?.game || null);
    }
  }, [versus]);

  //********************************! Fonctions utiles

  // Gérer la sélection des jeux
  const handleSelectGame1 = (game) => {
    setSelectedGame1(game);
    setSearchQuery1('');
  };

  const handleSelectGame2 = (game) => {
    setSelectedGame2(game);
    setSearchQuery2('');
  };

  // Gére le format des dates
  const formatDateForInput = (isoDate) => {
    if (!isoDate) return '';
    const date = new Date(isoDate);
    const localTime = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    );
    return localTime.toISOString().split('T')[0];
  };

  const showAlert = (type, title, message) => {
    setAlert({ show: true, type, title, message });
    setTimeout(
      () => setAlert({ show: false, type: '', title: '', message: '' }),
      3000,
    );
  };

  //********************************! Gestion de la mutation
  const updateVersus = async (updatedData) => {
    const response = await axios.put(
      `${API_ENDPOINTS.EDIT_VERSUS}/${versusSlug}`,
      updatedData,
    );
    return response.data;
  };

  // Création de la mutation
  const mutation = useMutation(updateVersus, {
    onSuccess: () => {
      navigate(ROUTES.ADMIN_VERSUS);
    },
    onError: (error) => {
      let message =
        "Une erreur s'est produite lors de la mise à jour des informations de Versus.";
      if (error.response && error.response.data) {
        const errorMsg = error.response.data['hydra:description'] || message;
        const formattedMessage = errorMsg.replace(/(slug:)\s?/, '');
        message = formattedMessage;
      }
      showAlert('error', 'Erreur', message);
    },
  });

  //********************************! Soumission du formulaire
  const handleSubmit = async () => {
    const afterData = {
      title,
      startTime,
      endTime,
      game1Slug: selectedGame1?.slug || '',
      game2Slug: selectedGame2?.slug || '',
    };

    let updatedData = {};
    let hasChanges = false;

    // Vérifier si le titre a été modifié
    if (beforeData.title !== afterData.title) {
      updatedData.title = afterData.title;
      updatedData.slug = generateSlug(afterData.title);
      hasChanges = true;
    }

    // Vérifier si les dates ont été modifiées
    if (beforeData.startTime !== afterData.startTime) {
      updatedData.startTime = afterData.startTime;
      hasChanges = true;
    }
    if (beforeData.endTime !== afterData.endTime) {
      updatedData.endTime = afterData.endTime;
      hasChanges = true;
    }

    // Vérifier si les jeux ont été modifiés
    if (
      beforeData.game1Slug !== afterData.game1Slug ||
      beforeData.game2Slug !== afterData.game2Slug
    ) {
      updatedData.versusGames = [
        {
          '@id': `/api/versus_games/${versus.versusGames[0].slug}`,
          game: `/api/game/${afterData.game1Slug}`,
        },
        {
          '@id': `/api/versus_games/${versus.versusGames[1].slug}`,
          game: `/api/game/${afterData.game2Slug}`,
        },
      ];
      hasChanges = true;
    }

    if (hasChanges) {
      mutation.mutate(updatedData);
    } else {
      showAlert(
        'error',
        'Aucune modification',
        'Aucune modification détectée à mettre à jour.',
      );
    }
  };

  //********************************! Rendu

  return (
    <div className="mt-[90px] px-4 md:mt-8">
      {alert.show && (
        <AlertMessage
          type={alert.type}
          title={alert.title}
          message={alert.message}
          autoCloseTime={3000}
          position="mx-auto"
        />
      )}
      <h1 className="font-bungee mb-5 text-center text-xl">Versus</h1>
      <div className=" bg-white-2 mb-12 rounded-lg border px-4 py-12 shadow-xl">
        <LabeledInput
          label="Titre du versus"
          placeholder="Titre du versus"
          additionalContainerClass="mb-3 md:w-1/2 mx-auto"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          disabled={
            versus
              ? versus.status === 'actif' || versus.status === 'terminé'
              : false
          }
        />
        <div className="s:flex s:space-x-8 mx-auto mb-12 md:w-1/2">
          <LabeledInput
            label="Date de début"
            type="date"
            additionalContainerClass="w-full s:w-1/2"
            value={formatDateForInput(startTime)}
            onChange={(e) => setStartTime(e.target.value)}
            disabled={
              versus
                ? versus.status === 'actif' || versus.status === 'terminé'
                : false
            }
          />
          <LabeledInput
            label="Date de fin"
            type="date"
            additionalContainerClass="w-full s:w-1/2"
            value={formatDateForInput(endTime)}
            onChange={(e) => setEndTime(e.target.value)}
            disabled={versus ? versus.status === 'terminé' : false}
          />
        </div>
        <div className="mb-8 flex">
          {/* Jeu 1 */}
          <GameSelection
            games={games1}
            onSelectGame={handleSelectGame1}
            selectedGame={selectedGame1}
            resetGame={() => setSelectedGame1(null)}
            searchQuery={searchQuery1}
            setSearchQuery={setSearchQuery1}
            allowedChange={versus ? versus.status === "planifié" : false}
          />

          {/* Icone personnalisé du VS */}
          <div className="flex w-2/12 items-center justify-center">
            <div className="font-inter relative font-bold lg:text-[4rem]">
              <span className="relative left-[-33%]">V</span>
              <span className="absolute left-[33%] top-[18%]">S</span>
            </div>
          </div>

          {/* Jeu 1 */}
          <GameSelection
            games={games2}
            onSelectGame={handleSelectGame2}
            selectedGame={selectedGame2}
            resetGame={() => setSelectedGame2(null)}
            searchQuery={searchQuery2}
            setSearchQuery={setSearchQuery2}
            allowedChange={versus ? versus.status === "planifié" : false}
          />
        </div>
        <div className="xs:flex xs:space-x-8 xs:mx-auto xl:w-4/5">
          <Button
            link={ROUTES.ADMIN_VERSUS}
            variant="secondary"
            className="xs:mb-0 xs:w-1/2 mb-3 w-full"
          >
            Retour
          </Button>
          <Button
            type="submit"
            className="xs:w-1/2 w-full"
            onClick={handleSubmit}
          >
            Soumettre
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminVersusEdit;
