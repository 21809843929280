import React from 'react';
import FooterLogo from './FooterLogo';
import FooterLinks from './FooterLinks';
import SocialFollow from './SocialFollow';
import Copyright from './Copyright';
import * as ROUTES from '../../../utils/routesConfig';

const Footer = () => {
  return (
    <footer className="bg-black-1 text-white-1 z-30 w-full px-6 pb-5">
      {/* Logo du site */}
      <FooterLogo />
      <div className="mx-auto my-8 max-w-[1280px] justify-center text-center md:flex">
        {/* Colonne 1 : Support & Aide */}
        <FooterLinks
          title="Support & Aide"
          links={[
            // { url: ROUTES.ABOUT, text: "À Propos" },
            { url: ROUTES.CONTACT, text: 'Nous contacter' },
            { url: ROUTES.FAQ, text: 'FAQ' },
          ]}
        />

        {/* Colonne 2 : Réseaux Sociaux */}
        <SocialFollow
          title="Restez connecté !"
          socialLinks={[
            {
              type: 'instagram',
              url: 'https://www.instagram.com/',
            },
            {
              type: 'twitter',
              url: 'https://twitter.com/Joystique_fr',
            },
          ]}
        />

        {/* Colonne 3 : Informations légales */}
        <FooterLinks
          title="Informations légales"
          links={[
            { url: ROUTES.LEGALINFORMATION, text: 'Mentions légales' },
            { url: ROUTES.TERMS_OF_USE, text: "Conditions d'utilisation" },
            {
              url: ROUTES.PRIVACY_AND_COOKIES,
              text: 'Confidentialité & Cookies',
            },
          ]}
        />
      </div>
      {/* Copyright */}
      <Copyright author="Joystique" copyrightText="Tous droits réservés" />
    </footer>
  );
};

export default Footer;
