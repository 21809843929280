const imageContext = require.context(
  '../assets/images/games',
  false,
  /\.(png|jpg|jpeg|webp)$/,
);

const gameImages = imageContext.keys().map((path) => {
  const name = path.replace(/.*\/(.+)\.\w+$/, '$1');
  return {
    src: imageContext(path),
    alt: `Couverture du jeu ${name.charAt(0).toUpperCase() + name.slice(1).replace(/[-_]/g, ' ')}`,
  };
});

const splitImagesIntoChunks = (images, chunks) => {
  const chunkSize = Math.ceil(images.length / chunks);
  return Array.from({ length: chunks }, (v, i) =>
    images.slice(i * chunkSize, i * chunkSize + chunkSize),
  );
};

const [imagesLine1, imagesLine2, imagesLine3] = splitImagesIntoChunks(
  gameImages,
  3,
);

export { imagesLine1, imagesLine2, imagesLine3 };
