import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import {
  API_ENDPOINTS,
  GAME_IMAGE_URL,
  USER_IMAGE_URL,
} from '../utils/backURL';
import Modal from '../components/modal/Modal';
import Button from '../components/buttons/Button';
import Spinner from '../components/spinner/Spinner';
import Contribution from './UserProfile/Contribution';
import Tab from './UserProfile/Tab';
import AlertMessage from '../components/alertMessage/AlertMessage';
import Compressor from 'compressorjs';
import { FaHeart } from 'react-icons/fa';
import ListManagementModal from '../components/modal/ListManagementModal';
import { FaArrowRight } from 'react-icons/fa';
import LabeledInput from '../components/input/LabeledInput';
import ToggleButton from '../components/buttons/ToggleButton';
import { FaTrashAlt } from 'react-icons/fa';
import * as ROUTES from '../utils/routesConfig';
import { TfiFaceSad } from 'react-icons/tfi';

const UserProfile = () => {
  const { userId } = useParams();
  const { userData, setUserData } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('listes');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [isNewListModalOpen, setIsNewListModalOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    isVisible: false,
    title: '',
    message: '',
    type: 'error',
  });
  const MAX_FILE_SIZE = 2097152;
  // La liste sélectionné
  const [selectedList, setSelectedList] = useState(null);
  // Menu pour Modifier ou Supprimer une liste
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // lors de l'edition
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [visibilityEdit, setVisibilityEdit] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [visibility, setVisibility] = useState(false);

  //! Requete API

  const fetchUser = async (userId) => {
    const response = await axios.get(`${API_ENDPOINTS.FETCH_USER}/${userId}`);
    return response.data;
  };

  const fetchContribution = async () => {
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_GAMES}?page=1&itemsPerPage=12&status=actif&user.id=${userId}`,
    );
    return response.data['hydra:member'];
  };

  const fetchLists = async () => {
    if (user.id == userData.id) {
      const response = await axios.get(
        `${API_ENDPOINTS.FETCH_GAME_LIST}?user.id=${userId}`,
      );
      return response.data['hydra:member'];
    } else {
      const response = await axios.get(
        `${API_ENDPOINTS.FETCH_GAME_LIST}?user.id=${userId}&visibility=public`,
      );
      return response.data['hydra:member'];
    }
  };

  //! Query
  const {
    data: contributions,
    isLoading: contributionsLoading,
    // isError: versusError,
    refetch: contributionsRefetch,
  } = useQuery(['contributions'], fetchContribution);

  const {
    data: user,
    isLoading: userLoading,
    refetch: userRefetch,
  } = useQuery(['user', userId], () => fetchUser(userId), {
    enabled: !!userId,
  });

  const {
    data: gameLists,
    isLoading: gameListsLoading,
    isError: gameListsError,
    refetch: refetchGameLists,
  } = useQuery(['gameLists', userId], fetchLists, {
    enabled: !!user,
  });

  //! Fonctions utiles
  //Permet d'ouvrir le modal de modif de PP
  const handleProfilePictureClick = () => {
    setIsModalOpen(true);
  };

  //Permet de fermer le modal de modif de PP
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Gestion des onglets (tab)
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Reset l'alert message
  const resetAlertInfo = () => {
    setAlertInfo({
      isVisible: false,
      title: '',
      message: '',
      type: 'error',
    });
  };

  // Permet d'ouvrir le modal pour chaque liste
  const handleListClick = (list) => {
    setSelectedList(list);
    setIsListModalOpen(true);
  };

  // Permet de fermer le modal de chaque liste
  const handleCloseModalList = () => {
    setIsListModalOpen(false);
  };

  // Permet d'ouvrir le modal création de list
  const handleOpenModalNewList = () => {
    setIsNewListModalOpen(true);
  };

  // Permet de fermer le modal création de list
  const handleCloseModalNewList = () => {
    setIsNewListModalOpen(false);
    setVisibility(false);
  };

  // Fonction pour permettre la modification du titre
  const handleEditList = () => {
    setIsEditing(!isEditing);
    setNewTitle(selectedList.name);
    setIsMenuOpen(false);
  };

  // Fonction pour soumettre le nouveau titre
  const handleEditSubmit = async () => {
    setIsEditing(false);
    try {
      const response = await axios.put(
        `${API_ENDPOINTS.EDIT_GAME_LIST}/${selectedList.id}`,
        {
          name: newTitle,
          visibility: visibilityEdit ? 'private' : 'public',
        },
      );
      if (response.data) {
        setSelectedList(response.data);
      }
    } catch (error) {
      setAlertInfo({
        isVisible: true,
        title: 'Erreur',
        message: 'Erreur lors de la modification de la liste.',
        type: 'error',
      });
    } finally {
      setIsEditing(false);
      setIsListModalOpen(false);
      refetchGameLists();
    }
  };

  // Fonction pour demander confirmation avant suppression
  const handleDeleteList = (list) => {
    setIsConfirmModalOpen(true);
    setSelectedList(list);
  };

  // Fonction pour confirmer la suppression
  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINTS.DELETE_GAME_LIST}/${selectedList.id}`,
      );
      if (response.status === 200) {
        setAlertInfo({
          isVisible: true,
          title: 'Succès',
          message: 'Liste supprimée avec succès.',
          type: 'success',
        });
      }
    } catch (error) {
      setAlertInfo({
        isVisible: true,
        title: 'Erreur',
        message: 'Erreur lors de la suppression de la liste.',
        type: 'error',
      });
    } finally {
      setIsConfirmModalOpen(false);
      setIsMenuOpen(false);
      handleCloseModalList();
      refetchGameLists();
    }
  };

  const handleCreateList = async () => {
    if (!newListName.trim()) return;
    try {
      await axios.post(API_ENDPOINTS.POST_GAME_LIST, {
        name: newListName,
        user: `api/users/${userData.id}`,
        visibility: visibility ? 'private' : 'public',
      });
      setNewListName('');
      setIsNewListModalOpen(false);
    } catch (error) {
      console.error('Erreur lors de la création de la liste', error);
    } finally {
      refetchGameLists();
    }
  };

  const handleRemoveGameFromList = async (entryId) => {
    try {
      const response = await axios.delete(
        `${API_ENDPOINTS.DELETE_GAME_LIST_ENTRIES}/${entryId}`,
      );

      if (response.status === 204) {
        const updatedEntries = selectedList.userGameListEntries.filter(
          (entry) => entry.id !== entryId,
        );
        setSelectedList({
          ...selectedList,
          userGameListEntries: updatedEntries,
        });
      }
    } catch (error) {
      setAlertInfo({
        isVisible: true,
        title: 'Erreur',
        message: 'Erreur lors de la suppression du jeu de la liste.',
        type: 'error',
      });
    } finally {
      refetchGameLists();
    }
  };

  //! Gestion image profil
  // Suppression image
  const handleFileDelete = async () => {
    try {
      const response = await axios.post(
        `${API_ENDPOINTS.DELETE_USER_IMAGE}/${userData.id}`,
      );
      if (response.status === 200) {
        setUserData((prevData) => ({
          ...prevData,
          profile_picture: response.data.profilePictureName,
        }));
      }
    } catch (error) {
      setAlertInfo({
        isVisible: true,
        title: 'Erreur',
        message: "Erreur lors de la suppression de l'image.",
        type: 'error',
      });
    } finally {
      setIsModalOpen(false);
      userRefetch();
    }
  };

  // Modification image
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setAlertInfo({
        isVisible: true,
        title: 'Erreur de Fichier',
        message: 'Aucun fichier sélectionné.',
        type: 'error',
      });
      return;
    }

    new Compressor(file, {
      quality: 0.6,
      success: async (result) => {
        if (result.size > MAX_FILE_SIZE) {
          setAlertInfo({
            isVisible: true,
            title: 'Erreur de Taille de Fichier',
            message:
              'La taille du fichier après compression est encore trop grande.',
            type: 'error',
          });
          return;
        }

        setSelectedFile(URL.createObjectURL(result));
        await uploadFile(result);
      },
      error(err) {
        setAlertInfo({
          isVisible: true,
          title: 'Erreur de Compression',
          message: 'Erreur lors de la compression du fichier : ' + err.message,
          type: 'error',
        });
      },
    });
  };

  const uploadFile = async (compressedFile) => {
    setIsModalOpen(false);
    const formData = new FormData();
    formData.append('imageFile', compressedFile, compressedFile.name);
    try {
      const response = await axios.post(
        `${API_ENDPOINTS.EDIT_USER_IMAGE}/${userData.id}`,
        formData,
      );
      if (response.status === 200) {
        setUserData((prevData) => ({
          ...prevData,
          profile_picture: response.data.profilePictureName,
        }));
      }
    } catch (error) {
      console.error("Erreur d'upload:", error.response);
      setAlertInfo({
        isVisible: true,
        title: "Erreur d'Upload",
        message: "Une erreur est survenue lors de l'upload du fichier.",
        type: 'error',
      });
    } finally {
      userRefetch();
    }
  };

  const handleListAction = async (listId, isGameInList) => {
    try {
    } catch (error) {
    } finally {
    }
  };

  //! useEffect
  // fermeture de l'alerte
  useEffect(() => {
    if (alertInfo.isVisible) {
      const timer = setTimeout(() => {
        resetAlertInfo();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [alertInfo]);

  // Pour changer de page de profil en actualisant les données
  useEffect(() => {
    userRefetch();
    contributionsRefetch();
  }, [userId]);

  // Gestion du modal
  useEffect(() => {
    const customFileInput = document.getElementById('customFileInput');
    const fileInput = document.getElementById('fileInput');

    if (customFileInput && fileInput) {
      const handleFileInputClick = () => {
        fileInput.click();
      };

      customFileInput.addEventListener('click', handleFileInputClick);

      return () => {
        customFileInput.removeEventListener('click', handleFileInputClick);
      };
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (selectedList) {
      setVisibilityEdit(selectedList.visibility === 'private');
    }
  }, [selectedList]);

  //! Spinner
  if (userLoading || contributionsLoading) {
    return <Spinner />;
  }

  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Rendu
  return (
    <div className="">
      <Modal
        isOpen={isModalOpen}
        title="Modifier la photo de profil"
        onClose={handleCloseModal}
        showCloseIcon={true}
      >
        <div className="">
          <input
            type="file"
            id="fileInput"
            className="hidden"
            onChange={handleFileChange}
          />
          <Button
            variant=""
            id="customFileInput"
            className="text-accent-8 mx-auto w-full cursor-pointer border-t pt-3 text-lg font-semibold"
          >
            Importer une photo
          </Button>
          <Button
            variant=""
            className="mx-auto w-full cursor-pointer border-t pt-3 text-lg font-semibold text-red-500"
            onClick={handleFileDelete}
          >
            Supprimer la photo actuelle
          </Button>
        </div>
      </Modal>
      {user && user.cover && (
        <figure className="relative h-36 md:h-72">
          <img
            src={`${USER_IMAGE_URL}${user.cover}`}
            alt={`Couverture de ${user.username}`}
            className="h-full w-full object-cover"
          />
        </figure>
      )}
      <div className="bg-white-1 px-4">
        <div
          className={`mx-auto max-w-[1280px] border-b ${
            user && user.cover ? 'pb-4' : 'py-4'
          } border-white-6 bg-white-1`}
        >
          <div className="s:flex-row mb-3 flex flex-col items-center">
            <figure className="relative shrink-0">
              <img
                src={`${USER_IMAGE_URL}${user.profilePictureName}`}
                alt={`Profil de ${user.username}`}
                className="relative z-10 m-4 size-24 rounded-md object-cover md:size-36"
                onClick={
                  Number(userId) === userData.id
                    ? handleProfilePictureClick
                    : undefined
                }
                style={{
                  cursor:
                    Number(userId) === userData.id ? 'pointer' : 'default',
                }}
              />
            </figure>
            <div className="ml-5 flex grow flex-col justify-center truncate">
              <p className="truncate whitespace-nowrap text-2xl font-semibold">
                {user.username}
              </p>
            </div>
          </div>
          <div className="max-h-[80px] px-2"></div>
        </div>
      </div>
      <div className="w-full max-w-[1280px] px-4 lg:mx-auto">
        <div className="s:grid-cols-3 s:mx-0 mx-auto grid max-w-[400px] grid-cols-1 gap-3 py-5 text-center text-sm">
          <Tab
            name="listes"
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
          <Tab
            name="contributions"
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
          <Tab
            name="avis"
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
        </div>

        {activeTab === 'listes' &&
          (gameLists && gameLists.length > 0 ? (
            <>
              <div className="mb-3 grid grid-cols-1 gap-x-4 md:grid-cols-2 lg:grid-cols-3">
                {gameLists.map((list) => (
                  <div
                    key={list.id}
                    className="bg-white-1 hover:bg-white-4 mb-4 flex cursor-pointer items-center justify-between rounded-xl border p-5 shadow-sm"
                    onClick={() => handleListClick(list)}
                  >
                    <div className="truncate">
                      <div className="flex items-center">
                        {list.name === 'Favoris' && (
                          <FaHeart className="mr-2 text-red-400" />
                        )}
                        <h3 className="truncate text-lg font-bold">
                          {list.name}
                        </h3>
                      </div>
                      <p className="text-black-7 text-sm">
                        Nombre de jeux : {list.userGameListEntries.length}
                      </p>
                    </div>
                    <FaArrowRight className="ml-2 min-w-[16px]" />
                  </div>
                ))}
              </div>
              {Number(userId) === userData.id && (
                <div className="mb-8 mt-2">
                  <Button
                    className="w-full font-semibold md:w-1/3 md:max-w-[395px]"
                    onClick={handleOpenModalNewList}
                  >
                    + Créer une liste
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="flex min-h-[300px] flex-col items-center justify-center">
              <TfiFaceSad size="50px" className="text-black-7" />{' '}
              <p>Pas de liste à afficher.</p>
            </div>
          ))}

        {activeTab === 'contributions' &&
          (contributions && contributions.length > 0 ? (
            <Contribution
              contributions={contributions}
              username={user.username}
            />
          ) : (
            <div className="flex min-h-[300px] flex-col items-center justify-center">
              <TfiFaceSad size="50px" className="text-black-7" />{' '}
              <p>Pas de contribution à afficher.</p>
            </div>
          ))}

        {activeTab === 'avis' &&
          (contributions && contributions.length < 0 ? (
            <div>Cas ou l'utilisateur a des avis à afficher.</div>
          ) : (
            <div className="flex min-h-[300px] flex-col items-center justify-center">
              <TfiFaceSad size="50px" className="text-black-7" />{' '}
              <p>Pas d'avis à afficher.</p>
            </div>
          ))}
      </div>
      {alertInfo.isVisible && (
        <AlertMessage
          title={alertInfo.title}
          message={alertInfo.message}
          type={alertInfo.type}
          autoCloseTime={5000}
        />
      )}
      <ListManagementModal
        title={
          isEditing
            ? 'Modifier la liste'
            : selectedList
              ? selectedList.name
              : ''
        }
        isOpen={isListModalOpen}
        onClose={handleCloseModalList}
        options={!isEditing && selectedList?.name !== 'Favoris'}
        Arrow={isEditing}
        onBack={handleEditList}
        onRename={handleEditList}
        onDelete={() => handleDeleteList(selectedList)}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      >
        {!isEditing ? (
          <div>
            {selectedList &&
              selectedList.userGameListEntries.map((entry) => (
                <div
                  key={entry.id}
                  className="my-3 flex items-center justify-between"
                >
                  {entry.game && (
                    <Link
                      to={`${ROUTES.GAME_DETAILS.replace(
                        ':gameSlug',
                        entry.game.slug,
                      )}`}
                      className="hover:text-black-7"
                    >
                      <div className="flex items-center truncate">
                        <img
                          src={`${GAME_IMAGE_URL}${entry.game.imageName}`}
                          alt={entry.game.title}
                          className="aspect-3/4 mr-4 h-16 rounded-lg object-cover"
                        />
                        <div className="truncate">
                          <h4 className="truncate font-semibold">
                            {entry.game.title}
                          </h4>
                          <p className="text-black-7 truncate text-xs">
                            Ajouté le{' '}
                            {new Date(entry.addedAt).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                    </Link>
                  )}
                  <Button
                    onClick={() => handleRemoveGameFromList(entry.id)}
                    className="text-black-7 ml-4 opacity-50 hover:opacity-100"
                    variant=""
                  >
                    <FaTrashAlt />
                  </Button>
                </div>
              ))}
          </div>
        ) : (
          <div className="">
            <LabeledInput
              title="Nouveau titre"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              additionalContainerClass="mt-8 mb-3"
            />
            <ToggleButton
              isChecked={visibilityEdit}
              onChange={(newCheckedValue) => {
                setVisibilityEdit(newCheckedValue);
              }}
              label={visibilityEdit ? 'Privée' : 'Public'}
              additionalContainerClass="my-5"
              additionalButtonClass="ml-3"
            />
            <Button className="w-full" onClick={handleEditSubmit}>
              Valider
            </Button>
          </div>
        )}
      </ListManagementModal>
      <ListManagementModal
        title="Créer une nouvelle liste"
        isOpen={isNewListModalOpen}
        onClose={handleCloseModalNewList}
      >
        <LabeledInput
          type="text"
          placeholder="Nom de la liste"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
        />
        <ToggleButton
          isChecked={visibility}
          onChange={setVisibility}
          label={visibility ? 'Privée' : 'Public'}
          additionalContainerClass="my-5"
          additionalButtonClass="ml-3"
        />
        <Button onClick={handleCreateList} className="mt-3 w-full">
          Valider
        </Button>
      </ListManagementModal>
      <Modal
        isOpen={isConfirmModalOpen}
        title="Supprimer la liste"
        text={`Êtes-vous sûr de vouloir supprimer la liste "${selectedList?.name}" ?`}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={confirmDelete}
        showCloseIcon={true}
        showCancelButton={true}
      />
    </div>
  );
};

export default UserProfile;
