import React from 'react';
import { Link } from 'react-router-dom';

const SectionHeader = ({ title, to, linkName, additionalClass }) => {
  const baseStyle = 'flex items-center justify-between mb-5';
  const combinedStyles = `${baseStyle} ${additionalClass}`;
  return (
    <header className={combinedStyles}>
      <div className="xs:w-auto flex w-full items-center">
        <div className="bg-accent-10 mr-2 h-7 w-1 rounded-full"></div>
        <h2 className="truncate font-bold uppercase first-letter:text-lg">
          {title}
        </h2>
      </div>
      <Link
        to={to}
        className="text-black-8 hover:text-black-5 xs:block hidden text-xs uppercase underline underline-offset-4"
      >
        {linkName}
      </Link>
    </header>
  );
};

export default SectionHeader;
