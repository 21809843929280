import React, { useCallback, useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../../../utils/backURL';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Spinner from '../../../../components/spinner/Spinner';
import Button from '../../../../components/buttons/Button';
import * as ROUTES from '../../../../utils/routesConfig';
import { IconContext } from 'react-icons';
import { MdAdd } from 'react-icons/md';
import Pagination from '../../../../components/pagination/Pagination';

const AdminVersus = () => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFetchingVersus, setIsFetchingVersus] = useState(false);
  const [title, setTitle] = useState('Tous les versus');
  const ITEMS_PER_PAGE = 30;

  //! Construit l'URL pour la requête API en fonction des paramètres de pagination et de filtrage
  const buildURL = (page, search, status) => {
    let url = `${API_ENDPOINTS.FETCH_VERSUSES}?page=${page}`;
    if (search) {
      url += `&title=${encodeURIComponent(search)}`;
    }
    if (status) {
      url += `&status=${encodeURIComponent(status)}`;
    }
    return url;
  };

  //! Récupère les versus depuis l'API
  const fetchVersuses = useCallback(async () => {
    setIsFetchingVersus(true);
    const url = buildURL(currentPage, searchQuery, selectedStatus);
    try {
      const response = await axios.get(url);
      setTotalPages(
        Math.ceil(response.data['hydra:totalItems'] / ITEMS_PER_PAGE),
      );
      return response.data['hydra:member'];
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des versus : ',
        error.message,
      );
    } finally {
      setIsFetchingVersus(false);
    }
  }, [currentPage, searchQuery, selectedStatus]);

  //! Utilise React Query pour charger les données
  const {
    data: versus,
    isLoading: versusLoading,
    refetch,
  } = useQuery(
    ['versusesAdmin', currentPage, selectedStatus, searchQuery],
    fetchVersuses,
  );

  console.log(versus);

  //! Refetch les jeux lorsque les paramètres de recherche ou de pagination changent
  useEffect(() => {
    let newTitle = 'Versus';

    if (selectedStatus) {
      newTitle += ` - ${
        selectedStatus.charAt(0).toUpperCase() + selectedStatus.slice(1)
      }`;
    }

    if (searchQuery) {
      newTitle += ` - ${
        searchQuery.charAt(0).toUpperCase() + searchQuery.slice(1)
      }`;
    }

    setTitle(newTitle);
    refetch();
  }, [currentPage, searchQuery, selectedStatus, refetch]);

  //! Gère le changement de page dans la pagination
  const handlePageChange = (newPage) => setCurrentPage(newPage);

  //! Esthétique : gère la couleur de fond des cellules
  const getBackgroundClass = (versus, index) => {
    if (!versus) {
      return;
    }
    if (
      (versus.status === 'actif' || versus.status === 'terminé') &&
      versus.gameVotesDetails[index].isWinner
    ) {
      return 'bg-green-100';
    } else if (
      (versus.status === 'actif' || versus.status === 'terminé') &&
      !versus.gameVotesDetails[index].isWinner
    ) {
      return 'bg-red-100';
    } else {
      return '';
    }
  };

  return (
    <div className="mx-3 mt-[90px] md:mx-8 md:mt-8">
      {/*//!-------- En-tête page ---------------*/}
      <div className="bg-white-2 mb-8 rounded-lg px-4 py-2 shadow-xl">
        <h1 className="font-bungee text-center text-xl">{title}</h1>
        <div className="my-4">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Rechercher par titre..."
            className="w-full rounded border px-3 py-2"
          />
        </div>
        <div className="my-4 flex flex-wrap items-center">
          <label
            htmlFor="status-select"
            className="mr-3 block text-sm font-semibold uppercase"
          >
            Statut :
          </label>
          <select
            id="status-select"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="rounded border px-3 py-2"
          >
            <option value="">Tous les statuts</option>
            <option value="actif">Actif</option>
            <option value="planifié">Planifié</option>
            <option value="terminé">Terminé</option>
          </select>
        </div>
      </div>
      <Button
        link={ROUTES.ADMIN_VERSUS_ADD}
        variant="secondary"
        className="mb-5 ml-3 flex max-w-[200px] items-center md:ml-0"
      >
        <IconContext.Provider
          value={{
            size: '20px',
            className: 'mr-4',
          }}
        >
          <MdAdd />
        </IconContext.Provider>
        <span className="text-sm font-bold">Ajouter un versus</span>
      </Button>
      <div className="overflow-x-auto rounded-lg shadow-xl">
        <table className="min-w-full table-auto text-xs">
          {/*//!-------- En-têtes du tableau ---------------*/}
          <thead>
            <tr className="bg-black-4 text-white-1 ">
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Actions
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Titre
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Status
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Date de début
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Date de fin
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Jeu 1
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Jeu 2
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Vote jeu 1
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Vote jeu 2
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Total vote
              </th>
            </tr>
          </thead>
          {/*//!-------- Spinner ---------------*/}
          <tbody>
            {isFetchingVersus || versusLoading ? (
              <tr className="table-row">
                <td colSpan="9" className="table-cell text-center align-middle">
                  <Spinner />
                </td>
              </tr>
            ) : (
              versus.map((versus) => {
                return (
                  <tr
                    key={versus.id}
                    className="hover:bg-white-4 my-3 rounded text-center"
                  >
                    {/*//!-------- Actions ---------------*/}
                    <td className="flex gap-1 whitespace-nowrap border px-2">
                      <Button
                        className="mx-auto py-0 text-xs"
                        link={`${ROUTES.ADMIN_VERSUS_EDIT.replace(
                          ':versusSlug',
                          versus.slug,
                        )}`}
                      >
                        Modifier
                      </Button>
                      <Button
                        className="mx-auto py-0 text-xs"
                        variant="five"
                        link={`${ROUTES.VERSUS.replace(
                          ':versusSlug',
                          versus.slug,
                        )}`}
                      >
                        Voir
                      </Button>
                    </td>
                    {/*//!-------- Titre ---------------*/}
                    <td className="whitespace-nowrap border px-4">
                      {versus.title}
                    </td>
                    {/*//!-------- Status ---------------*/}
                    <td
                      className={`whitespace-nowrap border px-4 font-semibold capitalize ${
                        versus.status === 'planifié'
                          ? 'text-orange-400'
                          : versus.status === 'terminé'
                            ? 'text-red-500'
                            : versus.status === 'actif'
                              ? 'text-green-700'
                              : ''
                      }`}
                    >
                      {versus.status}
                    </td>
                    {/*//!-------- Date de début ---------------*/}
                    <td className="whitespace-nowrap border px-4">
                      {new Date(versus.startTime).toLocaleDateString()}
                    </td>
                    {/*//!-------- Date de fin ---------------*/}
                    <td className="whitespace-nowrap border px-4">
                      {new Date(versus.endTime).toLocaleDateString()}
                    </td>
                    {/*//!-------- Jeu 1 ---------------*/}
                    <td
                      className={`whitespace-nowrap border px-4 ${getBackgroundClass(versus, 0)}`}
                    >
                      {versus.gameVotesDetails &&
                      versus.gameVotesDetails.length === 2
                        ? versus.gameVotesDetails[0].gameTitle
                        : 'N/A'}
                    </td>
                    {/*//!-------- Jeu 2 ---------------*/}
                    <td
                      className={`whitespace-nowrap border px-4 ${getBackgroundClass(versus, 1)}`}
                    >
                      {versus.gameVotesDetails &&
                      versus.gameVotesDetails.length === 2
                        ? versus.gameVotesDetails[1].gameTitle
                        : 'N/A'}
                    </td>
                    {/*//!-------- Vote jeu 1 ---------------*/}
                    <td
                      className={`whitespace-nowrap border px-4 ${getBackgroundClass(versus, 0)}`}
                    >
                      {versus.gameVotesDetails &&
                      versus.gameVotesDetails.length === 2
                        ? versus.gameVotesDetails[0].voteCount
                        : 'N/A'}
                    </td>
                    {/*//!-------- Vote jeu 2 ---------------*/}
                    <td
                      className={`whitespace-nowrap border px-4 ${getBackgroundClass(versus, 1)}`}
                    >
                      {versus.gameVotesDetails &&
                      versus.gameVotesDetails.length === 2
                        ? versus.gameVotesDetails[1].voteCount
                        : 'N/A'}
                    </td>
                    {/*//!-------- Total votes ---------------*/}
                    <td className="whitespace-nowrap border px-4">
                      {versus.gameVotesDetails &&
                      versus.gameVotesDetails.length === 2
                        ? versus.gameVotesDetails[0].voteCount +
                          versus.gameVotesDetails[1].voteCount
                        : 'N/A'}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default AdminVersus;
