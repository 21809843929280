const ToggleButton = ({
  id,
  isChecked,
  onChange,
  label,
  labelPosition = 'left',
  additionalContainerClass,
  additionalButtonClass,
  additionalLabelClass
}) => {
  const baseContainerStyle = 'flex items-center';
  const combinedContainerStyles = `${baseContainerStyle} ${additionalContainerClass}`;
  const baseButtonStyle = 'relative';
  const combinedButtonStyles = `${baseButtonStyle} ${additionalButtonClass}`;
  const baseLabelStyle = 'text-black-6 text-xs uppercase font-semibold';
  const combinedLabelStyles = `${baseLabelStyle} ${additionalLabelClass}`;

  const toggle = () => {
    onChange(!isChecked);
  };

  const renderLabel = () => (
    <div className={`${combinedLabelStyles}`}>{label}</div>
  );

  return (
    <div
      className={combinedContainerStyles}
      style={{
        flexDirection:
          labelPosition === 'top' || labelPosition === 'bottom'
            ? 'column'
            : 'row',
      }}
    >
      {/* Affiche le label à gauche ou en haut */}
      {(labelPosition === 'left' || labelPosition === 'top') && renderLabel()}

      {/* Le bouton bascule */}
      <label htmlFor={id} className={`${combinedButtonStyles}`}>
        <input
          id={id}
          type="checkbox"
          className="sr-only"
          checked={isChecked}
          onChange={toggle}
        />
        <div className="border-white-6 bg-white-3 block h-8 w-14 cursor-pointer rounded-full border"></div>
        <div
          className={`dot duration-400 absolute left-1 top-1 size-6 cursor-pointer rounded-full transition-transform ${
            isChecked
              ? 'bg-accent-10 translate-x-6 shadow-inner'
              : 'bg-black-3 shadow-inner'
          }`}
        ></div>
      </label>

      {/* Affiche le label à droite ou en bas */}
      {(labelPosition === 'right' || labelPosition === 'bottom') &&
        renderLabel()}
    </div>
  );
};

export default ToggleButton;
