export const HOME = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const GAMES = "/jeux";
export const GAME_DETAILS = "/jeux/:gameSlug";
export const PROPOSE_GAME = "/soumettre-jeu";
export const PROPOSE_GAME_SUCCESS = "/soumettre-jeu/succes";
export const CONTACT = "/nous-contacter";
export const CONTACT_CONVERSATION = "/discussion/:discussionTicket";
export const COMMUNITY = "/communaute";
export const VERSUS = "/communaute/:versusSlug";
export const LEGALINFORMATION = "/mentions-legales";
export const TERMS_OF_USE = "/cgu";
export const PRIVACY_AND_COOKIES = "/politique-de-confidentialité-et-cookies";
export const FAQ = "/foire-aux-questions";
export const ABOUT = "/a-propos";
// User
export const VERIFY_EMAIL = "/verify-email";
export const RESEND_EMAIL_VERIF = "/resend-verification";
export const RESET_PASSWORD = "/reinitialisation-du-mot-de-passe";
export const SEND_EMAIL_FORGOT_PASSWORD = "/mot-de-passe-oublie";
export const USER_PROFILE = "/profil/:userId";
export const USER_ACCOUNT = "/mon-compte";
// Admin
export const DASHBOARD = "/admin-dashboard";
export const ADMIN_GAMES = "/admin-dashboard/games";
export const ADMIN_GAME = "/admin-dashboard/games/:gameSlug";
export const ADMIN_PLATFORMS = "/admin-dashboard/platforms";
export const ADMIN_DEVELOPPERS = "/admin-dashboard/developers";
export const ADMIN_PUBLISHERS = "/admin-dashboard/publishers";
export const ADMIN_TAGS = "/admin-dashboard/tags";
export const ADMIN_USERS = "/admin-dashboard/users";
export const ADMIN_VERSUS = "/admin-dashboard/community/versus";
export const ADMIN_VERSUS_ADD = "/admin-dashboard/community/versus/add";
export const ADMIN_VERSUS_EDIT =
  "/admin-dashboard/community/versus/edit/:versusSlug";
export const ADMIN_MESSAGES = "/admin-dashboard/messages";
export const ADMIN_MESSAGES_CONVERSATION =
  "/admin-dashboard/messages/:discussionTicket";
  
export const TODO = "/";
