import React, { useCallback, useEffect, useState } from 'react';
import { API_ENDPOINTS, USER_IMAGE_URL } from '../../../utils/backURL';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Button from '../../../components/buttons/Button';
import * as ROUTES from '../../../utils/routesConfig';
import Spinner from '../../../components/spinner/Spinner';
import Pagination from '../../../components/pagination/Pagination';
import { formatDateHour } from '../../../utils/formattingUtils';

const AdminUsers = () => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [title, setTitle] = useState('');
  const ITEMS_PER_PAGE = 30;

  //! Construit l'URL pour la requête API en fonction des paramètres de pagination et de filtrage
  const buildURL = (page, search, status) => {
    let url = `${API_ENDPOINTS.FETCH_USERS_FOR_ADMINS}?page=${page}&itemsPerPage=${ITEMS_PER_PAGE}`;
    if (search) {
      url += `&username=${encodeURIComponent(search)}`;
    }
    if (status) {
      url += `&isVerified=${encodeURIComponent(status)}`;
    }
    return url;
  };

  //! Récupère les utilisateurs depuis l'API
  const fetchUsers = useCallback(async () => {
    setIsFetchingUsers(true);
    const url = buildURL(currentPage, searchQuery, selectedStatus);
    try {
      const response = await axios.get(url);
      setTotalPages(
        Math.ceil(response.data['hydra:totalItems'] / ITEMS_PER_PAGE),
      );
      return response.data['hydra:member'];
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des utilisateurs : ',
        error.message,
      );
    } finally {
      setIsFetchingUsers(false);
    }
  }, [currentPage, searchQuery, selectedStatus]);

  //! Query
  const {
    data: users,
    isLoading: usersLoading,
    isError: usersError,
    refetch,
  } = useQuery(['users', currentPage, selectedStatus, searchQuery], fetchUsers);

  //! Refetch les utilisateurs lorsque les paramètres de recherche ou de pagination changent
  useEffect(() => {
    let newTitle = 'Utilisateurs';

    if (selectedStatus) {
      newTitle += ` - ${selectedStatus === '1' ? 'Vérifiés' : 'Non-vérifiés'}`;
    }

    if (searchQuery) {
      newTitle += ` - ${
        searchQuery.charAt(0).toUpperCase() + searchQuery.slice(1)
      }`;
    }

    setTitle(newTitle);
    refetch();
  }, [currentPage, searchQuery, selectedStatus, refetch]);

  //! Gère le changement de page dans la pagination
  const handlePageChange = (newPage) => setCurrentPage(newPage);

  if (usersError) return <p>Erreur de chargement des utilisateurs.</p>;

  return (
    <div className="mx-3 mt-[90px] md:mx-8 md:mt-8">
      <div className="bg-white-2 mb-8 rounded-lg px-4 py-2 shadow-xl">
        <h1 className="font-bungee text-center text-xl">{title}</h1>
        <div className="my-4">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Rechercher par pseudo..."
            className="w-full rounded border px-3 py-2"
          />
        </div>
        <div className="my-4 flex flex-wrap items-center">
          <label
            htmlFor="status-select"
            className="mr-3 block text-sm font-semibold uppercase"
          >
            Statut :
          </label>
          <select
            id="status-select"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="rounded border px-3 py-2"
          >
            <option value="">Tous les statuts</option>
            <option value="1">Vérifié</option>
            <option value="0">Non-vérifié</option>
          </select>
        </div>
      </div>
      {/*//!-------- Tableau ---------------*/}
      <div className="overflow-x-auto rounded-lg shadow-xl">
        <table className="min-w-full table-auto text-xs">
          {/*//!-------- En-têtes ---------------*/}
          <thead>
            <tr className="bg-black-4 text-white-1 ">
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Actions
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]"></th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Pseudo
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Email
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Points
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Inscription
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Compte vérifié
              </th>
            </tr>
          </thead>
          {/*//!-------- Corp du tableau ---------------*/}
          <tbody className="bg-white-2 text-center">
            {isFetchingUsers || usersLoading ? (
              <tr className="table-row">
                <td colSpan="9" className="table-cell text-center align-middle">
                  <Spinner />
                </td>
              </tr>
            ) : (
              users.map((user) => (
                <tr key={user.id} className="hover:bg-white-4 my-3 rounded">
                  {/*//!-------- Actions ---------------*/}
                  <td className="flex gap-1 whitespace-nowrap border px-2">
                    <Button
                      className="mx-auto py-0 text-xs"
                      variant="five"
                      link={`${ROUTES.USER_PROFILE.replace(
                        ':userId',
                        user.id,
                      )}`}
                    >
                      Voir
                    </Button>
                  </td>

                  {/*//!-------- Photo profil ---------------*/}
                  <td className="border">
                    <img
                      src={`${USER_IMAGE_URL}${user.profilePictureName}`}
                      alt={user.username}
                      className="aspect-3/4 mx-auto max-h-[40px] rounded object-cover"
                    />
                  </td>
                  {/*//!-------- Pseudo ---------------*/}
                  <td className="whitespace-nowrap border px-4 font-semibold">
                    {user.username}
                  </td>
                  {/*//!-------- Email ---------------*/}
                  <td className={`whitespace-nowrap border px-4 font-semibold`}>
                    {user.email}
                  </td>
                  {/*//!-------- Nombre points ---------------*/}
                  <td className={`whitespace-nowrap border px-4 font-semibold`}>
                    {user.points}
                  </td>
                  {/*//!-------- Date d'inscription ---------------*/}
                  <td className={`whitespace-nowrap border px-4 font-semibold`}>
                    {formatDateHour(user.created_at)}
                  </td>
                  {/*//!-------- Compte verifié ? ---------------*/}
                  <td
                    className={`whitespace-nowrap border px-4 font-semibold ${user.isVerified === true ? 'text-green-700' : 'text-red-500'}`}
                  >
                    {user.isVerified === true ? 'Oui' : 'Non'}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default AdminUsers;
