import axios from "axios";
import { API_ENDPOINTS } from "./backURL";

//* (utilisé dans isAuthenticated) Vérifie si la réponse contient des informations sur un utilisateur authentifié.
// Vrai si l'utilisateur est authentifié, sinon faux.
const isUserAuthenticated = (response) => {
  return !!(response.data && response.data.id);
};

//! Tente de rafraîchir le token JWT et vérifie ensuite l'authentification.
// Renvoie les données de l'utilisateur s'il est authentifié, sinon null.
const tryRefreshToken = async () => {
  try {
    await axios.post(
      API_ENDPOINTS.TOKEN_REFRESH,
      {},
      { withCredentials: true }
    );
    const newResponse = await axios.get(API_ENDPOINTS.FETCH_ME, {
      withCredentials: true,
    });
    if (isUserAuthenticated(newResponse)) {
      return newResponse.data;
    }
    return null;
  } catch {
    return null;
  }
};

//! Tente de rafraîchir le token JWT si une requete est refusé
export const refreshTokenIfNeeded = async () => {
  try {
    // Tentative de rafraîchissement du token
    await axios.post(
      API_ENDPOINTS.TOKEN_REFRESH,
      {},
      { withCredentials: true }
    );
    return true;
  } catch (error) {
    console.error("Erreur lors du rafraîchissement du token:", error);
    return false;
  }
};

//! Vérifie si l'utilisateur est actuellement authentifié.
export const checkAuthentication = async () => {
  try {
    let response = await axios.get(API_ENDPOINTS.FETCH_ME, {
      withCredentials: true,
    });
    if (!isUserAuthenticated(response)) {
      throw new Error("Token not valid");
    }
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      const refreshTokenResponse = await tryRefreshToken();
      return refreshTokenResponse;
    }
  }
  return null;
};

//**! Déconnecte l'utilisateur en invalidant les tokens
export const logout = async () => {
  try {
    await axios.post(
      API_ENDPOINTS.TOKEN_INVALIDATE,
      {},
      { withCredentials: true }
    );
    await axios.post(
      API_ENDPOINTS.JWT_INVALIDATE,
      {},
      { withCredentials: true }
    );
  } catch (error) {}
};
