import { useState, useEffect, useRef } from 'react';

export const useAnimation = (sectionRef) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [resetAnimation, setResetAnimation] = useState(false);
  const resizeTimeoutRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const section = sectionRef.current;
        const sectionPosition = section.getBoundingClientRect().top;
        const screenPosition = window.innerHeight / 1.3;
        if (sectionPosition < screenPosition) {
          setIsAnimated(true);
        } else {
          setIsAnimated(false);
        }
      }
    };

    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      setResetAnimation(true);
      resizeTimeoutRef.current = setTimeout(() => {
        setResetAnimation(false);
      }, 2000);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
    };
  }, [sectionRef]);

  return { isAnimated, resetAnimation };
};
