import React from 'react';
import Button from '../../components/buttons/Button';

const VotingSection = ({ versus, handleSubmit }) => {
  return (
    <div className="flex h-[150px] w-full flex-col items-center justify-center p-5">
      <p className="text-center text-lg">
        Quel est ton vainqueur du
        <span className="font-semibold"> {versus.title} </span>?
      </p>
      <div className="mx-auto mt-5 flex w-full max-w-[800px] space-x-3">
        <Button
          className="w-full truncate text-xs font-semibold uppercase"
          onClick={() => handleSubmit(0)}
        >
          {versus.gameVotesDetails[0].gameTitle}
        </Button>
        <Button
          className="w-full truncate text-xs font-semibold uppercase"
          onClick={() => handleSubmit(1)}
        >
          {versus.gameVotesDetails[1].gameTitle}
        </Button>
      </div>
    </div>
  );
};

export default VotingSection;
