import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../utils/backURL';
import { useQuery } from '@tanstack/react-query';
import Spinner from '../components/spinner/Spinner';
import { Helmet } from 'react-helmet';
import { GAME_IMAGE_URL } from '../utils/backURL';
import NotFoundPage from './NotFoundPage';
import Button from '../components/buttons/Button';
import ListManagementModal from '../components/modal/ListManagementModal';
import AuthContext from '../context/AuthContext';
import LabeledInput from '../components/input/LabeledInput';
import ToggleButton from '../components/buttons/ToggleButton';
import * as ROUTES from '../utils/routesConfig';
import { FaHeart } from 'react-icons/fa';

const GameDetails = () => {
  const { gameSlug } = useParams();
  const { userData, isUserAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [visibility, setVisibility] = useState(false);

  const fetchGame = async ({ queryKey }) => {
    const [, currentSlug] = queryKey;
    const response = await axios.get(
      `${API_ENDPOINTS.FETCH_GAME}/${currentSlug}`,
    );
    return response.data;
  };

  const fetchLists = async () => {
    if (isUserAuthenticated && userData && userData.id) {
      const response = await axios.get(
        `${API_ENDPOINTS.FETCH_GAME_LIST}?user.id=${userData.id}`,
      );
      return response.data['hydra:member'];
    }
    return [];
  };

  const { data: game, isLoading: gameLoading } = useQuery(
    ['game', gameSlug],
    fetchGame,
    { keepPreviousData: false },
  );

  const { data: gameLists, refetch: refetchGameLists } = useQuery(
    ['gameLists'],
    fetchLists,
    {
      enabled: isUserAuthenticated && !!userData && !!userData.id,
    },
  );

  const isGameInList = (list, gameSlug) => {
    return list.userGameListEntries.some(
      (entry) => entry.game.slug === gameSlug,
    );
  };

  const findUserGameListEntryId = (listId, gameSlug) => {
    const list = gameLists.find((list) => list.id === listId);
    const entry = list?.userGameListEntries.find(
      (entry) => entry.game.slug === gameSlug,
    );
    return entry?.id;
  };

  const handleListAction = async (listId, isGameInList) => {
    try {
      if (isGameInList) {
        const userGameListEntryId = findUserGameListEntryId(listId, gameSlug);
        if (userGameListEntryId) {
          await axios.delete(
            `${API_ENDPOINTS.DELETE_GAME_LIST_ENTRIES}/${userGameListEntryId}`,
          );
        }
      } else {
        // Ajouter le jeu à la liste
        await axios.post(`${API_ENDPOINTS.POST_GAME_LIST_ENTRIES}`, {
          game: `api/game/${game.slug}`,
          userGameList: `api/user_game_lists/${listId}`,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la gestion de la liste', error);
      // Gérer l'erreur (afficher un message, etc.)
    } finally {
      refetchGameLists();
    }
  };

  const toggleCreateListForm = () => {
    setIsCreatingList(!isCreatingList);
  };

  const handleCreateList = async () => {
    if (!newListName.trim()) return;
    try {
      await axios.post(API_ENDPOINTS.POST_GAME_LIST, {
        name: newListName,
        user: `api/users/${userData.id}`,
        visibility: visibility ? 'private' : 'public',
      });
      setNewListName('');
      setIsCreatingList(false);
    } catch (error) {
      console.error('Erreur lors de la création de la liste', error);
    } finally {
      refetchGameLists();
    }
  };

  //! Fonctions utiles
  const handleListManagement = () => {
    if (isUserAuthenticated) {
      setIsModalOpen(true);
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsCreatingList(false);
  };

  if (gameLoading) {
    return <Spinner />;
  }

  if (!game) {
    return <NotFoundPage />;
  }

  if (game.status !== 'actif') {
    navigate('/');
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{game.title} | Joystique</title>
        <meta
          name="description"
          content={`Découvrez plus sur ${
            game.title
          }. ${game.description.substring(0, 150)}...`}
        />
      </Helmet>

      {/* Modal de gestion de liste  */}
      {isUserAuthenticated && (
        <ListManagementModal
          isOpen={isModalOpen}
          title={
            isCreatingList ? 'Créer une nouvelle liste' : 'Ajouter à une liste'
          }
          onClose={handleCloseModal}
          showCloseIcon={true}
          Arrow={isCreatingList}
          onBack={toggleCreateListForm}
        >
          {!isCreatingList ? (
            <>
              <Button
                className="mb-2 w-full font-semibold"
                onClick={toggleCreateListForm}
              >
                + Créer une liste
              </Button>
              {isUserAuthenticated && gameLists && (
                <div className="">
                  {gameLists.map((list) => {
                    const gameIsInList = isGameInList(list, gameSlug);

                    return (
                      <Button
                        key={list.id}
                        className={`mb-2 flex w-full items-center justify-center rounded-lg border p-3 ${
                          gameIsInList ? '' : 'bg-white-3 hover:bg-white-6'
                        }`}
                        onClick={() => handleListAction(list.id, gameIsInList)}
                      >
                        <span className="mr-2 text-xs">
                          {gameIsInList ? 'Supprimer de' : 'Ajouter à'}
                        </span>
                        {list.name === 'Favoris' && (
                          <FaHeart className="mr-3 text-red-400" />
                        )}
                        <span className="font-semibold">{list.name}</span>
                      </Button>
                    );
                  })}
                </div>
              )}
            </>
          ) : (
            <div>
              <LabeledInput
                type="text"
                placeholder="Nom de la liste"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
              />
              <ToggleButton
                isChecked={visibility}
                onChange={setVisibility}
                label={visibility ? 'Privée' : 'Public'}
                additionalContainerClass="my-5"
                additionalButtonClass="ml-3"
              />
              <Button onClick={handleCreateList} className="mt-3 w-full">
                Valider
              </Button>
            </div>
          )}
        </ListManagementModal>
      )}

      <div className="mx-auto max-w-[1280px] py-8 pt-16 text-white">
        {/* Conteneur principal pour les détails du jeu */}
        <div className="flex flex-col px-8 md:flex-row">
          {/* Partie gauche | Cover */}
          <div className="mx-auto mb-6 md:mx-8 md:mb-0 md:shrink-0 md:grow-0">
            <img
              src={`${GAME_IMAGE_URL}${game.imageName}`}
              alt={game.title}
              className="aspect-[3/4] w-[350px] rounded-lg object-cover"
            />
          </div>

          {/* Partie droite | Détails */}
          <div className="md:ml-8 md:grow">
            {/* Titre et date de sortie */}
            <h1 className="mb-2 text-2xl font-semibold">{game.title}</h1>
            <p className="mb-4 text-gray-600">
              {new Date(game.releaseDate).toLocaleDateString()}
            </p>
            {/* Plateformes */}
            <div className="mb-6">
              <div className="flex flex-wrap">
                {game.gamePlatforms
                  .sort((a, b) => a.platform.ranking - b.platform.ranking)
                  .map((gp, index) => (
                    <span
                      key={index}
                      className="border-black-5 bg-white-1 mb-3 mr-1 rounded-lg border px-3 text-sm"
                    >
                      {gp.platform.name}
                    </span>
                  ))}
              </div>
            </div>

            {/* Gestion listes*/}

            <div className="mb-3 w-full">
              <Button
                className="h-14 w-full text-center font-bold"
                onClick={handleListManagement}
              >
                +/- Liste
              </Button>
            </div>

            {/* Développeur et éditeur */}
            <div className="bg-white-5 mb-6 flex min-h-[85px] flex-col rounded-lg p-4 md:flex-row">
              <div className="mb-3 flex w-1/2 flex-col md:mb-0">
                <span className="text-black-6 mb-2 font-semibold">
                  Développeur
                </span>
                <span>
                  {game.developer?.isActive ? game.developer.name : ''}
                </span>
              </div>
              <div className="flex w-1/2 flex-col md:pl-3">
                <span className="text-black-6 mb-2 font-semibold">Éditeur</span>
                <span>
                  {game.publisher?.isActive ? game.publisher.name : ''}
                </span>
              </div>
            </div>
            {/* Genres */}
            <div className="mb-6">
              <h2 className="mb-2 text-xl font-semibold">Genres</h2>
              {game.gameTags.map((gt, index) => (
                <span
                  key={index}
                  className="border-black-5 bg-white-1 mb-3 mr-1 rounded-lg border px-3"
                >
                  {gt.tag.value}
                </span>
              ))}
            </div>
            {/* Résumé */}
            <div>
              <h2 className="mb-2 text-xl font-semibold">Résumé</h2>
              <p>{game.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameDetails;
