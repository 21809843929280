import React from 'react';

const About = () => {
  return (
    <div className="text-black-1 mx-auto my-8 max-w-[1280px] px-4 font-sans">
      <h1 className="mb-4 text-3xl font-bold">À Propos de Joystique</h1>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">Notre Mission</h2>
      <p>
        Joystique est né de la passion pour les jeux vidéo et de la volonté de
        créer une communauté interactive pour les joueurs du monde entier. Notre
        mission est de fournir une plateforme où les joueurs peuvent découvrir,
        discuter et partager leur amour pour les jeux vidéo.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">Notre Vision</h2>
      <p>
        Nous aspirons à devenir une référence dans le monde du jeu vidéo, en
        offrant une expérience utilisateur unique qui permet non seulement de
        découvrir de nouveaux jeux mais aussi de participer activement à la
        communauté.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">Notre Histoire</h2>
      <p>
        Fondé en [Année de Création] par [Fondateur(s)], Joystique a commencé
        comme un petit blog partageant des avis sur les jeux vidéo. Depuis, nous
        avons évolué pour devenir une plateforme complète offrant des
        fonctionnalités telles que l'ajout de jeux à la base de données, un
        système de vote pour les jeux préférés, et un forum de discussion pour
        la communauté.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">Rencontrez l'Équipe</h2>
      <p>
        Notre équipe est composée de passionnés de jeux vidéo, de développeurs,
        de designers et de rédacteurs, tous dédiés à offrir la meilleure
        expérience possible à nos utilisateurs.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">Contactez-Nous</h2>
      <p>
        Pour toute question, suggestion ou commentaire, n'hésitez pas à nous
        contacter à [Adresse Email]. Nous sommes toujours heureux d'entendre
        notre communauté et de nous améliorer grâce à vos retours.
      </p>
    </div>
  );
};

export default About;
