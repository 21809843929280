import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconContext } from 'react-icons';

const DropdownClick = ({
  icon,
  title,
  links,
  onClick,
  additionalClassLi,
  additionalClassTitle,
  additionalClassLink,
  additionalClassLabel,
}) => {
  // Gère l'état d'ouverture/fermeture du menu déroulant
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  // Bascule l'état d'ouverture du menu déroulant
  const toggleOpen = () => setIsOpen(!isOpen);

  const baseStyleLi =
    'flex space-x-2 items-center justify-between py-3 px-5 hover:bg-black-2 cursor-pointer';
  const titleStyle = 'text-[0.8rem]';
  const baseStyleLink =
    'flex flex-col transition-all duration-500 ease-in-out overflow-hidden';
  const labelStyle = 'text-xs';

  const combinedStylesLi = `${baseStyleLi} ${additionalClassLi}`;
  const combinedStylesTitle = `${titleStyle} ${additionalClassTitle}`;
  const combinedStylesLink = `${baseStyleLink} ${additionalClassLink}`;
  const combinedStylesLabel = `${labelStyle} ${additionalClassLabel}`;

  return (
    <>
      <li className={combinedStylesLi} onClick={toggleOpen}>
        <div className="flex items-center space-x-2">
          <IconContext.Provider
            value={{ size: '15px', className: 'text-white-1' }}
          >
            {icon}
          </IconContext.Provider>
          <p className={combinedStylesTitle}>{title}</p>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`text-white-1 size-4 transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </li>
      {links && links.length > 0 && (
        <div
          className={`${combinedStylesLink} ${isOpen ? 'max-h-40' : 'max-h-0'}`}
        >
          {links.map((link, index) => {
            const isActiveLink = location.pathname === link.path;
            const activeLinkClass = isActiveLink ? 'bg-black-2' : '';

            return (
              <Link
                key={index}
                to={link.path}
                className={`hover:bg-black-2 py-2 pl-12 ${activeLinkClass}`}
                onClick={() => onClick(false)}
              >
                {link.icon && (
                  <IconContext.Provider value={{ className: 'inline mr-2' }}>
                    {link.icon}
                  </IconContext.Provider>
                )}
                <span className={combinedStylesLabel}>{link.label}</span>
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DropdownClick;
