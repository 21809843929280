import axios from "axios";
import { refreshTokenIfNeeded } from "./authService";
import { API_ENDPOINTS } from "./backURL";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshTokenUrl = API_ENDPOINTS.TOKEN_REFRESH;

    if (error.response.status === 401 &&
        originalRequest.url !== refreshTokenUrl &&
        !originalRequest._retry) {

      originalRequest._retry = true;
      const tokenRefreshed = await refreshTokenIfNeeded();
      if (tokenRefreshed) {
        return axios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);
