import React, { useEffect, useState } from 'react';
import { GAME_IMAGE_URL } from '../../../utils/backURL';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../utils/routesConfig';

const SearchResults = ({
  games,
  isSearchBarOpen,
  inputValue,
  isLoading,
  toggleSearchBar,
}) => {
  const [lastSearchValue, setLastSearchValue] = useState(inputValue);
  const hideOnDesktop = inputValue.length === 0 || isLoading ? 'md:hidden' : '';

  useEffect(() => {
    const timer = setTimeout(() => {
      setLastSearchValue(inputValue);
    }, 1000);

    return () => clearTimeout(timer);
  }, [inputValue]);

  if (!isSearchBarOpen) {
    return null;
  }

  return (
    <div
      className={`bg-white-1 h-screen-100-64 fixed top-[64px] z-50 w-full overflow-y-auto border p-3 shadow-md md:absolute md:left-1/2 md:top-[60px] md:h-auto md:max-h-[300px] md:w-[80%] md:max-w-[1280px] md:-translate-x-1/2 md:rounded-lg ${hideOnDesktop}`}
    >
      {inputValue.length > 0 &&
        (games && games.length > 0
          ? games.map((game) => (
              <Link
                key={game.id}
                to={`${ROUTES.GAME_DETAILS.replace(':gameSlug', game.slug)}`}
                className="hover:bg-white-4 flex cursor-pointer rounded-lg p-2"
                onClick={toggleSearchBar}
              >
                <img
                  src={`${GAME_IMAGE_URL}${game.imageName}`}
                  alt={game.title}
                  className="mr-2 size-12 rounded-lg object-cover"
                />
                <div>
                  <p>{game.title}</p>
                  {game.gamePlatforms.map((gp, index, array) => (
                    <span key={index} className="text-black-7 text-xs">
                      {gp.platform.name}
                      {index < array.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </div>
              </Link>
            ))
          : lastSearchValue.length > 0 && (
              <p>
                Aucun résultat pour{' '}
                <span className="font-bold">'{lastSearchValue}'</span>
              </p>
            ))}
    </div>
  );
};

export default SearchResults;
