import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IconContext } from "react-icons";

const SidebarLink = ({
  icon,
  title,
  link,
  onClick,
  additionalClassLink,
  additionalClassTitle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
    if (link) {
      event.preventDefault();
      navigate(link);
      window.scrollTo(0, 0);
    }
  };

  const baseStyleLink =
    "flex space-x-2 items-center py-3 px-5 hover:bg-black-2";
  const titleStyle = "text-[0.8rem]";

  // Détermine si le lien est actif
  const isActive = location.pathname === link;
  const activeClass = isActive ? "bg-black-2" : "";

  const combinedStylesLink = `${baseStyleLink} ${additionalClassLink} ${activeClass}`;
  const combinedStylesTitle = `${titleStyle} ${additionalClassTitle}`;

  return (
    <Link to={link} onClick={handleClick}>
      <li className={combinedStylesLink}>
        {icon && (
          <IconContext.Provider
            value={{ size: "15px", className: "text-white-1" }}
          >
            {icon}
          </IconContext.Provider>
        )}
        <p className={combinedStylesTitle}>{title}</p>
      </li>
    </Link>
  );
};

export default SidebarLink;
