import React from 'react';
import Section from '../../../components/ui/Section';
import SectionHeader from '../../../components/ui/SectionHeader';
import Button from '../../../components/buttons/Button';
import { GAME_IMAGE_URL } from '../../../utils/backURL';
import * as ROUTES from '../../../utils/routesConfig';

const VersusSection = ({ isVoteChecking, hasVoting, versus }) => {
  if (isVoteChecking || !versus) return null;

  return (
    <Section additionalClass="mx-0 md:mx-4">
      <SectionHeader title="Communauté" additionalClass="mx-4 md:mx-0" />
      <div className="mx-auto max-w-[1280px] rounded-lg md:relative md:flex md:h-[400px] md:overflow-hidden lg:h-[600px]">
        <div className="left-panel bg-accent-8 max-h-[20vh] shadow-inner md:absolute md:h-full md:max-h-none md:w-1/4 md:max-w-[400px] md:rounded-l-lg lg:w-1/3">
          <img
            src={`${GAME_IMAGE_URL}${versus.gameVotesDetails[0].gameImage}`}
            alt={versus.gameVotesDetails[0].gameTitle}
            className="h-full max-h-[20vh] w-full object-cover md:max-h-none md:rounded-l-lg"
          />
          <div className="bg-black-1 left-0 top-0 h-full w-full rounded-l-lg opacity-40 md:absolute"></div>
        </div>

        {hasVoting ? (
          <div className="xs:grid-cols-3 mx-4 my-3 grid h-[130px] grid-cols-2 gap-3 text-center md:mx-auto md:w-1/2 md:self-center lg:w-[35%]">
            <div className="flex flex-col self-center">
              <p className="truncate">{versus.gameVotesDetails[0].gameTitle}</p>
              <div className="bg-accent-10 mx-auto h-1 w-7 rounded-full"></div>
              <p className="font-inter ml-2 py-1 text-4xl font-black lg:text-6xl">
                {Math.round(versus.gameVotesDetails[0].votePercentage)}
                <span className="text-xl">%</span>
              </p>
            </div>
            <div className="xs:inline-flex hidden flex-col items-center justify-center">
              <div className="bg-white-4 rounded-lg px-2 py-3 text-sm shadow-sm">
                <p className="mb-1">{versus.title}</p>
                <p className="text-xs font-thin">
                  Fin : {new Date(versus.endTime).toLocaleDateString()}
                </p>
              </div>
            </div>
            <div className="flex flex-col self-center">
              <p className="font-inter ml-2 py-1 text-4xl font-black lg:text-6xl">
                {Math.round(versus.gameVotesDetails[1].votePercentage)}
                <span className="text-xl">%</span>
              </p>
              <div className="bg-accent-10 mx-auto h-1 w-7 rounded-full md:order-2"></div>
              <p className="truncate">{versus.gameVotesDetails[1].gameTitle}</p>
            </div>
          </div>
        ) : (
          <div className="mx-auto my-4 flex h-1/2 flex-col items-center justify-center md:my-auto md:w-1/3">
            <div className="text-center">
              <p className="text- uppercase">Vote pour ton</p>
              <p className="text-3xl font-black uppercase">Jeu Favori</p>
            </div>
            <Button
              link={`${ROUTES.VERSUS.replace(':versusSlug', versus.slug)}`}
              className="mt-2 md:mt-8"
            >
              Vote Maintenant
            </Button>
          </div>
        )}

        <div className="right-panel bg-black-3 max-h-[20vh] shadow-inner md:absolute md:right-0 md:h-full md:max-h-none md:w-1/4 md:max-w-[400px] md:rounded-r-lg lg:w-1/3">
          <img
            src={`${GAME_IMAGE_URL}${versus.gameVotesDetails[1].gameImage}`}
            alt={versus.gameVotesDetails[0].gameTitle}
            className="h-full max-h-[20vh] w-full object-cover md:max-h-none md:rounded-r-lg"
          />
          <div className="bg-black-1 left-0 top-0 h-full w-full rounded-r-lg opacity-40 md:absolute"></div>
        </div>
      </div>
    </Section>
  );
};

export default VersusSection;
