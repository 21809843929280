import React, { useEffect, useState } from "react";
import AuthContext from "./AuthContext";
import { checkAuthentication, logout } from "../utils/authService";
import Spinner from "../components/spinner/Spinner";

//! Fournisseur d'authentification
// fournit un contexte d'authentification.

const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  //! Vérifier l'état d'authentification de l'utilisateur à l'initialisation
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const data = await checkAuthentication();
      if (data) {
        setIsAuth(true);
        setUserData(data);
        setIsAdmin(data.roles.includes("ROLE_ADMIN"));
      } else {
        setIsAuth(false);
        setUserData(null);
      }
      setIsLoading(false);
    })();
  }, []);

  //! Lors de la connexion modifie l'état pour indiquer que l'utilisateur est authentifié.
  const login = async () => {
    const data = await checkAuthentication();
    if (data) {
      setIsAuth(true);
      setUserData(data);
      setIsAdmin(data.roles.includes("ROLE_ADMIN"));
    } else {
      setIsAuth(false);
      setUserData(null);
      setIsAdmin(false);
    }
  };

  //! Gère la déconnexion de l'utilisateur.
  const handleLogout = () => {
    logout();
    setIsAuth(false);
    setUserData(null);
    setIsAdmin(false);
  };



  return (
    <AuthContext.Provider
      value={{
        isUserAuthenticated: isAuth,
        isAdmin,
        handleLogout,
        userData,
        setUserData,
        login,
        isLoading,
      }}
    >
      {isLoading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
