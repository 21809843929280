import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from '../../../utils/backURL';
import Spinner from '../../../components/spinner/Spinner';
import { GAME_IMAGE_URL } from '../../../utils/backURL';
import Button from '../../../components/buttons/Button';
import { IconContext } from 'react-icons';
import { AiOutlineEye } from 'react-icons/ai';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import Pagination from '../../../components/pagination/Pagination';
import * as ROUTES from '../../../utils/routesConfig';

const AdminGames = () => {
  const [visibleDetails, setVisibleDetails] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFetchingGames, setIsFetchingGames] = useState(false);
  const [title, setTitle] = useState('Tous les jeux');
  const ITEMS_PER_PAGE = 30;

  //! Construit l'URL pour la requête API en fonction des paramètres de pagination et de filtrage
  const buildURL = (page, search, status) => {
    let url = `${API_ENDPOINTS.FETCH_GAMES}?page=${page}`;
    if (search) {
      url += `&slug=${encodeURIComponent(search)}`;
    }
    if (status) {
      url += `&status=${encodeURIComponent(status)}`;
    }
    return url;
  };

  //! Récupère les jeux depuis l'API
  const fetchGames = useCallback(async () => {
    setIsFetchingGames(true);
    const url = buildURL(currentPage, searchQuery, selectedStatus);
    try {
      const response = await axios.get(url);
      setTotalPages(
        Math.ceil(response.data['hydra:totalItems'] / ITEMS_PER_PAGE),
      );
      return response.data['hydra:member'];
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des jeux : ',
        error.message,
      );
    } finally {
      setIsFetchingGames(false);
    }
  }, [currentPage, searchQuery, selectedStatus]);

  //! Bascule la visibilité des détails de la plateforme pour un jeu spécifique
  const togglePlatformDetails = (gameId) => {
    setVisibleDetails((prev) => ({
      ...prev,
      [gameId]: { ...prev[gameId], platforms: !prev[gameId]?.platforms },
    }));
  };

  //! Bascule la visibilité des tags pour un jeu spécifique
  const toggleTagDetails = (gameId) => {
    setVisibleDetails((prev) => ({
      ...prev,
      [gameId]: { ...prev[gameId], tags: !prev[gameId]?.tags },
    }));
  };

  //! Utilise React Query pour charger les données des jeux
  const {
    data: games,
    isLoading: gamesLoading,
    isError: gamesError,
    refetch,
  } = useQuery(['games', currentPage, selectedStatus, searchQuery], fetchGames);

  //! Refetch les jeux lorsque les paramètres de recherche ou de pagination changent
  useEffect(() => {
    let newTitle = 'Jeux';

    if (selectedStatus) {
      newTitle += ` - ${
        selectedStatus.charAt(0).toUpperCase() + selectedStatus.slice(1)
      }`;
    }

    if (searchQuery) {
      newTitle += ` - ${
        searchQuery.charAt(0).toUpperCase() + searchQuery.slice(1)
      }`;
    }

    setTitle(newTitle);
    refetch();
  }, [currentPage, searchQuery, selectedStatus, refetch]);

  //! Gère le changement de page dans la pagination
  const handlePageChange = (newPage) => setCurrentPage(newPage);

  if (gamesError) return <p>Erreur de chargement des jeux.</p>;

  return (
    <div className="mx-3 mt-[90px] md:mx-8 md:mt-8">
      <div className="bg-white-2 mb-8 rounded-lg px-4 py-2 shadow-xl">
        <h1 className="font-bungee text-center text-xl">{title}</h1>
        <div className="my-4">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Rechercher par titre..."
            className="w-full rounded border px-3 py-2"
          />
        </div>
        <div className="my-4 flex flex-wrap items-center">
          <label
            htmlFor="status-select"
            className="mr-3 block text-sm font-semibold uppercase"
          >
            Statut :
          </label>
          <select
            id="status-select"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="rounded border px-3 py-2"
          >
            <option value="">Tous les statuts</option>
            <option value="actif">Actif</option>
            <option value="en attente">En attente</option>
            <option value="inactif">Inactif</option>
          </select>
        </div>
      </div>
      {/*//!-------- Tableau ---------------*/}
      <div className="overflow-x-auto rounded-lg shadow-xl">
        <table className="min-w-full table-auto text-xs">
          {/*//!-------- En-têtes ---------------*/}
          <thead>
            <tr className="bg-black-4 text-white-1 ">
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Actions
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]"></th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Titre
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Statut
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Description
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Date de sortie
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Plateforme(s)
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Tag(s)
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Editeur
              </th>
              <th className="whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem]">
                Développeur
              </th>
            </tr>
          </thead>
          {/*//!-------- Corp du tableau ---------------*/}
          <tbody className="bg-white-2 text-center">
            {isFetchingGames || gamesLoading ? (
              <tr className="table-row">
                <td colSpan="9" className="table-cell text-center align-middle">
                  <Spinner />
                </td>
              </tr>
            ) : (
              games.map((game) => (
                <tr key={game.id} className="hover:bg-white-4 my-3 rounded">
                  {/*//!-------- Actions ---------------*/}
                  <td className="whitespace-nowrap border px-2">
                    <Button
                      className="mx-auto py-0 text-xs"
                      link={`${ROUTES.ADMIN_GAME.replace(
                        ':gameSlug',
                        game.slug,
                      )}`}
                    >
                      Vérifier
                    </Button>
                  </td>
                  {/*//!-------- Cover ---------------*/}
                  <td className="border">
                    <img
                      src={`${GAME_IMAGE_URL}${game.imageName}`}
                      alt={game.title}
                      className="max-h-[40px] rounded object-cover"
                    />
                  </td>
                  {/*//!-------- Titre ---------------*/}
                  <td className="whitespace-nowrap border px-4 font-semibold">
                    {game.title}
                  </td>
                  {/*//!-------- Status ---------------*/}
                  <td
                    className={`whitespace-nowrap border px-4 font-semibold capitalize ${
                      game.status === 'en attente'
                        ? 'text-orange-400'
                        : game.status === 'inactif'
                          ? 'text-red-500'
                          : game.status === 'actif'
                            ? 'text-green-700'
                            : ''
                    }`}
                  >
                    {game.status}
                  </td>

                  {/*//!-------- Description ---------------*/}
                  <td className="max-w-[150px] truncate whitespace-nowrap border px-4">
                    {game.description}
                  </td>
                  {/*//!-------- Date de sortie ---------------*/}
                  <td className="whitespace-nowrap border px-4">
                    {new Date(game.releaseDate).toLocaleDateString()}
                  </td>
                  {/*//!-------- Platforms ---------------*/}
                  <td className="whitespace-nowrap border px-4">
                    {visibleDetails[game.id]?.platforms ? (
                      <div className="flex">
                        <IconContext.Provider
                          value={{
                            size: '20px',
                            className: 'text-blue-400 cursor-pointer mx-auto',
                          }}
                        >
                          <AiOutlineEyeInvisible
                            onClick={() => togglePlatformDetails(game.id)}
                          />
                        </IconContext.Provider>
                        {game.gamePlatforms.map((gp, index) => (
                          <span
                            key={index}
                            className="bg-white-3 mr-2 rounded border px-2"
                          >
                            {gp.platform.name}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <IconContext.Provider
                        value={{
                          size: '20px',
                          className: 'text-blue-400 cursor-pointer mx-auto',
                        }}
                      >
                        <AiOutlineEye
                          onClick={() => togglePlatformDetails(game.id)}
                        />
                      </IconContext.Provider>
                    )}
                  </td>
                  {/*//!-------- Tags ---------------*/}
                  <td className="whitespace-nowrap border px-4">
                    {visibleDetails[game.id]?.tags ? (
                      <div className="flex">
                        <IconContext.Provider
                          value={{
                            size: '20px',
                            className: 'text-blue-400 cursor-pointer mx-auto',
                          }}
                        >
                          <AiOutlineEyeInvisible
                            onClick={() => toggleTagDetails(game.id)}
                          />
                        </IconContext.Provider>
                        {game.gameTags.map((gt, index) => (
                          <span
                            key={index}
                            className="bg-white-3 mr-2 rounded border px-2"
                          >
                            {gt.tag.value}
                          </span>
                        ))}
                      </div>
                    ) : (
                      <IconContext.Provider
                        value={{
                          size: '20px',
                          className: 'text-blue-400 cursor-pointer mx-auto',
                        }}
                      >
                        <AiOutlineEye
                          onClick={() => toggleTagDetails(game.id)}
                        />
                      </IconContext.Provider>
                    )}
                  </td>
                  {/*//!-------- Editeurs ---------------*/}
                  <td className="whitespace-nowrap border px-4">
                    {game.publisher && game.publisher.name
                      ? game.publisher.name
                      : 'Aucun'}
                  </td>
                  {/*//!-------- Dévelopeurs ---------------*/}
                  <td className="whitespace-nowrap border px-4">
                    {game.developer && game.developer.name
                      ? game.developer.name
                      : 'Aucun'}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default AdminGames;
