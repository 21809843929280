// Formate une date et une heure au format "jj/mm/aaaa hh:mm"
export const formatDateHour = (dateString) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return new Date(dateString)
    .toLocaleDateString("fr-FR", options)
    .replace(",", " à");
};

// Formate un pourcentage en ajoutant un zéro en tête si nécessaire + arrondi. Par exemple, 9,67% devient 10%
export const formatPercentage = (percentage) => {
  let rounded = Math.round(percentage);
  return rounded.toString().padStart(2, '0');
};
