const Section = ({ children, additionalClass, variant = 'default' }) => {
  const baseStyle = 'pb-12';
  const variantStyles = {
    default: '',
    wrapper: 'mx-auto max-w-[1280px]',
  };
  const combinedStyles = `${baseStyle} ${variantStyles[variant]} ${additionalClass}`;

  return <section className={combinedStyles}>{children}</section>;
};

export default Section;
