import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../utils/backURL';
import Button from '../components/buttons/Button';
import LabeledInput from '../components/input/LabeledInput';
import { Helmet } from 'react-helmet';
import * as ROUTES from '../utils/routesConfig';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (newPassword.length < 8) {
      setErrorMessage('Le mot de passe doit comporter au moins 8 caractères.');
      return;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setErrorMessage(
        'Le mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre, et doit comporter au moins 8 caractères.',
      );
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      const response = await axios.post(
        `${API_ENDPOINTS.POST_RESET_PASSWORD}/${token}`,
        { password: newPassword },
      );
      navigate(ROUTES.LOGIN, { state: { mdpUpdated: true } });
    } catch (error) {
      setErrorMessage(
        error.response.data.error ||
          'Erreur lors de la réinitialisation du mot de passe.',
      );
    }
  };

  return (
    <div className="flex min-h-[500px] items-center justify-center px-4">
      <Helmet>
        <title>Réinitialisation du Mot de Passe | Joystique</title>
        <meta
          name="description"
          content="Définissez un nouveau mot de passe pour votre compte Joystique. Saisissez et confirmez votre nouveau mot de passe pour sécuriser votre compte."
        />
      </Helmet>
      <div className="bg-white-2 mx-auto my-10 w-[400px] max-w-xl rounded border p-5 shadow-lg">
        <h2 className="mb-4 text-center text-[1rem] font-semibold uppercase first-letter:text-[1.3rem]">
          Réinitialisation du mot de passe
        </h2>
        {errorMessage && (
          <p className="my-4 rounded bg-red-100 p-3 text-center text-xs text-red-700">
            {errorMessage}
          </p>
        )}
        <form onSubmit={handleSubmit}>
          <LabeledInput
            label="Nouveau mot de passe"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            additionalInputClass="mb-4"
            required
          />
          <LabeledInput
            label="Confirmer le mot de passe"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            additionalInputClass="mb-4"
            required
          />
          <Button type="submit" className="w-full">
            Confirmer
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
