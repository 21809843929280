import React from 'react';

const Overlay = ({ isSidebarOpen, closeSidebar }) => {
  return (
    <div
      className={`bg-black-1 fixed left-0 top-0 h-full w-full transition-opacity duration-1000 ease-in-out ${
        isSidebarOpen ? 'opacity-60' : 'pointer-events-none opacity-0'
      } z-10 md:hidden`}
      onClick={closeSidebar}
    ></div>
  );
};

export default Overlay;
