import React from 'react';
import LabeledInput from '../../../../components/input/LabeledInput';
import { GAME_IMAGE_URL } from '../../../../utils/backURL';
import logoxs from '../../../../assets/logo/logo-xs-wy.svg';

const GameSelection = ({
  games,
  onSelectGame,
  selectedGame,
  resetGame,
  searchQuery,
  setSearchQuery,
  allowedChange,
}) => {
  console.log('selectedGame', selectedGame);
  console.log('games', games);
  console.log('allowedChange', allowedChange);
  return (
    <div className="w-5/12">
      {/* Image */}
      <div className="bg-black-7 aspect-3/4 mx-auto max-h-[400px] max-w-[300px] overflow-hidden rounded">
        {selectedGame && selectedGame.imageName ? (
          <img
            src={`${GAME_IMAGE_URL}${selectedGame.imageName}`}
            alt={selectedGame.title}
            className="h-full w-full object-cover"
          />
        ) : (
          <img
            src={logoxs}
            alt="Placeholder"
            className="h-full w-full object-contain"
          />
        )}
      </div>
      {/* Input */}
      <div className="relative mx-auto max-w-[300px]">
        {!selectedGame ? (
          <>
            <LabeledInput
              placeholder="Titre du jeu. ex: Fortnite"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              additionalContainerClass={`mt-3`}
            />
            {games && (
              <div className="bg-white-2 absolute max-h-64 w-full overflow-y-auto rounded border">
                {games.map((game) => (
                  <div
                    className="hover:bg-white-4 cursor-pointer p-3"
                    key={game.id}
                    onClick={() => onSelectGame(game)}
                  >
                    {game.title}
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <>
            {allowedChange === false ? (
              <span className="text-xs">
                {selectedGame && selectedGame.title ? selectedGame.title : ''}
              </span>
            ) : (
              <LabeledInput
                value={selectedGame.title}
                disabled={true}
                showButton={true}
                buttonText="X"
                onButtonClick={resetGame}
                additionalContainerClass={`relative max-w-[300px] mx-auto mt-3`}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GameSelection;
