import React, { useState } from 'react';
import LabeledInput from '../../../../components/input/LabeledInput';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../../utils/backURL';
import GameSelection from './GameSelection';
import Button from '../../../../components/buttons/Button';
import * as ROUTES from '../../../../utils/routesConfig';
import AlertMessage from '../../../../components/alertMessage/AlertMessage';
import { useNavigate } from 'react-router-dom';
import { generateSlug } from '../../../../utils/slugger';

const AdminVersusAdd = () => {
  const [searchQuery1, setSearchQuery1] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');
  const [selectedGame1, setSelectedGame1] = useState(null);
  const [selectedGame2, setSelectedGame2] = useState(null);
  const [title, setTitle] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });

  //! Construit l'URL pour la requête API en fonction de la recherche
  const buildURL = (page, search, status) => {
    let url = `${API_ENDPOINTS.FETCH_GAMES}?page=${page}`;
    if (search) {
      url += `&slug=${encodeURIComponent(search)}`;
    }
    if (status) {
      url += `&status=${encodeURIComponent(status)}`;
    }
    return url;
  };

  //! Fonction pour appeler l'API et rechercher des jeux
  const fetchSearchResults = async (searchQuery) => {
    const formattedSearchQuery = searchQuery.replace(/\s+/g, '-');
    const url = buildURL(1, formattedSearchQuery, 'actif');
    try {
      const response = await axios.get(url, {
        withCredentials: true,
      });
      return response.data['hydra:member'];
    } catch (error) {
      throw new Error('Erreur lors de la recherche : ' + error.message);
    }
  };

  //! Query des jeux
  // Query pour les jeux en fonction de searchQuery1
  const { data: games1 } = useQuery(
    ['games', searchQuery1],
    () => fetchSearchResults(searchQuery1),
    { enabled: !!searchQuery1 },
  );

  // Query pour les jeux en fonction de searchQuery2
  const { data: games2 } = useQuery(
    ['games', searchQuery2],
    () => fetchSearchResults(searchQuery2),
    { enabled: !!searchQuery2 },
  );

  // Gérer la sélection des jeux
  const handleSelectGame1 = (game) => {
    setSelectedGame1(game);
    setSearchQuery1('');
  };

  const handleSelectGame2 = (game) => {
    setSelectedGame2(game);
    setSearchQuery2('');
  };

  // Fonction pour afficher l'alerte
  const showAlert = (type, title, message) => {
    setAlert({ show: true, type, title, message });
    setTimeout(
      () => setAlert({ show: false, type: '', title: '', message: '' }),
      3000,
    );
  };

  // Mutation pour créer un nouveau Versus
  const createVersusMutation = useMutation(
    (newVersus) => axios.post(API_ENDPOINTS.POST_VERSUS, newVersus),
    {
      onSuccess: () => {
        navigate(ROUTES.ADMIN_VERSUS);
        showAlert('success', 'Succès', 'Versus créé avec succès !');
      },
      onError: (error) => {
        let message = 'Erreur lors de la création du Versus.';
        if (error.response && error.response.data) {
          const errorMsg = error.response.data['hydra:description'] || message;
          const formattedMessage = errorMsg.replace(/(slug:)\s?/, '');
          message = formattedMessage;
        }
        showAlert('error', 'Erreur', message);
      },
    },
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const newVersus = {
      title,
      slug: generateSlug(title),
      startTime,
      endTime,
      status: 'planifié',
      versusGames: [
        { game: selectedGame1 ? `/api/game/${selectedGame1.slug}` : null },
        { game: selectedGame2 ? `/api/game/${selectedGame2.slug}` : null },
      ].filter((game) => game.game !== null),
    };
    createVersusMutation.mutate(newVersus);
  };

  return (
    <div className="mt-[90px] px-4 md:mt-8">
      {alert.show && (
        <AlertMessage
          type={alert.type}
          title={alert.title}
          message={alert.message}
          autoCloseTime={3000}
          position="mx-auto"
        />
      )}
      <h1 className="font-bungee mb-5 text-center text-xl">Versus</h1>
      <div className=" bg-white-2 mb-12 rounded-lg border px-4 py-12 shadow-xl">
        <LabeledInput
          placeholder="Titre du versus"
          additionalContainerClass="mb-3 md:w-1/2 mx-auto"
          onChange={(e) => setTitle(e.target.value)}
        />
        <div className="s:flex s:space-x-8 mx-auto mb-12 md:w-1/2">
          <LabeledInput
            label="Date de début"
            type="date"
            additionalContainerClass="w-full s:w-1/2"
            onChange={(e) => setStartTime(e.target.value)}
          />
          <LabeledInput
            label="Date de fin"
            type="date"
            additionalContainerClass="w-full s:w-1/2"
            onChange={(e) => setEndTime(e.target.value)}
          />
        </div>
        <div className="mb-8 flex">
          {/* Jeu 1 */}
          <GameSelection
            games={games1}
            onSelectGame={handleSelectGame1}
            selectedGame={selectedGame1}
            resetGame={() => setSelectedGame1(null)}
            searchQuery={searchQuery1}
            setSearchQuery={setSearchQuery1}
          />

          {/* Icone personnalisé du VS */}
          <div className="flex w-2/12 items-center justify-center">
            <div className="font-inter relative font-bold lg:text-[4rem]">
              <span className="relative left-[-33%]">V</span>
              <span className="absolute left-[33%] top-[18%]">S</span>
            </div>
          </div>

          {/* Jeu 1 */}
          <GameSelection
            games={games2}
            onSelectGame={handleSelectGame2}
            selectedGame={selectedGame2}
            resetGame={() => setSelectedGame2(null)}
            searchQuery={searchQuery2}
            setSearchQuery={setSearchQuery2}
          />
        </div>
        <div className="xs:flex xs:space-x-8 xs:mx-auto xl:w-4/5">
          <Button
            link={ROUTES.ADMIN_VERSUS}
            variant="secondary"
            className="xs:mb-0 xs:w-1/2 mb-3 w-full"
          >
            Retour
          </Button>
          <Button
            type="submit"
            className="xs:w-1/2 w-full"
            onClick={handleSubmit}
          >
            Soumettre
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminVersusAdd;
