import React from 'react';

const PrivacyAndCookies = () => {
  return (
    <div className="text-black-1 mx-auto my-8 max-w-[1280px] px-4 font-sans">
      <h1 className="mb-4 text-3xl font-bold">
        Politique de Confidentialité et Cookies
      </h1>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        1. Politique de Confidentialité
      </h2>
      <p>
        Chez Joystique, nous nous engageons à protéger la confidentialité et la
        sécurité des informations personnelles de nos utilisateurs. Cette
        politique explique comment nous recueillons, utilisons, divulguons, et
        protégeons les informations personnelles de nos utilisateurs.
      </p>
      <p>
        Les informations personnelles collectées peuvent inclure, mais ne se
        limitent pas à, le nom d'utilisateur, l'adresse e-mail, et toute autre
        information que vous choisissez de fournir lors de votre utilisation de
        Joystique.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        2. Utilisation des Cookies
      </h2>
      <p>
        Joystique utilise des cookies JWT (JSON Web Tokens) pour
        l'authentification des utilisateurs. Ces cookies sont essentiels pour
        fournir un accès sécurisé à votre compte et pour maintenir votre session
        active lors de la navigation sur le site.
      </p>
      <p>
        Un "refresh token" est également utilisé et stocké dans notre base de
        données pour renouveler automatiquement votre session et améliorer la
        sécurité de votre connexion.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        3. Gestion des Cookies
      </h2>
      <p>
        Les cookies sont de petits fichiers texte stockés sur votre appareil par
        votre navigateur web. Vous avez la possibilité de contrôler et de gérer
        les cookies via les paramètres de votre navigateur. Veuillez noter que
        la suppression ou le blocage des cookies nécessaires à
        l'authentification peut affecter votre accès et votre expérience sur le
        site Joystique.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        4. Sécurité des Données
      </h2>
      <p>
        La sécurité de vos informations personnelles est primordiale pour nous.
        Nous prenons des mesures appropriées pour protéger vos données contre
        l'accès non autorisé, la divulgation, la modification ou la destruction
        non autorisée. Cependant, aucun système n'est infaillible, et nous ne
        pouvons garantir la sécurité absolue de vos informations.
      </p>

      <h2 className="mb-3 mt-6 text-2xl font-semibold">
        5. Modifications de la Politique de Confidentialité
      </h2>
      <p>
        Joystique se réserve le droit de modifier cette politique de
        confidentialité à tout moment. Toutes les modifications seront publiées
        sur cette page, et nous vous encourageons à la consulter régulièrement
        pour vous tenir informé des éventuelles mises à jour.
      </p>
    </div>
  );
};

export default PrivacyAndCookies;
