import React from 'react';

const Spinner = ({ mini }) => {
  return (
    <div
      className={`flex items-center justify-center ${mini ? '' : 'h-screen w-screen'}`}
    >
      <div className="relative size-[100px]">
        {/* Div pour le carré qui tourne. Utilise animate-spin pour la rotation. */}
        <div className="duration-2000 absolute inset-0 flex animate-spin items-center justify-center">
          {/* Div intérieure du carré.. */}
          <div className="border-accent-9 size-[100px] border-4"></div>
        </div>
        {/* Div pour le texte "J+". Il est positionné absolument pour être superposé au carré en rotation. */}
        <div className="font-bungee text-black-6 absolute inset-0 flex items-center justify-center text-[3rem]">
          J+
        </div>
      </div>
    </div>
  );
};

export default Spinner;
