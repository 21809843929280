import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { API_ENDPOINTS } from '../utils/backURL';
import * as ROUTES from '../utils/routesConfig';
import AuthContext from '../context/AuthContext';
import Button from '../components/buttons/Button';
import LabeledInput from '../components/input/LabeledInput';
import AuthHeader from '../components/header/AuthHeader';
import { Helmet } from 'react-helmet';
import AlertMessage from '../components/alertMessage/AlertMessage';
import Navbar from '../components/layout/Navbar/Navbar';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationLink, setVerificationLink] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isUserAuthenticated, setUserData, handleLogout } =
    useContext(AuthContext);
  const emailUpdated = location.state?.emailUpdated;
  const mdpUpdated = location.state?.mdpUpdated;

  //! Redirection si l'utilisateur est déjà authentifié
  useEffect(() => {
    if (isUserAuthenticated) {
      navigate(location.state?.from || '/');
    }
  }, [isUserAuthenticated, navigate, location.state]);

  //! Appelé chaque fois que l'utilisateur tape dans le champ e-mail
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (verificationLink) {
      setVerificationLink(false);
    }
  };

  //! Appelé chaque fois que l'utilisateur tape dans le champ mdp
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (verificationLink) {
      setVerificationLink(false);
    }
  };

  //! Connexion via API
  const mutation = useMutation(
    () =>
      axios.post(
        API_ENDPOINTS.AUTH,
        { email, password },
        { withCredentials: true },
      ),
    {
      onSuccess: async () => {
        try {
          const userDataResponse = await axios.get(API_ENDPOINTS.FETCH_ME, {
            withCredentials: true,
          });
          if (!userDataResponse.data.isVerified) {
            setVerificationLink(true);
            handleLogout();
          } else {
            setUserData(userDataResponse.data);
            login();
            navigate('/');
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données de l'utilisateur après connexion:",
            error,
          );
        }
      },

      onError: (error) => {
        console.error('Erreur de connexion:', error);
      },
    },
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate();
  };

  return (
    <>
      <Navbar />
      <div className="lg:min-h-screen-100-64 flex flex-col lg:mt-0 lg:flex-row lg:items-stretch">
        <Helmet>
          <title>Connexion à Joystique | Votre Espace de Jeux Vidéo</title>
          <meta
            name="description"
            content="Connectez-vous à Joystique pour rejoindre une communauté passionnée de jeux vidéo. Partagez vos expériences et découvrez de nouveaux jeux."
          />
        </Helmet>
        {emailUpdated && (
          <AlertMessage
            title="Succès"
            message="Votre email a été mis à jour. Veuillez vérifier votre nouvelle adresse email."
            type="success"
            autoCloseTime={3000}
          />
        )}
        {mdpUpdated && (
          <AlertMessage
            title="Succès"
            message="Mot de passe réinitialisé avec succès"
            type="success"
            autoCloseTime={3000}
          />
        )}

        <div className="bg-login_banner hidden grow bg-cover bg-center bg-no-repeat lg:block lg:min-h-full lg:w-2/3"></div>
        <div className="xs:px-10 mx-auto flex max-w-[600px] grow flex-col overflow-auto px-4 pt-6 lg:w-1/3 lg:justify-normal lg:py-10">
          <AuthHeader activeLink="login" />
          {mutation.isError ? (
            <div className="my-4 rounded bg-red-100 p-3 text-center text-xs text-red-700">
              Identifiants invalides. Veuillez réessayer.
            </div>
          ) : null}
          {verificationLink && (
            <div className="border-black-6 bg-white-4 my-4 rounded border p-3 text-xs">
              Votre compte n'est pas encore vérifié. <br></br>
              <Link
                to={ROUTES.RESEND_EMAIL_VERIF}
                className="text-accent-11 hover:text-accent-8 underline"
              >
                Cliquez ici
              </Link>{' '}
              pour obtenir un nouveau lien de vérification.
            </div>
          )}
          <form onSubmit={handleSubmit} className="">
            {/* input email */}
            <div className="mb-4">
              <LabeledInput
                label="Email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
                additionalLabelClass=""
              />
            </div>

            {/* input password */}
            <div className="mb-1">
              <LabeledInput
                label="Mot de passe"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                required
                additionalLabelClass=""
              />
            </div>
            <div className="flex justify-end">
              <Link
                to={ROUTES.SEND_EMAIL_FORGOT_PASSWORD}
                className="text-black-5 hover:text-black-7 mb-4 text-xs hover:underline"
              >
                Mot de passe oublié ?
              </Link>
            </div>

            <Button
              type="submit"
              disabled={mutation.isLoading}
              className="xs:text-lg w-full truncate text-xs font-bold"
            >
              Se connecter
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
