import React, { useRef } from 'react';
import Section from '../../../../components/ui/Section';
import SectionHeaderHome from '../../../../components/ui/SectionHeaderHome';
import Game from './components/Game';
import Mouse from './components/Mouse';
import ProgressBar from './components/ProgressBar';
import JeuOne from '../../../../assets/images/games/roblox.webp';
import JeuTwo from '../../../../assets/images/games/fortnite.webp';
import JeuThree from '../../../../assets/images/games/minecraft.webp';
import JeuFour from '../../../../assets/images/games/gta.webp';
import { useAnimation } from '../../../../hooks/useAnimation';
import { useProgress } from '../../../../hooks/useProgress';
import './VotingGames.css';

const VotingGames = () => {
  const sectionRef = useRef(null);
  const { isAnimated, resetAnimation } = useAnimation(sectionRef);

  const totalAnimationTime = 9000;
  const initialDelay = 4500;
  const percent = 52;

  const progress = useProgress(
    isAnimated,
    resetAnimation,
    totalAnimationTime,
    initialDelay,
    percent,
  );

  return (
    <Section variant="wrapper" additionalClass="text-white-1 mt-12">
      <div
        className={`bg-black-3 s:p-20 border-black-6 mx-4 rounded-xl border-t-[1px] px-5 py-8 text-center ${isAnimated && !resetAnimation ? 'animate' : ''}`}
        ref={sectionRef}
      >
        <SectionHeaderHome
          title="Exprimez vos préférences pour vos jeux favoris"
          subtitle="Votre vote compte plus qu'un simple avis. En votant, vous influencez
          le monde du jeu vidéo."
        />
        <div className="relative mx-auto my-12 flex w-full gap-x-5">
          <div className="flex w-[45%] justify-between gap-x-5 sm:w-[calc(66.66%-6.666px)] lg:w-[calc(75%-5px)]">
            <Game src={JeuOne} alt="One" className="game-one flex-1" />
            <Game
              src={JeuTwo}
              alt="Two"
              className="game-two hidden flex-1 sm:block"
            />
            <Game
              src={JeuThree}
              alt="Three"
              className="game-three hidden flex-1 lg:block"
            />
          </div>
          <Game
            src={JeuFour}
            alt="Four"
            className="game-four absolute right-0 top-0 w-[45%] sm:w-[calc(33.33%-13.334px)] lg:w-[calc(25%-15px)]"
          />
          <Mouse />
        </div>
        <ProgressBar progress={progress} />
      </div>
    </Section>
  );
};

export default VotingGames;
