import { useState, useEffect } from 'react';

/**
 * Custom React hook to manage animated progress towards a percentage.
 * The animation restarts every cycle defined by the total animation time.
 *
 * @param {boolean} isAnimated - Flag to start or stop animation.
 * @param {boolean} resetAnimation - Flag to reset animation immediately.
 * @param {number} totalAnimationTime - Total duration of one cycle in milliseconds.
 * @param {number} initialDelay - Delay before starting the animation in milliseconds.
 * @param {number} percent - Target progress percentage to achieve.
 * @returns {number} Current progress as a percentage.
 */
export const useProgress = (
  isAnimated,
  resetAnimation,
  totalAnimationTime,
  initialDelay,
  percent,
) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progressTimer;

    /**
     * Starts the progress animation.
     */
    const startAnimation = () => {
      const progressSpeed = (totalAnimationTime - initialDelay) / percent / 4;
      progressTimer = setInterval(() => {
        setProgress((currentProgress) => {
          if (currentProgress < percent) {
            return currentProgress + 1;
          } else {
            clearInterval(progressTimer);
            return currentProgress;
          }
        });
      }, progressSpeed);
    };

    /**
     * Handles the complete cycle of resetting and starting the animation.
     */
    const handleCompleteCycle = () => {
      setProgress(0);
      setTimeout(startAnimation, initialDelay);
      setTimeout(() => setProgress(0), totalAnimationTime - 1);
    };

    let cycleTimer;
    if (isAnimated && !resetAnimation) {
      cycleTimer = setInterval(handleCompleteCycle, totalAnimationTime);
      handleCompleteCycle();
    }

    return () => {
      if (progressTimer) clearInterval(progressTimer);
      if (cycleTimer) clearInterval(cycleTimer);
      setProgress(0);
    };
  }, [percent, totalAnimationTime, initialDelay, isAnimated, resetAnimation]);

  return progress;
};
