import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../utils/routesConfig';
import Button from '../../buttons/Button';
import { USER_IMAGE_URL } from '../../../utils/backURL';

const SidebarMobile = ({
  isSidebarOpen,
  closeSidebar,
  userData,
  isAdmin,
  handleLogoutAndClose,
  isUserAuthenticated,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <>
      <div
        className={`bg-black-3 fixed left-0 top-0 z-20 h-full w-64 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-700 ease-in-out lg:hidden`}
      >
        {userData && isUserAuthenticated ? (
          <div>
            <div
              className="border-black-2 flex items-center border-b px-5 py-4"
              onClick={toggleOpen}
            >
              <img
                src={`${USER_IMAGE_URL}${userData.profile_picture}`}
                alt={`${userData.alt_text}`}
                className="border-white-3 mr-4 size-[45px] border-2 object-cover object-center"
              />
              <div className="flex grow items-center justify-between">
                <p className="text-white-3 truncate font-semibold">
                  {userData.username}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`text-white-1 size-4 transition-transform ${
                    isOpen ? 'rotate-180' : ''
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
            </div>
            {isOpen && (
              <ul className="text-white-1 bg-black-5 shadow-black-3 font-semibold uppercase shadow-inner">
                <Link
                  to={`${ROUTES.USER_PROFILE.replace(':userId', userData.id)}`}
                  onClick={closeSidebar}
                >
                  <li className="hover:bg-black-2 px-5 py-3 text-white">
                    Profil
                  </li>
                </Link>
                <Link to={ROUTES.USER_ACCOUNT} onClick={closeSidebar}>
                  <li className="hover:bg-black-2 px-5 py-3 text-white">
                    Paramètres
                  </li>
                </Link>
              </ul>
            )}
          </div>
        ) : (
          <div className="px-2">
            <Button
              className={'my-4 w-full'}
              link="/login"
              onClick={closeSidebar}
            >
              Connexion
            </Button>
          </div>
        )}
        <ul className="text-white-1 font-semibold uppercase">
          <Link to={ROUTES.GAMES} onClick={closeSidebar}>
            <li className="hover:bg-black-2 px-5 py-3">Jeux</li>
          </Link>
          {isUserAuthenticated ? (
            <>
              <Link to={ROUTES.COMMUNITY} onClick={closeSidebar}>
                <li className="hover:bg-black-2 px-5 py-3">Communauté</li>
              </Link>
              {isAdmin && (
                <Link to={ROUTES.DASHBOARD} onClick={closeSidebar}>
                  <li className="hover:bg-black-2 px-5 py-3">
                    Espace Administrateur
                  </li>
                </Link>
              )}
              <Link to={ROUTES.HOME} onClick={handleLogoutAndClose}>
                <div className="hover:bg-black-2 px-5 py-3 text-white">
                  Déconnexion
                </div>
              </Link>
            </>
          ) : null}
        </ul>
      </div>
      <div
        className={`bg-black-1 fixed left-0 top-0 h-full w-full transition-opacity duration-1000 ease-in-out ${
          isSidebarOpen ? 'opacity-60' : 'pointer-events-none opacity-0'
        } z-10 lg:hidden`}
        onClick={closeSidebar}
      ></div>
    </>
  );
};

export default SidebarMobile;
