import React, { useCallback, useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../../utils/backURL';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Pagination from '../../../components/pagination/Pagination';
import ToggleButton from '../../../components/buttons/ToggleButton';
import Modal from '../../../components/modal/Modal';
import ContextMenu from '../../../components/buttons/ContextMenu';
import CustomTable from '../../../components/table/CustomTable';
import { FaTrashAlt } from 'react-icons/fa';
import Button from '../../../components/buttons/Button';
import { MdAdd } from 'react-icons/md';
import LabeledInput from '../../../components/input/LabeledInput';
import { generateSlug } from '../../../utils/slugger';
import { RxHamburgerMenu } from 'react-icons/rx';
import { GrOrderedList } from 'react-icons/gr';

const AdminPlatforms = () => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [title, setTitle] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newPlatformName, setNewPlatformName] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [orderOption, setOrderOption] = useState(false);
  const ITEMS_PER_PAGE = 30;

  //! Construit l'URL pour la requête API en fonction des paramètres de pagination et de filtrage
  const buildURL = (page, search, status) => {
    let url = `${API_ENDPOINTS.FETCH_PLATFORMS}?page=${page}&itemsPerPage=${ITEMS_PER_PAGE}&order[ranking]=asc`;
    if (search) {
      url += `&slug=${encodeURIComponent(search)}`;
    }
    if (status) {
      url += `&status=${encodeURIComponent(status)}`;
    }
    return url;
  };

  //! Récupère les plateformes depuis l'API
  const fetchPlatforms = useCallback(async () => {
    const url = buildURL(currentPage, searchQuery, selectedStatus);
    try {
      const response = await axios.get(url);
      setTotalPages(
        Math.ceil(response.data['hydra:totalItems'] / ITEMS_PER_PAGE),
      );
      return response.data['hydra:member'];
    } catch (error) {
      console.error(
        'Erreur lors de la récupération des plateformes : ',
        error.message,
      );
    }
  }, [currentPage, searchQuery, selectedStatus]);

  //! Query
  const {
    data: platforms,
    isLoading: platformsLoading,
    isError: platformsError,
    refetch: platformsRefetch,
  } = useQuery(
    ['platforms', currentPage, selectedStatus, searchQuery],
    fetchPlatforms,
  );

  //! Refetch les utilisateurs lorsque les paramètres de recherche ou de pagination changent
  useEffect(() => {
    let newTitle = 'Plateformes';

    if (selectedStatus) {
      newTitle += ` - ${
        selectedStatus.charAt(0).toUpperCase() + selectedStatus.slice(1)
      }`;
    }

    if (searchQuery) {
      newTitle += ` - ${
        searchQuery.charAt(0).toUpperCase() + searchQuery.slice(1)
      }`;
    }

    setTitle(newTitle);
    platformsRefetch();
  }, [currentPage, searchQuery, selectedStatus, platformsRefetch]);

  //! Evènement
  // Changement de status de la plateforme actif/inactif (click toggle)
  const handleStatusChange = async (platformSlug, newStatus) => {
    try {
      await axios.put(`${API_ENDPOINTS.EDIT_PLATFORM}/${platformSlug}`, {
        status: newStatus,
      });
      platformsRefetch();
    } catch (error) {
      console.error(
        'Erreur lors de la mise à jour du statut : ',
        error.message,
      );
    }
  };

  // Suppression de la plateforme (clic dans le contextMenu)
  const handleDeletePlatform = async (platform) => {
    try {
      await axios.delete(`${API_ENDPOINTS.DELETE_PLATFORMS}/${platform}`);
    } catch (error) {
      console.log('erreur');
    } finally {
      platformsRefetch();
    }
  };

  // Ajout d'une plateforme (clic dans le ModalAdd)
  const handleAddPlatform = async (newPlatformName) => {
    try {
      await axios.post(API_ENDPOINTS.POST_NEW_PLATFORM, {
        name: newPlatformName,
        slug: generateSlug(newPlatformName),
        status: 'inactif',
      });
      platformsRefetch();
      setAddModalOpen(false);
    } catch (error) {
      console.error("Erreur lors de l'ajout de la plateforme :", error);
    }
  };

  // Ouverture du modal Suppression de plateforme
  const openDeleteModalForPlatform = (platform) => {
    setSelectedPlatform(platform);
    setDeleteModalOpen(true);
  };

  // Ouverture du modal Ajout de plateforme
  const openAddModalForPlatform = () => {
    setAddModalOpen(true);
  };

  const toggleOrderOption = () => {
    setOrderOption(!orderOption);
  };

  const moveItem = (direction, index) => {
    console.log(direction);
    console.log(index);
    if (direction === 'up' && index > 0) {
      const item = platforms[index];
      const newPlatforms = [...platforms];
      console.log(item);
      console.log(newPlatforms);
      newPlatforms[index] = newPlatforms[index - 1];
      newPlatforms[index - 1] = item;
      // Mettre à jour l'état et le backend si nécessaire
    } else if (direction === 'down' && index < platforms.length - 1) {
      const item = platforms[index];
      const newPlatforms = [...platforms];
      newPlatforms[index] = newPlatforms[index + 1];
      newPlatforms[index + 1] = item;
      // Mettre à jour l'état et le backend si nécessaire
    }
  };

  //! Gère le changement de page dans la pagination
  const handlePageChange = (newPage) => setCurrentPage(newPage);

  if (platformsError) return <p>Erreur de chargement des utilisateurs.</p>;

  //! Contenu du tableau
  const columns = [
    {
      header: 'Plateformes',
      render: (platform) => platform.name,
    },
    {
      header: '',
      render: (platform, index) => (
        <>
          <div className="flex justify-between px-4 align-middle">
            <ToggleButton
              id={platform.id}
              isChecked={platform.status === 'actif'}
              onChange={() =>
                handleStatusChange(
                  platform.slug,
                  platform.status === 'actif' ? 'inactif' : 'actif',
                )
              }
              label={platform.status}
              labelPosition="right"
              additionalContainerClass="mr-8"
              additionalButtonClass="mr-5"
              additionalLabelClass="text-xs uppercase font-semibold"
            />
            {orderOption ? (
              <div className="flex items-center justify-center">
                <span className="mr-5 rounded-full bg-gray-200 p-1 text-xs font-light">
                  {platform.ranking}oh
                </span>
                <button onClick={() => moveItem('up', index)}>↑</button>
                <button onClick={() => moveItem('down', index)}>↓</button>
              </div>
            ) : (
              <ContextMenu
                options={[
                  {
                    text: 'Supprimer',
                    icon: <FaTrashAlt />,
                    action: () => openDeleteModalForPlatform(platform),
                  },
                ]}
              />
            )}
          </div>
          <Modal
            isOpen={deleteModalOpen}
            title="Suppression de la plateforme"
            text={`Êtes-vous sûr de vouloir supprimer  : ${selectedPlatform?.name} ?`}
            onClose={() => setDeleteModalOpen(false)}
            onConfirm={() => handleDeletePlatform(selectedPlatform?.slug)}
            showCancelButton={true}
            additionalTitleClass="font-bold"
            additionalTextClass="mt-6 font-normal"
          />
        </>
      ),
    },
  ];

  //! Rendu
  return (
    <div className="mx-3 mt-[90px] text-center md:mx-8 md:mt-8">
      <div className="bg-white-2 mb-8 rounded-lg px-4 py-2 shadow-xl">
        <h1 className="font-bungee text-center text-xl">{title}</h1>
        <div className="my-4">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Rechercher par titre..."
            className="w-full rounded border px-3 py-2"
          />
        </div>
        <div className="my-4 flex flex-wrap items-center">
          <label
            htmlFor="status-select"
            className="mr-3 block text-sm font-semibold uppercase"
          >
            Statut :
          </label>
          <select
            id="status-select"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="rounded border px-3 py-2"
          >
            <option value="">Tous les statuts</option>
            <option value="actif">Actif</option>
            <option value="inactif">Inactif</option>
          </select>
        </div>
      </div>
      {/* Bouton + Modal Ajout de plateforme */}
      <div className="flex-col md:flex-row">
        <Button
          variant="secondary"
          className="mb-5 ml-3 flex max-w-[250px] items-center md:ml-0"
          onClick={openAddModalForPlatform}
        >
          <MdAdd size={'20px'} className="mr-2" />
          <span className="text-sm font-bold">Ajouter une plateforme</span>
        </Button>
        <Button
          variant="secondary"
          className="mb-5 ml-3 flex max-w-[250px] items-center md:ml-0"
          onClick={toggleOrderOption}
        >
          <GrOrderedList size={'20px'} className="mr-2" />
          <span className="text-sm font-bold">Modifier l'ordre</span>
        </Button>
      </div>
      <Modal
        isOpen={addModalOpen}
        title="Ajouter une plateforme"
        onClose={() => setAddModalOpen(false)}
        onConfirm={() => handleAddPlatform(newPlatformName)}
        showCancelButton={true}
        additionalTitleClass="font-bold"
        additionalTextClass="mt-6 font-normal"
        buttonVariant="default"
      >
        <LabeledInput
          placeholder="Nom de la plateforme"
          additionalContainerClass="my-5"
          onChange={(e) => setNewPlatformName(e.target.value)}
        />
      </Modal>
      {/* Tableau */}
      <CustomTable
        columns={columns}
        data={platforms || []}
        loading={platformsLoading}
      />
      {/* Pagination */}
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default AdminPlatforms;
