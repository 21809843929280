import React from 'react';
import Section from '../../../components/ui/Section';
import SectionHeader from '../../../components/ui/SectionHeader';
import Button from '../../../components/buttons/Button';

const CurrentVersus = ({
  isVoteChecking,
  versus,
  hasVoting,
  GAME_IMAGE_URL,
  ROUTES,
}) => {
  return (
    <>
      {!isVoteChecking && versus && (
        <Section additionalClass="mx-4 mb-20">
          <SectionHeader title="Duel de la semaine" additionalClass="" />
          <div className="">
            {/* Jeu 1 */}
            <div className="flex justify-between space-x-3 md:space-x-0">
              <div className="aspect-3/4 max-h-[500px] w-full max-w-[400px] rounded-lg">
                <img
                  src={`${GAME_IMAGE_URL}${versus.gameVotesDetails[0].gameImage}`}
                  alt={versus.gameVotesDetails[0].gameTitle}
                  className="mb-3 h-full max-h-[500px] w-full max-w-[400px] rounded-lg object-cover"
                />
              </div>
              {/* CTA Desktop */}
              {hasVoting ? (
                <div className="mx-auto my-4 hidden h-1/2 flex-col items-center justify-center md:my-auto md:w-1/3 lg:flex">
                  <div className="text-center">
                    <p className="uppercase">Résultat</p>
                    <p className="text-2xl font-black uppercase md:text-3xl">
                      {versus.title}
                    </p>
                  </div>
                  <Button
                    link={`${ROUTES.VERSUS.replace(':versusSlug', versus.slug)}`}
                    className="mt-2"
                  >
                    Voir le résultat
                  </Button>
                </div>
              ) : (
                <div className="mx-auto my-4 hidden h-1/2 flex-col items-center justify-center md:my-auto md:w-1/3 lg:flex">
                  <div className="text-center">
                    <p className="uppercase">Vote pour ton</p>
                    <p className="text-2xl font-black uppercase md:text-3xl">
                      {versus.title}
                    </p>
                  </div>
                  <Button
                    link={`${ROUTES.VERSUS.replace(
                      ':versusSlug',
                      versus.slug,
                    )}`}
                    className="mt-2"
                  >
                    Vote Maintenant
                  </Button>
                </div>
              )}
              {/* Jeu 2 */}
              <div className="aspect-3/4 max-h-[500px] w-full max-w-[400px] rounded-lg">
                <img
                  src={`${GAME_IMAGE_URL}${versus.gameVotesDetails[1].gameImage}`}
                  alt={versus.gameVotesDetails[1].gameTitle}
                  className="mb-3 ml-auto h-full max-h-[500px] w-full max-w-[400px] rounded-lg object-cover"
                />
              </div>
            </div>
            {/* CTA Mobile */}
            {hasVoting ? (
              <div className="mx-auto my-4 h-1/2 flex-col items-center justify-center lg:hidden">
                <div className="text-center">
                  <p className="uppercase">Résultat</p>
                  <p className="text-2xl font-black uppercase md:text-3xl">
                    {versus.title}
                  </p>
                </div>
                <Button
                  link={`${ROUTES.VERSUS.replace(':versusSlug', versus.slug)}`}
                  className="s:w-1/2 mx-auto mt-2"
                >
                  Voir le résultat
                </Button>
              </div>
            ) : (
              <div className="mx-auto my-4 h-1/2 flex-col items-center justify-center lg:hidden">
                <div className="text-center">
                  <p className="uppercase">Vote pour ton</p>
                  <p className="text-2xl font-black uppercase md:text-3xl">
                    {versus.title}
                  </p>
                </div>
                <Button
                  link={`${ROUTES.VERSUS.replace(':versusSlug', versus.slug)}`}
                  className="s:w-1/2 mx-auto mt-2"
                >
                  Vote Maintenant
                </Button>
              </div>
            )}
          </div>
        </Section>
      )}
    </>
  );
};

export default CurrentVersus;
