import React from 'react';
import Spinner from '../spinner/Spinner';

const CustomTable = ({ columns, data, loading }) => {
  return (
    <div className="overflow-x-auto rounded-lg shadow-xl">
      <table className="w-full table-auto text-xs">
        <thead className="bg-black-4 text-white-1">
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                className={`whitespace-nowrap px-4 py-2 text-[0.6rem] uppercase first-letter:text-[1rem] ${column.headerClassName || ''}`}
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white-2">
          {loading ? (
            <tr className=''>
              <td colSpan={columns.length} className="h-[300px] w-full text-center">
                <Spinner mini={true} />
              </td>
            </tr>
          ) : (
            data.map((item, rowIndex) => (
              <tr
                key={rowIndex}
                className="hover:bg-white-4 my-3 rounded border-y"
              >
                {columns.map((column, columnIndex) => (
                  <td
                    key={columnIndex}
                    className={`whitespace-nowrap px-4 font-semibold ${column.cellClassName || ''}`}
                  >
                    {column.render(item, rowIndex)}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;
